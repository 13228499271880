import jwtDecode from "jwt-decode";
import AuthService from "services/auth-service";


async function getRefresh(refresh_token) {
    const response = await AuthService.refreshToken({ refresh: refresh_token });
    console.log("setting new token")
    localStorage.setItem('access_token', response.access);
    localStorage.setItem('refresh_token', response.refresh);
}

function isAuth() {

    let token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');
    if (!token) {
        console.log("return false for null token");
        return false;
    } else {
        let tokenParts = jwtDecode(token);
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp < now) {

            let refresh_tokenParts = jwtDecode(refresh_token)

            if (refresh_tokenParts.exp > now) {

                getRefresh(refresh_token);
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }
}

export default isAuth;