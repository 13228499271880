export const genders = [
  {
    label: "Maschio",
    value: "maschio",
  },
  {
    label: "Donna",
    value: "donna",
  },
  {
    label: "Altro",
    value: "altro",
  },
];
export const countries = [
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaigian",
  "Bielorussia",
  "Belgio",
  "Bosnia ed Erzegovina",
  "Bulgaria",
  "Croazia",
  "Cipro",
  "Repubblica Ceca",
  "Danimarca",
  "Estonia",
  "Finlandia",
  "Francia",
  "Georgia",
  "Germania",
  "Grecia",
  "Ungheria",
  "Islanda",
  "Irlanda",
  "Italia",
  "Kosovo",
  "Lettonia",
  "Liechtenstein",
  "Lituania",
  "Lussemburgo",
  "Macedonia",
  "Malta",
  "Moldavia",
  "Monaco",
  "Montenegro",
  "Paesi Bassi",
  "Norvegia",
  "Polonia",
  "Portogallo",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovacchia",
  "Slovenia",
  "Spagna",
  "Svezia",
  "Svizzera",
  "Turchia",
  "Ucraina",
  "Regno Unito",
  "Città del Vaticano",
];
export const countryEn = [
  "Albania.",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United Kingdom",
  "Vatican City.",
];
