import HttpService from "./htttp.service";

const root = process.env.REACT_APP_API_ENDPOINT;

class ZindoService {
  //authEndpoint = process.env.API_URL;

  constructor() {
    this.header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access_token"),
      },
    };

    this.rootSocketRecipes = process.env.REACT_APP_SOCKET_ENDPOINT;
    this.root = process.env.REACT_APP_API_ENDPOINT;
  }

  getMachineLocation = async () => {
    const endpoint = this.root + "zindoapp/locationAll/";
    return await HttpService.get(endpoint, this.header);
  };

  getDataAttributes = async () => {
    const endpoint = this.root + "zindoapp/datas/columns";
    return await HttpService.get(endpoint, this.header);
  };

  getMachineDatas = async () => {
    const endpoint = this.root + "zindoapp/datas/";
    return await HttpService.get(endpoint, this.header);
  };

  getMachineDatasById = async (id) => {
    const endpoint = this.root + "zindoapp/datas/" + id + "/";
    return await HttpService.get(endpoint, this.header);
  };

  getMachineDatasPermission = async (id) => {
    const endpoint = this.root + "zindoapp/dataMachinePermission/" + id + "/";
    return await HttpService.get(endpoint, this.header);
  };

  getParamsMachine = async () => {
    const endpoint = this.root + "zindoapp/paramsmachine/";
    return await HttpService.get(endpoint, this.header);
  };

  getMachineMixMax = async (id) => {
    const endpoint = this.root + "zindoapp/cutterpress/" + id + "/";
    return await HttpService.get(endpoint, this.header);
  }

  datasselection = async (id, dataDate) => {
    console.log("dataselection check");
    const selectionEndpoint = this.root + "zindoapp/datasselection/" + id + "/";
    return await HttpService.post(selectionEndpoint, dataDate);
  };

  register = async (credentials) => {
    const registerEndpoint = "register";
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = "logout";
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = "password-forgot";
    return await HttpService.post(forgotPassword, payload);
  };

  resetPassword = async (credentials) => {
    const resetPassword = "password-reset";
    return await HttpService.post(resetPassword, credentials);
  };

  getProfile = async () => {
    const getProfile = "me";
    return await HttpService.get(getProfile);
  };

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  };

  getTypeMachines = async () => {
    const endpoint = this.root + "zindoapp/typemachines/";
    return await HttpService.get(endpoint);
  };

  getAllTypeMachines = async () => {
    const endpoint = this.root + "zindoapp/allTypeMachine/";
    return await HttpService.get(endpoint);
  };

  getMachines = async (type) => {
    const params = "?type=" + type;
    const endpoint = this.root + "zindoapp/machines/" + params;
    return await HttpService.get(endpoint);
  };

  getParamsType = async (type) => {
    const params = "?type=" + type;
    const endpoint = this.root + "zindoapp/paramasType/" + params;
    return await HttpService.get(endpoint);
  };



  datasGetAllClients = async (idLog) => {
    const endpointAllClient =
      this.root + "zindoapp/datasGetAllClients/" + idLog + "/";
    return await HttpService.get(endpointAllClient);
  };

  getRecipes = async (payload) => {
    const endpoint = this.root + "zindoapp/getrecipes/";
    return await HttpService.post(endpoint, payload);
  };

  getRecipesSocket = (id) => {
    let url = `${this.rootSocketRecipes}recipes/${id}/`;
    return new WebSocket(url);
  };

  getDataMqttSocket = (id) => {
    let url = `${this.rootSocketRecipes}ws/mqtt/${id}/`;
    return new WebSocket(url);
  };

  sendRecipe = async (payload) => {
    const endpoint = this.root + "zindoapp/sendrecipes/";
    return await HttpService.post(endpoint, payload);
  };

  removeRecipe = async (payload) => {
    const endpoint = this.root + "zindoapp/removerecipes/";
    return await HttpService.post(endpoint, payload);
  };

  associamacchineclienti = async (payload) => {
    const endpoint = this.root + "zindoapp/associamacchineclienti/";
    return await HttpService.post(endpoint, payload);
  };

  datasGetAll = async (payloadDataAll) => {
    const endpointDataAll = this.root + "zindoapp/datasGetAll/";
    return await HttpService.post(endpointDataAll, payloadDataAll);
  };

  insertnewmachineandcheck = async (payloadDataNewMachine) => {
    const endpointDataAll = this.root + "zindoapp/insertnewmachineandcheck/";
    return await HttpService.post(endpointDataAll, payloadDataNewMachine);
  };

  insertNewClientAndSede = async (payloadDataNewClientAS) => {
    const endpointDataClientAS = this.root + "zindoapp/insertncaa/";
    return await HttpService.post(endpointDataClientAS, payloadDataNewClientAS);
  };

  deleteClientAndSede = async (idClient) => {
    const endpointDeleteClient =
      this.root + "zindoapp/deletecaa/" + idClient + "/";
    return await HttpService.post(endpointDeleteClient);
  };

  getClienteAndSede = async (idClient) => {
    const endpointGetDataClient = this.root + "zindoapp/getclient/" + idClient + "/";
    return await HttpService.post(endpointGetDataClient);
  }

  editClientAndSede = async (payloadDataEditClientAS) => {
    const endpointDataClientAS = this.root + "zindoapp/editclient/";
    return await HttpService.post(endpointDataClientAS, payloadDataEditClientAS);
  };

  getRegion = async (payloadDataRegion) => {
    const endpointDataRegions = this.root + "zindoapp/getregions/";
    return await HttpService.post(endpointDataRegions, payloadDataRegion);
  };

  getProvince = async (payloadDataProvince) => {
    const endpointDataProvince = this.root + "zindoapp/getprovinces/";
    return await HttpService.post(endpointDataProvince, payloadDataProvince);
  };

  getCity = async (payloadDataCity) => {
    const endpointDataCity = this.root + "zindoapp/getcitys/";
    return await HttpService.post(endpointDataCity, payloadDataCity);
  };

  dissociamacchina = async (payload) => {
    const endpoint = this.root + "zindoapp/removeAssociate/";
    return await HttpService.post(endpoint, payload);
  };

  eliminamacchina = async (payload) => {
    const endpoint = this.root + "zindoapp/deleteMachine/"
    return await HttpService.post(endpoint, payload);
  };

  getmacchina = async (payload) => {
    const endpoint = this.root + "zindoapp/getMachine/"
    return await HttpService.post(endpoint, payload);
  };

  editMachine = async (payloadDataEditMachine) => {
    const endpointDataClientAS = this.root + "zindoapp/editmachine/";
    return await HttpService.post(endpointDataClientAS, payloadDataEditMachine);
  };

  restoremacchina = async (payload) => {
    const endpoint = this.root + "zindoapp/restoreMachine/"
    return await HttpService.post(endpoint, payload);
  };

  creaTipologia = async (payload) => {
    const endpoint = this.root + "zindoapp/crea-tipologia/";
    return await HttpService.post(endpoint, payload);
  };

  updateTipologia = async (payload) => {
    const endpoint = this.root + "zindoapp/update-tipologia/";
    return await HttpService.post(endpoint, payload);
  };

  deleteTipologia = async (pk) => {
    const endpoint = this.root + "zindoapp/tipologie-macchina/" + pk + "/delete/";
    return await HttpService.delete(endpoint);
  };

  getTipologieMacchina = async () => {
    const endpoint = this.root + "zindoapp/tipoligie-macchina/";
    return await HttpService.get(endpoint);
  };

  setActiveRecipe = async (payload) => {
    const endpoint = this.root + "zindoapp/active-recipes/";
    return await HttpService.post(endpoint, payload);
  };

  // RICETTE  STANDARD

  standardRecipeGET = async (type) => {
    const params = "?type=" + type;
    const endpoint = this.root + "zindoapp/ricette-standard/" + params;
    return await HttpService.get(endpoint);
  };

  standardRecipePOST = async (payload) => {
    const endpoint = this.root + "zindoapp/ricette-standard/";
    return await HttpService.post(endpoint, payload);
  };
  standardRecipePUT = async (payload) => {
    const endpoint = this.root + "zindoapp/ricette-standard/";
    return await HttpService.put(endpoint, payload);
  };
  standardRecipeDELETE = async (payload) => {
    const endpoint = this.root + "zindoapp/ricette-standard/";
    return await HttpService.delete(endpoint, payload);
  };

  // PARAMETRI

  parametriGET = async () => {
    const endpoint = this.root + "zindoapp/parametri/";
    return await HttpService.get(endpoint);
  };

  parametriPOST = async (payload) => {
    const endpoint = this.root + "zindoapp/parametri/";
    return await HttpService.post(endpoint, payload);
  };
  parametriPUT = async (payload) => {
    const endpoint = this.root + "zindoapp/parametri/";
    return await HttpService.put(endpoint, payload);
  };
  parametriDELETE = async (payload) => {
    const endpoint = this.root + "zindoapp/parametri/";
    return await HttpService.delete(endpoint, payload);
  };






}




export default new ZindoService();
