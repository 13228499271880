import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import { Scrollbar } from 'smooth-scrollbar-react';
import TablePagination from '@mui/material/TablePagination';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText  } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Popover from '@mui/material/Popover';
import zindoService from 'services/zindo-service';


function DeleteButton (props){
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const disabled_label = "Ci sono macchine associate";
    const label = "elimina";

    return (
        <>
        <Tooltip title={props.disable ? disabled_label : label} TransitionComponent={Zoom} placement="top">
            <span>
                <IconButton color="error" aria-label="add an alarm" onClick={handleClick}  disabled={props.disable} /*  onClick={ () => updateTipologia(rowsPerPage)} */ >
                    <BsTrash3  size={20} className='p-0'/>
            </IconButton>
            </span>                        
        </Tooltip>
        
            <Popover
 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                      
            >
                <Typography sx={{ p: 2 }} variant='h6'>Sei Sicuro di voler eliminare questa tipologia?</Typography> 
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 16px 5px 16px' }}>
                    <MDButton  variant="text" color="info" onClick={handleClose} size='small'>indietro</MDButton>
                    <MDButton  color={'error'} size='small' onClick={() => props.deleteAction(props.value)}>Elimina</MDButton>
                </div>

               
                
            </Popover>
    
        
    </>
    )
}


function Row(props) {
  const { row,
    updateAction,
    deleteAction,
    recipesAction
    } = props;
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
    console.log("ROW");
    console.log(row)
    
  },[])


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nome}
        </TableCell>
        <TableCell align='center'>{row.numero_macchine_associate}</TableCell>
        <TableCell align='right'>
            <Tooltip title="Ricette Standard" TransitionComponent={Zoom} placement="top">
                <IconButton color="warning" aria-label="add an alarm"  onClick={ () => recipesAction(row)}>
                    <BsFileText  size={20} className='p-0'/>
                </IconButton>
            </Tooltip>
            
            <Tooltip title="Modifica" TransitionComponent={Zoom} placement="top">
                    <IconButton color="info" aria-label="add an alarm"  onClick={ () => updateAction(row)}>
                        <BsPen  size={20} className='p-0'/>
                    </IconButton>
            </Tooltip>
            
            {row.numero_macchine_associate
                ?<DeleteButton disable = {true} deleteAction = {deleteAction} value = {row.pk}/>
                :<DeleteButton disable = {false} deleteAction = {deleteAction} value = {row.pk}/>
            }
        
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} sx={{ backgroundColor: '#f8f9fa' }}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Box sx={{ margin: 1}}>
              <Typography variant="h6" gutterBottom component="div" >
               Descrizione
              </Typography>
              <Typography variant="body2" gutterBottom component="div"  align="justify" >
              <Scrollbar
                                                        className="scrollable"
                                                        alwaysShowTracks={true}

                                                        style={{ maxHeight: "300px", paddingRight: "25px"}}
                                                        plugins={{
                                                            overscroll: {
                                                                effect: "bounce"
                                                            }
                                                        }}

                                                    ><div className='pl-5'>
                                                         {row.descrizione}
                                                    </div>
                                                       
                                                    </Scrollbar>
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
               Parametri
              </Typography>
              <TableContainer>
              <Table size="small" aria-label="purchases" rowsPerPage={10}>
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                  <TableCell align="center"> Nome</TableCell>
                    <TableCell align="center">In ricetta</TableCell>
                    <TableCell align="center">In dashboard</TableCell>
                    <TableCell align="center">Controllo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.parametri.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((param) => (

                    <TableRow key={param.nome} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" align="center"> {param.nome} </TableCell>
                        <TableCell align="center">{param.in_ricetta ? 1 : 0}</TableCell>
                        <TableCell align="center">{param.in_dasboard_realtime ? 1 : 0}</TableCell>
                        <TableCell align="center">{param.parametro_stato ? 1 : 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={row.parametri.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function TypeTable(props) {

    const {
        data,
        create,
        update,
        del,
        recipes
    } = props



    /* useEffect(()=>{
        console.log("DATAS TYPE");
        console.log(data)
    },[data]); */

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
          <TableCell>Info</TableCell>
            <TableCell>Tipologia</TableCell>
            {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
            <TableCell align='center'>Numero Macchine Associate</TableCell>
            <TableCell component='td' align='right'>
                <Button variant="text" color='primary' style={{paddingRight:17}} onClick={create} id={"addTypeID"}><BsPlus size={30} align={'right'}/>AGGIUNGI</Button>
            </TableCell>           
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.pk} row={row} updateAction={update} deleteAction={del} recipesAction={recipes}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
