import { Component } from "react";
import DataTableFilterDemo from "./components/DataFilter";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import TipologiaMacchineTemplate from "zindoLayouts/TipologiaMacchina/components/tipologiaTemplate";

export default class Machines extends Component {
  render() {
    return (
      <TipologiaMacchineTemplate
        title="Macchine Personali"
        component={<DataTableFilterDemo />}
      />

    );
  }
}