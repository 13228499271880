// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Import components
import DashBoardMachineAll from "../../components/DashBoard/DashBoard";

function DashboardMachine() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={1}>
        <MDBox pt={6} pb={3}>
          <DashBoardMachineAll />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default DashboardMachine;
