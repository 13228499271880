import React, { Component } from "react";
import DashboardTipologia from "./components/DashboardTipologia";
import "./style/formStyle.css";
import TipologiaMacchineTemplate from "./components/tipologiaTemplate";

export default class DashboardType extends Component {
    render() {
        return (
            <TipologiaMacchineTemplate
                title="Tipologie macchine"
                component={<DashboardTipologia />}
            />

        );
    }
}

// #End region
