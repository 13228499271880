import React, {
  useCallback,
  useState,
  useEffect,
  Component,
  PureComponent,
  useMemo,
} from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import { AgGridReact } from "ag-grid-react";

import axios from "axios";

import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.css";
import data from "layouts/user-management/data";
import ReactRoundedImage from "react-rounded-image";
import baresimaImage from "../../../assets/images/machineImage/baresina.png";
import unknownImage from "../../../assets/images/machineImage/unknown.png";
import { Grid } from "@mui/material";
import ParamTable from "./machinesTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ZindoService from "services/zindo-service";

const DataTableFilterDemo = () => {
  const [rowData, setRowData] = useState([]);
  const [rowClientData, setRowClientData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);


  useEffect(() => {
    getColumnMachines();

    const getDataMachines = async () => {
      const dataMachines = await ZindoService.getMachineDatas();

      let data = dataMachines.filter(elem => elem.dashboard === true);
      let dataClient = dataMachines.filter(elem => elem.dashboard === false);
      setRowData(data);
      setRowClientData(dataClient);

      console.log(dataMachines);
    }

    getDataMachines();

  }, []);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "70vh", width: "100%", boxSizing: "border-box" }),
    []
  );


  let getColumnMachines = async () => {

    let dataColumn = await ZindoService.getDataAttributes();
    const perm = JSON.parse(localStorage.getItem('permission'));

    if (!perm.machine_data_permission) {
      dataColumn.push(
        {
          field: "tipologia_macchina",
          headerName: "Tipologia macchina",
          flex: 1,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }),
          cellRenderer: TypeOfMachine,
          cellRendererParams: {
            getValue: function (field) {
              alert(`${field} was clicked`);
            },
          },
          cellMouseOver: (e) => console.log("Cell Hover"),
        },
        {
          field: "data",
          headerName: "Usage",
          flex: 2,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }),
          cellRenderer: BarChartUsage,
          cellRendererParams: {
            getValue: function (field) {
              alert(`${field} was clicked`);
            },
          },
        },
        {
          field: "id",
          headerName: "",
          flex: 1,
          resizable: false,
          suppressSizeToFit: true,
          minWidth: 150,
          cellStyle: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }),
          cellRenderer: BtnCellRenderer,
          cellRendererParams: {
            clicked: function (field) {
              alert(`${field} was clicked`);
            },
          },
        }
      );
    } else {
      dataColumn.push({
        field: "data",
        headerName: "Usage",
        flex: 2,
        resizable: false,
        cellStyle: () => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }),
        cellRenderer: BarChartUsage,
        cellRendererParams: {
          getValue: function (field) {
            alert(`${field} was clicked`);
          },
        },
      });
    }

    setColumnDefs(dataColumn);
    console.log(dataColumn)
  };

  const onGridReady = useCallback(async () => {

    const dataMachines = await ZindoService.getMachineDatas();

    let data = [...dataMachines];

    for (let elem of data) {
      for (let value of elem.active) {
        value.yVal = 5;
      }
    }
    console.log('data')
    console.log(data)
    setRowData(data);

    console.log(dataMachines);

  }, []);

  const defaultColDef = {
    resizable: false,
  };

  return (
    <MDBox pb={2} pt={3} px={2}>
      <Grid container spacing={0}>
        <div style={containerStyle}>
          <div className="outer-div">
            <Grid item xs={12} md={12} xl={12} >
              <div id="mineID">
                <ParamTable
                  id={'tableMyMachineID'}
                  rows={rowData}

                /*  paramsHook={{params: rows, setParams: setRows}} */
                />
              </div>
            </Grid>



            <Grid item xs={12} md={12} xl={12} pt={5}>
              <MDBox
                my={3}
                py={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className={'fullwidth'}
              >
                <MDTypography variant="h6" color="white" mx={2}>
                  Macchine Clienti
                </MDTypography>
              </MDBox>
              <div id="clientID">
                <ParamTable
                  id={'tableClientMachineID'}
                  rows={rowClientData}
                />
              </div>
            </Grid>

            {/* <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div> */}




          </div>
        </div>
      </Grid>
    </MDBox>
  );
};

export default DataTableFilterDemo;

class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.webSocketSniffer(props.value);

    const buttonGlowStyleYellow = {
      font: "10 Raleway, sans-serif",
      padding: "3px",
      borderRadius: "2em",
      cursor: "pointer",
      position: "relative",
      transition: "0.25s ease-out",
      boxShadow: "0 0 15px #dee610",
    };
    this.state = buttonGlowStyleYellow;
  }
  webSocketSniffer(id) {
    let url = `${process.env.REACT_APP_SOCKET_ENDPOINT}ws/mqtt/${id}/`;
    console.log(id);
    const machineSocket = new WebSocket(url);
    machineSocket.onopen = (e) => {
      console.log("connected");
      this.setState({ boxShadow: "0 0 15px #2ae319" });
    };
    machineSocket.onclose = (e) => {
      console.log("disconnected");
      this.setState({ boxShadow: "0 0 15px #f20f22" });

      setTimeout(() => {
        this.machineSocket = new WebSocket(url);
        console.log(url);
      }, 1000);
    };
    machineSocket.onerror = (err) => {
      console.log("Socket encountered error: ", err.message, "Closing socket");
      this.setState({ boxShadow: "0 0 15px #f20f22" });
      machineSocket.close();
    };
    machineSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      /* if (data.hasOwnProperty("value")) {
        console.log("Data ActiveRecipe:", data.value.value.Data.ActiveRecipe);
      } else {
        console.log("Data:", data);
      } */
    };
  }

  render() {
    return (
      <NavLink
        to={`/macchine/${this.props.value}`}
        state={{ id: this.props.value }}
      >
        <button className="buttonGlow" style={this.state}>
          Dashboard
        </button>
      </NavLink>
    );
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
      </div>
    );
  }
  return null;
};

class BarChartUsage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    /* console.log(props.data.active); */
    this.dataUsage = props.data.active;
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={this.dataUsage}>
          <Tooltip content={<CustomTooltip />} />
          <XAxis dataKey="xVal" hide />
          <Bar dataKey="yVal" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

class TypeOfMachine extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    /* console.log(props.data.active); */
    this.dataUsage = props.data.tipologia_macchina;
    if (props.data.tipologia_macchina === "baresina") {
      this.dataUsage = baresimaImage;
    } else {
      this.dataUsage = unknownImage;
    }
  }

  changeBackground(e) {
    /* e.target.style.background = "red"; */
  }

  render() {
    return (
      <div onMouseOver={this.changeBackground}>
        <ReactRoundedImage
          image={this.dataUsage}
          roundedSize="0"
          imageWidth="50"
          imageHeight="50"
        />
      </div>
    );
  }
}

// #Region Styled Component
const Container = styled.div`
  height: 70vh;
  width: 100%;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.bg};

  .ag-theme-alpine {
    color: ${(props) => props.theme.text};
    background: ${(props) => props.theme.bg};
    height: 100%;
    width: 100%;
    border-style: none;
    .ag-root-wrapper.ag-layout-normal {
      color: ${(props) => props.theme.text};
      background: ${(props) => props.theme.bg2};
      width: 100%;
      border-style: none;
    }
    .buttonGlow {
      color: ${(props) => props.theme.text};
      border: 2px solid ${(props) => props.theme.text};
      line-height: normal;
    }
  }
`;
// #End region
