import styled from "styled-components";

import { Scrollbar } from 'smooth-scrollbar-react';

import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import '@progress/kendo-theme-default/dist/all.css';

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";

import "../style/style.css";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

import ZindoService from "services/zindo-service";

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCol
} from 'mdb-react-ui-kit';


import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
    FormNumericTextBox,
    FormInput,
    FormTextArea,
    FormSwitch
} from "./form-components";
import {
    nameValidator,
    existRecipeValidator,
    numberValidator,
    paramValidator,
} from "./validators";
import {enumTypeParam} from 'zindoLayouts/utility/enumerators';
import { boolean } from "yup/lib/locale";

export default function RecipesForm({ params, toggleShow, modalHook, recipesHook, modality, nameRecipeEdit }) {

    const formRef = React.useRef(null);

    const order_form = Object.values(enumTypeParam);

    const [initialState, setInitialState] = useState({});

    const [recipeForm, setRecipeForm] = useState([]);

    const [selectMachines, setSelectedMachines] = useState([]);

    const creationForm = () => {

        let field = []

        if (modality === 'edit') {
            field.push(<Field
                id={"id_ricetta"}
                name={"Name"}
                label={"Ricetta"}
                component={FormInput}
                readOnly={true}
            />)
        } else {
            field.push(<Field
                id={"id_ricetta"}
                name={"Name"}
                label={"Ricetta"}
                component={FormInput}
                validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
            />)
        }

        field.push(<Field
            id={"id_info"}
            name={"Info"}
            label={"Info"}
            optional={true}
            component={FormTextArea}
        />)

        //capire come funziona questo ordinamento
        //console.log("-------------------------- PARAM ------------------------------");
        //console.log(params);
        let params_ = params.filter(item=> item.in_ricetta===true);
        params_.sort((a, b) => order_form.indexOf(a.tipologia) - order_form.indexOf(b.tipologia))
        for (let elem of params_) {
            switch (elem.tipologia) {
                case enumTypeParam.INTEGER: case enumTypeParam.FLOAT:

                    field.push(<Field
                        id={"id" + elem.nome}
                        name={elem.nome}
                        label={elem.label_frontend}
                        format={"n0"}
                        component={FormNumericTextBox}
                        min={0}
                        //max={elem.max}
                        validator={(value) =>paramValidator(value, elem.min, elem.max)}
                    />)
                    break;
                case enumTypeParam.BOOL:

                    field.push(<Field
                        id={"id" + elem.nome}
                        label={elem.label_frontend}
                        name={elem.nome}
                        component={FormSwitch}

                    />)
                    break;

                /* case "textarea":
                    field.push(<Field
                        id={"id" + elem.nome}
                        name={elem.nome}
                        label={elem.label_frontend}
                        optional={true}
                        component={FormTextArea}
                    />)
                    break; */
                default:
                    field.push(
                        <Field
                            id={"id" + elem.nome}
                            name={elem.nome}
                            label={elem.label_frontend}
                            component={FormInput}
                            validator={nameValidator}
                        />);
            }
        }

        field.push(<>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
            </div>
        </>)

        setRecipeForm(field);
    }

    useEffect(() => {
        var initial = {};
        console.log("PARAMS");
        console.log(params);

        if (nameRecipeEdit) {  

            var initial = {};
            for (const [key, value] of Object.entries(nameRecipeEdit.recipe)) {
                initial[key] = value;
            }
            
        }else{

            initial = { Name: "", Info: "" };
            params.map(item => {
                if (item.tipologia == 'bool') {
                    initial[item.nome] = false;
                } else if (item.tipologia == 'intero') {
                    initial[item.nome] = 0;
                } else {
                    initial[item.nome] = '';
                }

            })

        }    
        setInitialState(initial);

    }, [params,nameRecipeEdit])

    const closeForm = (reset) => {
        reset();
        toggleShow();
    }


    const notificationAction = (type) => {

        console.log(type)
        if (type === "create") {
            NotificationManager.success(
                "Ricetta creata con successo",
                "",
                4000
            );
        } else {
            NotificationManager.success(
                "Ricetta modificata con successo",
                "",
                4000
            );
        }
    }

    const submit = async (dataItem) => {

        for (const [key,value] of Object.entries(dataItem)){
            if(typeof(value) === 'boolean'){
                dataItem[key] = value ? 1 : 0;
            }
        }

        let totale = [...recipesHook.recipes];
        //dataItem.AirStatus = dataItem.AirStatus ? 1 : 0;
        let notifica = 'create';

        console.log("DATAITEM");
        console.log(dataItem);

        if (modality === 'edit') {
            notifica = 'update';


            const data = await ZindoService.sendRecipe(JSON.stringify({
                data_options: {
                    idMachines: parseInt(nameRecipeEdit.mach),
                    formFields: dataItem
                }
            }));

            if (parseInt(data.status) == StatusCodes.OK) {

                totale.map(item =>
                    item.idMachine === nameRecipeEdit.mach
                        ? item.recipes.map((rec, index) =>
                            rec.Name === nameRecipeEdit.recipe.Name
                                ? item.recipes[index] = dataItem
                                : '')
                        : ''
                )
            } else {
                console.log("STATUS CODE NOT OK");
            }

        } else {

            for (let elem of selectMachines) {

                const data = await ZindoService.sendRecipe(JSON.stringify({
                    data_options: {
                        idMachines: parseInt(elem),
                        formFields: dataItem
                    }
                }));

                if (parseInt(data.status) == StatusCodes.OK) {

                    totale.map(item =>
                        item.idMachine === elem
                            ? item.recipes.push(dataItem)
                            : ''
                    )
                } else {
                    console.log("STATUS CODE NOT OK");
                }
            }
        }

        
        recipesHook.setRecipes(totale);

        var initial = {};

        for (const [key, value] of Object.entries(initialState)) {
            if (typeof value == "number") {
                initial[key] = 0;
            } else {
                initial[key] = "";
            }

        }
        setInitialState(initial);
        notificationAction(notifica);
        toggleShow();
        

    };

    useEffect(()=>{

        formRef.current.resetForm();

    },[initialState])

    useEffect(() => {

        console.log("RECIPE USE EFFECT")

        let sel = recipesHook.recipes.filter(item => item.isactive === 0).map(item => item.idMachine);

        setSelectedMachines(sel);

        creationForm();

    }, [recipesHook.recipes]);


  /*   useEffect(() => {

        if (nameRecipeEdit) {
            console.log("MODIFICA");
            console.log(nameRecipeEdit);
            var initial = {};
            for (const [key, value] of Object.entries(nameRecipeEdit.recipe)) {
                initial[key] = value;
            }
            console.log(initial)
            setInitialState(initial);
        }

    }, [nameRecipeEdit]); */

    return (<><MDBModal staticBackdrop tabIndex='-1' show={modalHook.staticModal} setShow={modalHook.setStaticModal} className="pt-5">
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBox
                        mx={1}
                        mt={-3}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDBModalHeader style={{ border: 0 }}>
                            <MDBModalTitle style={{ color: "whitesmoke" }}>{modality === 'edit' ? 'Modifica Ricetta' : 'Crea Ricetta'}</MDBModalTitle>
                            <MDBBtn className="btn-close float right" color="none" aria-label="Close" onClick={toggleShow} />
                        </MDBModalHeader>
                    </MDBox>

                    <MDBModalBody className="p-4 pb-0 ">

                        <MDBCol xl={12} lg={12} className='mb-4 pr-3'>
                            <Form
                                ref={formRef}
                                onSubmit={submit}
                                initialValues={initialState}
                                key={"form_recipe"}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <fieldset className={"k-form-fieldset"}>
                                            {recipeForm}
                                        </fieldset>
                                        <div className="k-form-buttons">
                                            <Button
                                                themeColor={"primary"}
                                                type={"submit"}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {modality === 'edit' ? 'Aggiorna' : 'Aggiungi'}
                                            </Button>
                                            <Button onClick={() => closeForm(formRenderProps.onFormReset)}>Chiudi</Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        </MDBCol>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
        <NotificationContainer/>
    </>
        
    )
}

