import React, { Component } from "react";
import DashboardParametri from "./components/DashboardParametri";
import TipologiaMacchineTemplate from "zindoLayouts/TipologiaMacchina/components/tipologiaTemplate";

export default class DashboardParametriComponent extends Component {
    render() {
        return (
            <TipologiaMacchineTemplate
                title="Parametri"
                component={<DashboardParametri />}
            />

        );
    }
}

// #End region
