import { getter } from "@progress/kendo-react-common";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
export const termsValidator = (value) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value) =>
  !value
    ? "Email field is required."
    : emailRegex.test(value)
    ? ""
    : "Email is not in a valid format.";
export const emailValidatorNotREquired = (value) =>
  emailRegex.test(value) ? "" : "Email is not in a valid format.";
export const nameValidator = (value) =>
  !value
    ? "Il campo e necessario"
    : value.length < 2
    ? "Il campo deve essere almeno maggiore di 2 elementi."
    : "";
export const userNameValidator = (value) =>
  !value
    ? "Il campo e necessario."
    : value.length < 2
    ? "Il campo deve essere almeno maggiore di 2 elementi."
    : "";
export const codiceFiscaleValidator = (value) =>
  !value
    ? "Il Codice Fiscale è richiesto."
    : value.length < 16
    ? "Il codice fiscale deve essere almeno di 16 elementi."
    : value.length > 16
    ? "Troppi caratteri per il Codice fiscale."
    : "";
export const partitaIvaValidator = (value) =>
  !value
    ? "La partita IVA è richiesta."
    : value.length < 11
    ? "Il codice fiscale deve essere almeno di 11 elementi."
    : "";
export const phoneValidator = (value) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
    ? ""
    : "Not a valid phone number.";
export const cardValidator = (value) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";
export const cvcValidator = (value) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";
export const guestsValidator = (value) =>
  !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = (value) =>
  value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = (value) =>
  value ? "" : "Arrival Date is required.";
export const colorValidator = (value) => (value ? "" : "Color is required.");
export const requiredValidator = (value) =>
  value ? "" : "Error: This field is required.";
export const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Password must be at least 8 symbols.";
export const addressValidator = (value) =>
  value ? "" : "Address is required.";
const userNameGetter = getter("username");
const emailGetter = getter("email");
export const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: "Please fill in the following fields.",
    ["username"]: !userName ? "User Name is required." : "",
    ["email"]:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in a valid format.",
  };
};
