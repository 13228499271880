import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import { Scrollbar } from 'smooth-scrollbar-react';
import TablePagination from '@mui/material/TablePagination';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText  } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Popover from '@mui/material/Popover';
import zindoService from 'services/zindo-service';
import { useState } from 'react';

import { MDBCheckbox, MDBSelect } from 'mdb-react-ui-kit';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Chip from '@mui/material/Chip';
import { dataCsvIcon } from '@progress/kendo-svg-icons';


function Row(props) {
  const { 
    row,

    defaultValue,
    parametri,
    updateAction,
    deleteAction,
    submit,
    cancel
    } = props;

    const trueElem = <BsCheck2Circle color="green" size={25} />;
    const falseElem = <BsXCircle color="red" size={20} />;  
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [check, setCheck] = useState(true);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [lastInsert, setLastInsert] = useState({});


  useEffect(()=>{
    var lastInsert_= {}
    if(row.modality==='edit') {
        lastInsert_ ={
            paramID: row.paramID,
            nome: row.nome,
            in_ricetta: row.in_ricetta,
            realtime: row.realtime,
            controllo: row.controllo,
            modality: 'view'
        }
    }else{
        lastInsert_ ={
            paramID: defaultValue.value,
            nome: defaultValue.label,
            in_ricetta: false,
            realtime: false,
            controllo: false,
            modality: 'view'
        }
    }
    
    setLastInsert(lastInsert_);

  },[row])

  
  const changeHandlerParam = (choice) => setLastInsert({ ...lastInsert, 'paramID': choice.value, 'nome': choice.label });

  const changeHandler = (e) =>{
    setLastInsert({ ...lastInsert, [e.target.name]: e.target.checked });
    let controllo = e.target.name ==='controllo'? e.target.checked : lastInsert.controllo;
    let ricetta = e.target.name ==='in_ricetta'? e.target.checked : lastInsert.in_ricetta;
    let realtime = e.target.name ==='realtime'? e.target.checked : lastInsert.realtime;

    setCheck(controllo || ricetta || realtime);
    
  } 

  const submitRow =(modality, data) =>{
    const c = lastInsert.controllo || lastInsert.in_ricetta || lastInsert.realtime;
    if (c){
        submit(modality, data);
    }else{
        setCheck(c);
    }
  }


  return (
    <React.Fragment>
        {row.modality ==="edit" || row.modality ==="create" 
        ?<>
        {!check &&<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell colSpan={5}><div className="k-messagebox k-messagebox-error my-0">Seleziona almeno un ruolo per il parametro</div></TableCell>
                </TableRow>
        }
               
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row">
            <Select
                  name="parametro" id={"selectID"} onChange={(choice) => changeHandlerParam(choice)}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  options={parametri}
                  error={true}
                  defaultValue={defaultValue}
                  isDisabled={row.modality==='edit'? true:false}
                />
            </TableCell>
            <TableCell align='center'>
                <MDBCheckbox name='in_ricetta' id={"ricetta"+row.paramID} onChange={changeHandler} defaultChecked={row.in_ricetta}/>
            </TableCell>
            <TableCell align='center'>
                <MDBCheckbox name='realtime' id={"realtime"+row.paramID} onChange={changeHandler} defaultChecked={row.realtime}/>
            </TableCell>
            <TableCell align='center'>
                <MDBCheckbox name='controllo' id={"controllo"+row.paramID} onChange={changeHandler} defaultChecked={row.controllo}/>
            </TableCell>
            <TableCell align='right'> 
                <IconButton color="info" aria-label="add an alarm" onClick={() =>submitRow(row.modality, lastInsert)}>
                    <BsPlus size={30} className='p-0' />
                </IconButton>
                <IconButton color="error" className='p-0' aria-label="add an alarm" onClick={()=>cancel(row.modality, row.paramID)} /* disabled={disable} */>
                    <BsX size={30} />
                </IconButton>
            </TableCell>
        </TableRow>
        </>
        :
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row">{row.nome}</TableCell>
            <TableCell align='center'>{row.in_ricetta ? trueElem : falseElem}</TableCell>
            <TableCell align='center'>{row.realtime ? trueElem : falseElem}</TableCell>
            <TableCell align='center'>{row.controllo ? trueElem : falseElem}</TableCell>
            <TableCell align='right'> 

                <ParamEditButton color="info" onClick={() => updateAction(row.paramID)} >
                    <BsPen size={20} />
                </ParamEditButton>

                <ParamButton color="error" onClick={() => deleteAction(row.paramID)}>
                    <BsTrash3 size={20} />
                </ParamButton>

            </TableCell>
        </TableRow>
        }
      
      <TableRow>
      </TableRow>
    </React.Fragment>
  );
}


export default function ParamTable(props) {
    const {
        rows,
        setRows
    } = props;

    
    /* ROW:
        controllo: false
        in_ricetta: false
        modality: "create"
        nome: ""
        paramID: "createID"
        realtime: false
    */
   

    const [params, setParams] = useState([]); //parametri nel DB
    const [disable, setDisable] = useState(false); //disabilita il tasto aggiungi
    const [filterParams, setFilterParams] = useState([]);
    const [default_, setDefault_]=useState({});
  

    useEffect(()=>{

        const get_params_choices = async () => {

            const data = await zindoService.getParamsMachine();
            var t = [];
            data.map((p, index) => {
              t.push({ id: index, value: p.pk, label: p.nome });
            })
            setParams(t);
            setFilterParams(t);
            setDefault_(t[0]);
          }

        get_params_choices();
        
    },[]);


    const addFormRow = ()=>{
        let row = [...rows];
        row.unshift({
            paramID: 'createID',
            nome:'',
            in_ricetta:false,
            realtime:false,
            controllo:false,
            modality: 'create',
            
        })

        setRows(row);
        setDefault_(filterParams[0]);
        setDisable(true);
    }

    const editFormRow = (paramID) =>{

        let row = [...rows];
        row = row.map(r=>r.paramID===paramID? {...r, modality:'edit'}: r);
        let a = params.find(elem=>elem.value===paramID);
        /* let filter = [...filterParams]
        filter.push(a);
        setFilterParams(filter); */
        setDefault_(a);

        setRows(row);
        setDisable(true);

    }

    const submit = (modality, data)=>{

        switch(modality){
            case 'create':
                let row = rows.filter(r=>r.modality!=='create');
                row.unshift(data);
                setFilterParams(filterParams.filter(param=>param.value!==data.paramID))
                setRows(row);
                setDisable(false);
                break;
            default:
   
                let rowEdit = rows.map(r=>r.paramID=== data.paramID ? data : r);
                setRows(rowEdit);
                setDisable(false);
                break;

        }
        
    }

    const remove = (paramID) =>{
        let a = params.find(elem=>elem.value===paramID);
        let filter = [...filterParams]
        filter.push(a);
        filter.sort((a,b)=> a.id-b.id);
   
        setFilterParams(filter);
  
        setRows(rows.filter(elem=>elem.paramID!==paramID));
       
    }

    const cancel = (modality, paramID) =>{
        switch(modality){
            case 'create':
                setRows(rows.filter(r=>r.modality!=='create'));
                break;
            default:
                let rowEdit = rows.map(r=>r.paramID=== paramID ? {...r, modality:'view'} : r);
                setRows(rowEdit);
                break;

        }
        setDisable(false);
        
    }




  return (
    <TableContainer component={Paper} style={{minHeight:'500px'}}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow > 
            <TableCell>Parametro</TableCell>
            {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
            <TableCell align='center'>In ricetta</TableCell>
            <TableCell align='center'>Realtime</TableCell>
            <TableCell align='center'>controllo</TableCell>
            <TableCell component='td' align='right'>
                <Button variant="text" color='primary' style={{paddingRight:17}} onClick={addFormRow} id={"addTypeID"} disabled={disable}><BsPlus size={30} align={'right'}/>AGGIUNGI</Button>
            </TableCell>           
          </TableRow>
          <TableRow>
            {/* <TableCell colSpan={5} style={{hidden:true}}></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody >  
          {rows.map((row) => (
            <Row key={row.paramID} 
                 row={row} 
                 updateAction={editFormRow} 
                 deleteAction={remove} 
                 submit={submit} 
                 cancel={cancel} 
                 parametri={filterParams} 
                 defaultValue={default_}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}



const ParamButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
      boxShadow: 'none',
    }
  });
  const ParamEditButton = styled(IconButton)({
    '&:hover': {
      color: 'blue',
      boxShadow: 'none',
    }
  });
  