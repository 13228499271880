import * as React from "react";
import ZindoService from "services/zindo-service";

import {Form, FormElement} from "@progress/kendo-react-form";
import {Button} from "@progress/kendo-react-buttons";
import {Stepper} from "@progress/kendo-react-layout";
import {AccountDetails} from "./account-details";
import PersonalDetails from "./personal-details";
import jwtDecode from "jwt-decode";

import "react-notifications/lib/notifications.css";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {useNavigate} from "react-router-dom";

/* import { PaymentDetails } from "./payment-details"; */
const stepPages = [AccountDetails, <PersonalDetails/>];

function App() {
    const navigate = useNavigate();
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [steps, setSteps] = React.useState([
        {
            label: "Info Utente",
            isValid: undefined,
        },
        {
            label: "Info Azienda",
            isValid: undefined,
        },
    ]);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const isPreviousStepsValid =
        steps
            .slice(0, step)
            .findIndex((currentStep) => currentStep.isValid === false) === -1;
    const onStepSubmit = React.useCallback(
        (event) => {
            const {isValid, values} = event;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));
            setSteps(currentSteps);
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);
            if (isLastStep && isPreviousStepsValid && isValid) {
                setIsLoading(true);
                /* alert(JSON.stringify(values)); */
                sendToBAckend(values);
            }
        },
        [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
    );

    const createNotification = (type) => {
        if (type === "info") {
            NotificationManager.info("Info message");
        } else if (type === "success") {
            NotificationManager.success(
                "Utente e Azienda inseriti",
                "Inserimento Effettuato",
                5000
            )
            navigate("/gestione_macchine");
        } else if (type === "warning") {
            NotificationManager.warning(
                "Warning message",
                "Close after 3000ms",
                3000
            );
        } else if (type === "error") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante l'inserimento",
                "Errore durante inserimento",
                5000
            );
        } else if (type === "errorCustom") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante l'inserimento",
                "Azienda e Partita iva già presente.",
                5000
            );
        }
    };

    const sendToBAckend = async (dataUser) => {
        let singleDataUserAg = dataUser;
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        singleDataUserAg["user_id"] = user_id;
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({userData: singleDataUserAg}),
        };
        const response = await ZindoService.insertNewClientAndSede(requestOptions);
        /* const response = await fetch(
          "http://127.0.0.1:8000/zindoapp/insertncaa/",
          requestOptions
        ); */
        const data = response;
        /* console.log(data);
        console.log(data[0]["responce"]); */
        createNotification(data[0]["responce"]);
    };

    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <Stepper value={step} items={steps}/>
            <Form
                initialValues={formState}
                onSubmitClick={onStepSubmit}
                render={(formRenderProps) => (
                    <div
                        style={{
                            /* alignSelf: "center", */
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <FormElement
                            style={{
                                width: "100%",
                            }}
                        >
                            {stepPages[step]}
                            <span
                                style={{
                                    marginTop: "40px",
                                }}
                                className={"k-form-separator"}
                            />
                            <div
                                style={{
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                }}
                                className={
                                    "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                }
                            >
                <span
                    style={{
                        alignSelf: "center",
                    }}
                >
                  Passaggi {step + 1} of 2
                </span>
                                <div>
                                    {step !== 0 ? (
                                        <Button
                                            style={{
                                                marginRight: "16px",
                                            }}
                                            onClick={onPrevClick}
                                        >
                                            Precedente
                                        </Button>
                                    ) : undefined}
                                    <Button
                                        themeColor={"primary"}
                                        disabled={isLastStep ? !isPreviousStepsValid : false}
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        {isLastStep ? "Inserisci" : "Avanti"}
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    </div>
                )}
            />
            <NotificationContainer/>
        </div>
    );
}

export default App;
