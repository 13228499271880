// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import InsertNewClient from "./formInserimentoCliente";

function AllPartOfInsertClient() {
  return (
    <MDBox pt={6} pb={3}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Inserisci Nuovo Utente
              </MDTypography>
            </MDBox>
            <InsertNewClient />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AllPartOfInsertClient;
