
import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "context";
import isAuth from "zindoLayouts/utility/authorization";

const ProtectedRoute = ({ redirectPath = "/auth/login", to, children }) => {

  const location = useLocation();

  if (!isAuth()) {
    console.log("Autenticazione fallita protectedRoute")
    console.log(location.pathname)
    console.log(location)
    return <Navigate to={redirectPath + `?redirectTo=${to}`} replace />;
  }
  //alert("auth")
  return children;
};

export default ProtectedRoute;

