import React, { /* useState, */ useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import App from "../multiStageform/main.js";

import '@progress/kendo-theme-default/dist/all.css';

/* import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";

import { Scrollbar } from "react-scrollbars-custom"; */

/* import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"; */

/* import { useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode"; */

function InsertNewClient() {
  /* const [checked, setChecked] = useState([]);
  var [dataAllClients, setDataAllClients] = useState([]);
  const [dataAllClientsTemp, setDataAllClientsTemp] = useState([]); */

  useEffect(() => {
    getClientsWithId();
  }, []);

  let getClientsWithId = async () => {
    /* axios.get("http://127.0.0.1:8000/zindoapp/datas/" + id).then((response) => {
      setinitialIndex(response.data.maxDataSlideMotor);
      setstopIndex(response.data.minDataSlideMotor);
      setRowDataById(response.data);
    }); */
    /*  var refresh_token = localStorage.getItem("access_token");
    let refresh_tokenParts = jwtDecode(refresh_token);
    var user_id = refresh_tokenParts.user_id;
    let responce = await fetch(
      "http://127.0.0.1:8000/zindoapp/datasGetAllClients/" +
        user_id.toString() +
        "/"
    );
    let data = await responce.json();

    setDataAllClients(data);
    setDataAllClientsTemp(data);
    console.log(data); */
  };

  /* localStorage.getItem('access_token');
  let refresh_tokenParts = jwtDecode(refresh_token) */

  return (
    <div>
      <MDBox py={3} px={3}>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox flex>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                pt={1}
                px={2}
              >
                <MDBox
                  variant="gradient"
                  bgColor="success"
                  color={"dark" === "light" ? "dark" : "white"}
                  coloredShadow="dark"
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  mt={-3}
                >
                  <Icon fontSize="medium" color="inherit">
                    person_add
                  </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                  <MDTypography
                    variant="button"
                    fontWeight="light"
                    color="text"
                  >
                    Inserisci Nuovo Utente
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Divider />
              <App />
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
    </div>
  );
}

export default InsertNewClient;
