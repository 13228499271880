import styled from "styled-components";

import { Scrollbar } from 'smooth-scrollbar-react';

import { useState, useEffect } from "react";

//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import '@progress/kendo-theme-default/dist/all.css';

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";

import Grid from "@mui/material/Grid";

import DataTable from "examples/Tables/DataTable";

import "../style/style.css";

import IconButton from '@mui/material/IconButton';

import ZindoService from "services/zindo-service";
import { StatusCodes } from "http-status-codes";
import MDTypography from "components/MDTypography";


import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCol,
    MDBRow

} from 'mdb-react-ui-kit';


import { BsRepeat } from "react-icons/bs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';

function RecipeStandardCard({recipes, reachableMachines, notReachableMachines, labels}) {


    /* useEffect(()=>{
        console.log("----notReachableMachines---")
        console.log(notReachableMachines);
        console.log(recipes);
        console.log(labels);
        
    },[]) */


    return (<>
        <MDBox bgColor={"grey-100"} borderRadius="lg">
            <Grid container spacing={0}>

                <Grid item xs={12} md={12} style={{paddingTop:'10px'}} px={2}>
                    <Grid item>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="h6"
                            color="text.warning"
                        >
                            {recipes.Name}
                        </Typography>
                    </Grid>   
                </Grid>
                <Grid item md={6} xs={12} pb={3} px={2}>
                
                        <Scrollbar
                            className="scrollable"
                            alwaysShowTracks={true}

                            style={{ maxHeight: "220px", padding: "0px" }}

                            plugins={{
                                overscroll: {
                                    effect: "bounce"
                                }
                            }}
                        >
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.warning"
                            >
                                {recipes.Info}
                            </Typography>

                        </Scrollbar>
                </Grid>
                <Grid item md={6} xs={12} px={2}>
            
                    <Scrollbar
                        className="scrollable"
                        alwaysShowTracks={true}

                        style={{ maxHeight: "220px", padding: "0px" }}
                        plugins={{
                            overscroll: {
                                effect: "bounce"
                            }
                        }}
                    >
                        {Object.keys(recipes).map((el,index) => 
                            el !== 'Name' && el!=='Info'
                            ? <List sx={{ width: '100%', maxWidth: 360}}>
                            
                              <ListItem
                                key={index}
                                disableGutters
                                secondaryAction={recipes[el]}
                               
                              >
                                <ListItemText primary={<React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {labels[el]}
                                    </Typography>

                                </React.Fragment>} />
                              </ListItem>
                            
                            </List>
                            
                           
                            : ''
                        )
                        }
                        
                        
                    </Scrollbar>
                        
                </Grid>
  
            </Grid>
        </MDBox >
        <Grid container spacing={0} mt={2}>
            <Grid item md={6} xs={12}>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="h6"
                    color="text.warning"
                >
                    Macchine raggiungibili
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    
                    {reachableMachines.map(item =>
                            <><Divider variant="inset" component="li" className={'my-0'} />
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {item.nomeMacchina}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                <span className="text-success">Stop Produzione</span>
                                            </Typography>

                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            </>
                        )
                    }
        
                </List>
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="h6"
                    color="text.warning"
                >
                    Macchine non raggiungibili
                </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {notReachableMachines.length!==0 
                    ? notReachableMachines.map(item =>
                        <><Divider variant="inset" component="li" className={'my-0'} />
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={<React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {item.nomeMacchina}
                                    </Typography>

                                </React.Fragment>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="caption"
                                            color="text.primary"
                                        >
                                            {item.isactive===2?<span className="text-danger">Spenta</span>:<span className="text-warning">Produzione</span>}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        </>
                    )
                    :   <>
                    <Divider variant="inset" component="li" className={'my-0'} />
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<React.Fragment>
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="caption"
                                    color="text.primary"
                                >
                                    Nessuna macchina non raggiungibile
                                </Typography>

                            </React.Fragment>}   
                        />
                    </ListItem>
                    </>
                    }
                    
                    {/* {notReachableMachines.map(item =>
                            <><Divider variant="inset" component="li" className={'my-0'} />
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {item.nomeMacchina}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                {item.isactive===2?<span className="text-danger">Spenta</span>:<span className="text-warning">Produzione</span>}
                                            </Typography>

                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            </>
                        )
                    } */}
        
                </List>
            </Grid>
        </Grid>
    </>)
}




export default function DefaultTableRecipes({ col, typeMachine, selectedMachines, recipesHook }) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const formRef = React.useRef(null);
    const [label, setLabel]= useState({});
    const [currentRecipe, setCurrentRecipe] = useState({});
    const [currentMac, setCurrentMac] = useState({});
    const [staticModal, setStaticModal] = useState(false);
    const [form, setForm] = useState(null);

    const toggleShow = () => {console.log(!staticModal);
    setStaticModal(!staticModal);
}

    const createForm = (recipes) => {

        let reachableMachines = selectedMachines.filter(item => item.isactive === 0);
        let notReachableMachines = selectedMachines.filter(item => item.isactive !== 0);
        console.log(notReachableMachines)

        setForm(
        <RecipeStandardCard
            recipes = {recipes}
            reachableMachines = {reachableMachines}
            notReachableMachines = {notReachableMachines}
            labels={label}
        />)
        setCurrentRecipe(recipes);
        setCurrentMac(reachableMachines.map(item => item.idMachine));
        toggleShow();
    }
    const standardRecipe = async () => {

        const data = await ZindoService.standardRecipeGET(typeMachine);
        console.log("-------- DATA ---------")
        console.log(data);
        let rows_ = [];
        let disable = !selectedMachines.some(item => item.isactive === 0)

        for (let elem of data.data) {
            let row = { 'Name': elem.nomericetta };
            for (let param of elem.parametri) {
                row[param.idparametro] = param.valore
            }
            let el = { ...row};

            el['Info'] = elem.info;
            
            row['action'] = <IconButton color="warning" onClick={() => createForm(el)} id={"ripristina"} disabled={disable}>
                <BsRepeat size={20} />
            </IconButton>
            rows_.push(row);
        }

        setRows(rows_);

    }

    useEffect(() => {
        // chiamata lista default 
        let col_ = col.filter(item => item.id !== 'macid' && item.id !== 'id_active'
        ).map(item => item.id === 'id_plus'
            ? { ...item, Header: 'azioni' }
            : item);
  
        /* let param = col_.filter(item => item.Header !== 'azioni').map(item => { item.Name : item.}) */
        setColumns(col_);


        let label_={};

        col_.filter(item => item.accessor !== 'Name' && item.accessor !== 'action' 
        ).map(item => label_[item['accessor']] = item['Header']);


        setLabel(label_);

        typeMachine !== null && standardRecipe();

    }, [col])




    const submit = async() => {
        console.log("submit");
        toggleShow();
        console.log(currentMac);
        console.log(currentRecipe);
        let totale = [...recipesHook.recipes];


        for (let elem of currentMac) {

            
            const data = await ZindoService.sendRecipe(JSON.stringify({
                data_options: {
                    idMachines: elem,
                    formFields: currentRecipe
                }
            }));

            
            if (parseInt(data.status) == StatusCodes.OK) {

                console.log("creata con successo--------------------------------");

                let item = totale.find(e=>e.idMachine===elem);
                if(item && item.recipes.some(e => e.Name === currentRecipe.Name)){
                    item.recipes.map((rec, index) =>
                        rec.Name === currentRecipe.Name
                            ? item.recipes[index] = currentRecipe
                            : '')
                }else{
                    item.recipes.push(currentRecipe);
                }
            } else {
                console.log("STATUS CODE NOT OK");
            }

        }
        console.log('TOTALE');
        console.log(totale);
        
        recipesHook.setRecipes(totale);
    }


    return (
    rows.length!==0 &&<>
            <MDBox
                mt={3}
                py={3}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                className={'fullwidth'}
                >
                <MDTypography variant="h6" color="white" mx={2}>
                    RICETTE STANDARD
                </MDTypography>
            </MDBox>
            <Grid item xs={12} md={12} xl={12} pt={2} >
                <div id="id_standard" >{/* className="recipes-hidden" */}
                    <MDBRow className={"mx-0 my-2"}>
                        <MDBCol xl={12} lg={12} className='mb-4 px-1'>
                            <DataTable
                                id="standardTable"
                                table={{ columns, rows }}
                                isSorted={false}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                noEndBorder
                                fullwidth
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </Grid>
            <MDBModal staticBackdrop tabIndex='-1' show={staticModal} setShow={setStaticModal} className="pt-5 modal-lg">
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBox
                            mx={1}
                            mt={-3}
                            py={1}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDBModalHeader style={{ border: 0 }}>
                                <MDBModalTitle style={{ color: "whitesmoke" }}>Ripristino Ricetta</MDBModalTitle>
                                <MDBBtn className="btn-close float right" color="none" aria-label="Close" onClick={toggleShow} />
                            </MDBModalHeader>
                        </MDBox>

                        <MDBModalBody className="p-4 pb-0 ">
                            <MDBCol xl={12} lg={12} className='mb-4 pr-3'>
                                
                                <fieldset className={"k-form-fieldset"}>
                                    {form}   
                                </fieldset>
                                <div className="k-form-buttons">
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={false}
                                        onClick={submit}
                                    >
                                        Ripristina
                                    </Button>
                                    <Button onClick={toggleShow}>Chiudi</Button>
                                </div>
                            </MDBCol>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>   
    )
}

