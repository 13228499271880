import React from "react";
import { MDBBadge, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import styled from "styled-components";
import { Scrollbar } from "smooth-scrollbar-react";
import { LuFactory } from "react-icons/lu";
import baresimaImage from "../../../assets/images/machineImage/baresina.png";

function RowGroup(props) {
  return (
    <MDBListGroupItem className="d-flex justify-content-between align-items-center">
      {props.machines.map((machine, index) => {
        var badge;
        if (props.error) {
          badge = (
            <MDBBadge pill light color="danger">
              Allarm
            </MDBBadge>
          );
        }
        return (
          <div className="d-flex align-items-center px-0 " key={index}>
            <a className="d-flex link" style={{ cursor: "pointer" }}>
              <span className="rounded-circle mr-2">
                <img src={baresimaImage} width={35}></img>
              </span>
              <div className="truncate">
                <p className="fw-bold mb-1 mt-1 truncate">
                  {machine.Nome}
                </p>
                <p className="fw-bold mb-1 mt-1 truncate">
                  {machine.NomeUnivocoMacchina}
                </p>
                {badge}
              </div>
            </a>
          </div>
        );
      })}
    </MDBListGroupItem>
  );
}

function getMachines(machines, city, err) {
  var row = [];
  console.log(machines);
  const skip = 2;
  for (var i = 0; i < machines.length; i += skip) {
    let array = machines.slice(i, i + skip);
    row.push(<RowGroup machines={array} key={city + i} error={err} />);
  }

  return row;
}

export default function Machines(props) {
  const machines = getMachines(props.machines, props.city_machine, props.error);

  return (
    <ContainerRealTime>
      <div
        className="sample-container"
        style={{ maxHeight: 200, display: "flex" }}
      >
        <Scrollbar
          className="scrollable py-1"
          alwaysShowTracks={true}
          plugins={{
            overscroll: {
              effect: "bounce",
            },
          }}
        >
          <MDBListGroup light="true">{machines}</MDBListGroup>
        </Scrollbar>
      </div>
    </ContainerRealTime>
  );
}

const ContainerRealTime = styled.div`
  .notify-badge {
    position: absolute !important;
    margin-left: 3rem;
  }
  .list-group {
    border-color: white;
  }
  .list-group-item {
    border: 0px white;
    border-radius: 0px;
    padding: 10px 20px 10px 20px;
  }
  .truncate {
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.truncate {
    padding-left: 10px;
  }
  .sample-container {
    width: 400px;
  }
  .scrollable {
    border-radius: 0px;
    width: 400px;
  }

  .rounded-circle {
    padding: 8px;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
  }

  .link {
    color: black;
  }

  a.link:hover {
    color: lightblue;
  }
  .link:hover > .rounded-circle {
    background: lightblue;
  }
`;
