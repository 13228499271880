// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import { MapMachine } from "./components/mapMachine";
import { useEffect, useState } from "react";
import Machines from "./components/listMachines";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText } from "mdb-react-ui-kit";
import { paddingIcon } from "@progress/kendo-svg-icons";

function Dashboard() {
    const [role, setRole] = useState(false);

    useEffect(() => {
        setRole(JSON.parse(localStorage.getItem('permission'))?.maps);
    }, []);

    console.log(role)

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            { }
                            {/*<MapMachine/>*/}
                            {role ? (
                                <MapMachine />
                            ) : (<>

                                <MDBCardImage style={{ padding: "10px" }}
                                    src='https://www.zindo.it/wp-content/uploads/2021/03/zindo_logo_web_300x90.svg'
                                    alt='...' position='top' height={150} />
                                <MDBCardBody>
                                    <MDBCardText className="text-center fs-1"><p>Benvenuto</p></MDBCardText>
                                </MDBCardBody>
                            </>
                            )}
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
