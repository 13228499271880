import styled from "styled-components";

import { Scrollbar } from 'smooth-scrollbar-react';

import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import '@progress/kendo-theme-default/dist/all.css';

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";

import "zindoLayouts/Recipes/style/style.css";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

import ZindoService from "services/zindo-service";

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCol
} from 'mdb-react-ui-kit';


import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
    FormNumericTextBox,
    FormInput,
    FormTextArea,
    FormSwitch
} from "zindoLayouts/Recipes/components/form-components";
import {
    nameValidator,
    existRecipeValidator,
    existStdRecipeValidator,
    numberValidator,
    paramValidator,
} from "zindoLayouts/Recipes/components/validators";




export default function StdRecipesForm({ params, toggleShow, modalHook, recipesHook, modality, nameRecipeEdit, action }) {

    const formRef = React.useRef(null);

    const order_form = ['Stringa', 'textarea', 'Intero', 'Float', 'Bool'];

    const [initialState, setInitialState] = useState({});

    const [recipeForm, setRecipeForm] = useState([]);



    const creationForm = () => {

        let field = []

        if (modality === 'edit') {
            field.push(<Field
                id={"id_ricetta"}
                name={"nomericetta"}
                label={"Ricetta"}
                component={FormInput}
                validator={(choice) => existStdRecipeValidator(choice, recipesHook.recipes.map(rec => rec.nomericetta))}
                //readOnly={true}
            />)
        } else {
            field.push(<Field
                id={"id_ricetta"}
                name={"nomericetta"}
                label={"Ricetta"}
                component={FormInput}
                validator={(choice) => existStdRecipeValidator(choice, recipesHook.recipes.map(rec => rec.nomericetta))}
            />)
        }

        field.push(<Field
            id={"id_info"}
            name={"info"}
            label={"Info"}
            optional={true}
            component={FormTextArea}
        />)

        //capire come funziona questo ordinamento
        console.log("-------------------------- PARAM ------------------------------");
        console.log(params);
        let params_ = params.filter(item=> item.in_ricetta===true);
        params_.sort((a, b) => order_form.indexOf(a.tipologia) - order_form.indexOf(b.tipologia))
        for (let elem of params_) {
            console.log("parametro")
            console.log(elem)
            switch (elem.tipologia) {
                case "Intero": case "Float":

                    field.push(<Field
                        id={"id" + elem.nome}
                        name={elem.nome}
                        label={elem.label_frontend}
                        format={"n0"}
                        component={FormNumericTextBox}
                        min={0}
                        //max={elem.max}
                        validator={(value) =>paramValidator(value, elem.min, elem.max)}
                    />)
                    break;
                case "Bool":

                    field.push(<Field
                        id={"id" + elem.nome}
                        label={elem.label_frontend}
                        name={elem.nome}
                        component={FormSwitch}

                    />)
                    break;

                case "textarea":
                    field.push(<Field
                        id={"id" + elem.nome}
                        name={elem.nome}
                        label={elem.label_frontend}
                        optional={true}
                        component={FormTextArea}
                    />)
                    break;
                default:
                    field.push(
                        <Field
                            id={"id" + elem.nome}
                            name={elem.nome}
                            label={elem.label_frontend}
                            component={FormInput}
                            validator={nameValidator}
                        />);
            }
        }

        field.push(<>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
            </div>
        </>)

        setRecipeForm(field);
    }

    useEffect(() => {
        var initial = {};
        console.log("PARAMS");
        console.log(params);
        console.log(recipesHook.recipes)

        if (nameRecipeEdit) {  
            var initial = {};
            for (const [key, value] of Object.entries(nameRecipeEdit.recipe)) {
                if (key ==='parametri'){
                    
                    for(const [k, val] of Object.entries(value)){
                        initial[val['idparametro']] = !isNaN(val['valore']) ? Number(val['valore']) : val['valore']
                    }
                }else{
                    initial[key] = value;
                }
                
            }            
        }else{

            initial = {};
            params.map(item => {
                if (item.tipologia == 'Bool') {
                    initial[item.nome] = false;
                } else if (item.tipologia == 'Intero') {
                    initial[item.nome] = 0;
                } else {
                    initial[item.nome] = '';
                }

            })

        }
        console.log(initial)
        setInitialState(initial);

    }, [nameRecipeEdit])

    const closeForm = (reset) => {
        reset();
        toggleShow();
    }


    /* const notificationAction = (type) => {

        console.log(type)
        if (type === "create") {
            NotificationManager.success(
                "Ricetta creata con successo",
                "",
                4000
            );
        } else {
            NotificationManager.success(
                "Ricetta modificata con successo",
                "",
                4000
            );
        }
    }
 */
    const submit = async (dataItem) => {

        dataItem.AirStatus = dataItem.AirStatus ? 1 : 0;
    
        console.log("DATAITEM");
        console.log(dataItem);

        await action(dataItem);
        toggleShow();
    };

    useEffect(()=>{

        formRef.current.resetForm();

    },[initialState])

    useEffect(() => {

        creationForm();

    }, [params]);


  /*   useEffect(() => {

        if (nameRecipeEdit) {
            console.log("MODIFICA");
            console.log(nameRecipeEdit);
            var initial = {};
            for (const [key, value] of Object.entries(nameRecipeEdit.recipe)) {
                initial[key] = value;
            }
            console.log(initial)
            setInitialState(initial);
        }

    }, [nameRecipeEdit]); */

    return (<><MDBModal staticBackdrop tabIndex='-1' show={modalHook.staticModal} setShow={modalHook.setStaticModal} className="pt-5">
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBox
                        mx={1}
                        mt={-3}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDBModalHeader style={{ border: 0 }}>
                            <MDBModalTitle style={{ color: "whitesmoke" }}>{modality === 'edit' ? 'Modifica Ricetta' : 'Crea Ricetta'}</MDBModalTitle>
                            <MDBBtn className="btn-close float right" color="none" aria-label="Close" onClick={toggleShow} />
                        </MDBModalHeader>
                    </MDBox>

                    <MDBModalBody className="p-4 pb-0 ">

                        <MDBCol xl={12} lg={12} className='mb-4 pr-3'>
                            <Form
                                ref={formRef}
                                onSubmit={submit}
                                initialValues={initialState}
                                key={"form_recipe"}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <fieldset className={"k-form-fieldset"}>
                                            {recipeForm}
                                        </fieldset>
                                        <div className="k-form-buttons">
                                            <Button
                                                themeColor={"primary"}
                                                type={"submit"}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {modality === 'edit' ? 'Aggiorna' : 'Aggiungi'}
                                            </Button>
                                            <Button onClick={() => closeForm(formRenderProps.onFormReset)}>Chiudi</Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        </MDBCol>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    </>
        
    )
}

