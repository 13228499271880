import styled from "styled-components";
import ZindoService from "services/zindo-service";
import React, {useState, useEffect, useCallback} from "react";
import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Brush,
    ResponsiveContainer,
    LineChart, ReferenceLine, Legend,
} from "recharts";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {BiRefresh} from "react-icons/bi";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import randomColor from "randomcolor";
import {DateRangePicker} from "rsuite";
import "rsuite/dist/rsuite.css";

import Form from 'react-bootstrap/Form';
import {ThreeCircles} from "react-loader-spinner";
import {MDBCardText, MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody} from "mdb-react-ui-kit";
import DataTable from 'react-data-table-component';

const DashBoardGraf = ({id}) => {
    const [rowDataById, setRowDataById] = useState([]);
    const [initialIndex, setinitialIndex] = useState(0);
    const [stopIndex, setstopIndex] = useState(0);
    const [numMotori, setumMotori] = useState(0);
    const [colors, setColors] = useState([]);
    const [allAreas, setAllAreas] = useState([]);
    const [allLines, setAllLines] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState(0);
    const [allOptions, setAllOptions] = useState([]);

    const [opacitySetup, setOpacitySetup] = useState({opacity: {}});

    const [isClickedMotor, setIsClickedMotor] = useState(false);
    const [isClickedOther, setIsClickedOther] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

    const timeOutRefresh = async () => {
        let responce = await fetch(
            "http://127.0.0.1:8000/zindoapp/datas/" + id + "/"
        );
        let data = await responce.json();
        setinitialIndex(data.maxDataSlideMotor);
        setstopIndex(data.minDataSlideMotor);
        setRowDataById(data);
    };

    useEffect(() => {
        window.addEventListener("error", (e) => {
            if (e.message === "ResizeObserver loop limit exceeded") {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });

        /* getMachineById(); */
        getMachineByIdTest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        /* setInterval(() => {
          timeOutRefresh();
        }, 5000); */
        /* addOpacity(); */
    }, []);

    const rotateMotor = isClickedMotor ? "rotate(360deg)" : "none";

    const clickSpinMotor = () => {
        setIsClickedMotor(!isClickedMotor);
        console.log("HO CLICCATO");
        setIsLoading(true);
        getMachineByIdTest();
    };

    const rotateOther = isClickedOther ? "rotate(360deg)" : "none";

    const clickSpinOther = () => {
        setIsClickedOther(!isClickedOther);
    };

    const addOpacity = (numValMotori, listValMotori) => {
        for (let i = 0; i < numValMotori; i++) {
            opacitySetup["opacity"][listValMotori[i]] = "0.6";
        }

        setOpacitySetup(opacitySetup);
        var listColor = randomColor({
            count: numValMotori,
        });
        for (let k = 0; k < numValMotori; k++) {
            colors.push(listColor[k]);
        }
        setColors(listColor);
    };

    const handleMouseEnter = useCallback((o) => {
        const dataKey = o.value;
        const keysValue = Object.keys(opacitySetup.opacity);
        for (var key in keysValue) {
            if (keysValue[key] === dataKey) {
                console.log(key);
                var i = key;
            }
        }

        if (opacitySetup.opacity[dataKey] === "0.6") {
            opacitySetup.opacity[dataKey] = "0";

            var AreaSingl01 = document.getElementsByClassName(dataKey);

            AreaSingl01[0].style.display = "none";
        } else {
            opacitySetup.opacity[dataKey] = "0.6";
            var AreaSingl11 = document.getElementsByClassName(dataKey);
            AreaSingl11[0].style.display = "block";
        }
        setOpacitySetup(opacitySetup);
        console.log("In handleMouseEnter");
        console.log(opacitySetup);
    }, []);

    let getMachineByIdTest = async () => {
        /* axios.get("http://127.0.0.1:8000/zindoapp/datas/" + id).then((response) => {
          setinitialIndex(response.data.maxDataSlideMotor);
          setstopIndex(response.data.minDataSlideMotor);
          setRowDataById(response.data);
        }); */
        console.log('qui4');
        let data = await ZindoService.getMachineDatasById(id);
        /* let responce = await fetch(
          "http://127.0.0.1:8000/zindoapp/datas/" + id + "/"
        );
        let data = await responce.json(); */

        setinitialIndex(data.maxDataSlideMotor);
        setstopIndex(data.minDataSlideMotor);
        setRowDataById(data);
        console.log('SONO QUIIIIIIIIIIIIIIIIIIII')
        console.log(data);
        await addOpacity(data.nameMotorFieldNUmber, data.nameMotorField);
        console.log(data);
        setIsLoading(false);
        console.log(isLoading);
    };

    const PrintPoint = () => {
        const keysValue = Object.keys(opacitySetup.opacity);
        for (let j = 0; j < keysValue.length; j++) {
            if (opacitySetup.opacity[keysValue[j]] === "0") {
                var key1 = keysValue[j];
                var AreaSingl01 = document.getElementsByClassName("dot" + key1);
                if (AreaSingl01.length > 0) {
                    AreaSingl01[0].style.display = "none";
                    AreaSingl01[1].style.display = "none";
                }
            } else {
                var key2 = keysValue[j];
                var AreaSingl02 = document.getElementsByClassName("dot" + key2);
                for (let showIndex = 0; showIndex < AreaSingl02.length; showIndex++) {
                    AreaSingl02[showIndex].style.display = "block";
                }
            }
        }
    };

    const PrintAllArea = () => {
        /* var rowsArea = []; */
        if (
            rowDataById.nameMotorFieldNUmber &&
            opacitySetup &&
            colors.length !== 0 &&
            allAreas.length === 0
        ) {
            for (let i = 0; i < rowDataById.nameMotorFieldNUmber; i++) {
                allAreas.push(
                    <Area
                        onMouseOver={PrintPoint}
                        onMouseMoveCapture={PrintPoint}
                        className={rowDataById.nameMotorField[i]}
                        key={rowDataById.nameMotorField[i]}
                        type="monotone"
                        dataKey={rowDataById.nameMotorField[i]}
                        activeDot={
                            <circle
                                r={5}
                                stroke="none"
                                strokeWidth={3}
                                fill={colors[i]}
                                className={"dot" + rowDataById.nameMotorField[i]}
                            />
                        }
                        fillOpacity={opacitySetup.opacity[rowDataById.nameMotorField[i]]}
                        stroke={colors[i]}
                        fill={colors[i]}
                    />
                );
            }
        }
        return allAreas;
    };

    const ChangeMotorId = async (value) => {
        console.log(value);
        await setSelectedBoard(value);
        printEnginesGraph();
    }

    const PrintSelectOptions = () => {
        if (rowDataById.listMotorSelect && allOptions.length === 0) {
            for (let key in rowDataById.listMotorSelect) {
                let row = rowDataById.listMotorSelect[key];
                console.log(row);
                allOptions.push(<option value={row['id']}>{row['alias']}</option>);
            }
        }
        return allOptions;
    }

    const printOtherGraph = () => {
        let graphs_list = []
        if (rowDataById.listOtherData && rowDataById.otherDataLabels && rowDataById.listUnitaMisura) {
            for (let k in rowDataById.listOtherData) {
                let value_row = rowDataById.listOtherData[k]
                let rows = []
                for (let i in rowDataById.listUnitaMisura) {
                    if (k === rowDataById.listUnitaMisura[i]) {
                        for (let j in rowDataById.otherDataLabels) {
                            let value_label = rowDataById.otherDataLabels[j]
                            if (k === value_label.unita_misura) {
                                rows.push(<Line type="monotone" dataKey={value_label.name} stroke={value_label.color}
                                                dot={false}
                                                name={value_label.label} strokeWidth={3} key={value_label.name}/>)
                            }
                        }
                    }
                }
                graphs_list.push(
                    <LineChart
                        data={value_row}
                        margin={{
                            top: 5,
                            right: 50,
                            left: 20,
                            bottom: 10,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="data"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend layout="horizontal" verticalAlign="top" align="center"
                                wrapperStyle={{fontSize: "16px"}}/>
                        <Brush dataKey="data" endIndex={initialIndex} startIndex={stopIndex} height={20}
                               stroke="#8884d8"/>
                        {rows}
                    </LineChart>
                );
            }
            console.log("QUI333333333333333333")
            console.log(graphs_list)
        }
        let graphs = []
        for (let i in graphs_list) {
            graphs.push(
                <>
                    <Spazer/>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    <Row>
                                        <Col xl={11} md={10} xs={10}>
                                            Grafico {rowDataById.listUnitaMisura[i].toUpperCase()}
                                        </Col>
                                    </Row>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <Row className="divGrafOthersAll" fluid={1}>
                                    <Col xl={12} className="divGrafOthers">
                                        <ResponsiveContainer>
                                            {graphs_list[i]}
                                        </ResponsiveContainer>
                                    </Col>
                                </Row>
                            </MDBox>
                        </Card>
                    </Grid>
                </>
            );
        }
        return (
            <>
                {graphs}
            </>
        );
    }

    const printEnginesGraph = () => {
        console.log('quasi');
        if (rowDataById.listMotorData) {
            console.log('qui2222222222222');
            console.log(selectedBoard);
            return (
                <LineChart
                    data={rowDataById.listMotorData[selectedBoard]}
                    margin={{
                        top: 5,
                        right: 50,
                        left: 20,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="data"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{fontSize: "16px"}}/>
                    <Brush dataKey="data" endIndex={initialIndex} startIndex={stopIndex} height={20} stroke="#8884d8"/>
                    <Line type="monotone" dataKey="alarm" stroke="#FF5858" dot={false} name="Sovraccarico"
                          strokeWidth={3}/>
                    <Line type="monotone" dataKey="warning" stroke="#FF8C00" dot={false} name="Allarme"
                          strokeWidth={3}/>
                    <Line type="monotone" dataKey="L1" stroke="#ACFC56" dot={false} strokeWidth={3}/>
                    <Line type="monotone" dataKey="L2" stroke="#FF7E58" dot={false} strokeWidth={3}/>
                    <Line type="monotone" dataKey="L3" stroke="#E056F3" dot={false} strokeWidth={3}/>
                </LineChart>
            );
        }
        return (<LineChart
                // width={500}
                // height={300}
                data={{}}
                margin={{
                    top: 20,
                    right: 50,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
            </LineChart>
        );
    }

    const CustomTooltip = ({active, payload, label}) => {
        PrintPoint();
        var tooltipAll = [];
        var tooltipRet = [];
        const keysValue = Object.keys(opacitySetup.opacity);
        if (active && payload && payload.length) {
            for (let indToolTip = 0; indToolTip < keysValue.length; indToolTip++) {
                if (opacitySetup.opacity[keysValue[indToolTip]] === "0.6") {
                    tooltipRet.push(
                        <div className="custom-tooltip">
                            <div style={{padding: 0, margin: 0}}>
                                <p className="label" style={{color: colors[indToolTip]}}>
                                    {`${keysValue[indToolTip]}`}{" "}
                                    {"min: " + `${payload[0].payload[keysValue[indToolTip]][0]}`}{" "}
                                    {"max: " + `${payload[0].payload[keysValue[indToolTip]][1]}`}
                                </p>
                            </div>
                        </div>
                    );
                }
            }
            tooltipAll.push(
                <div
                    className="scrollablelist"
                    style={{
                        borderRadius: "2px",
                        maxHeight: 300,
                        width: "auto",
                        fontSize: "14px",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        whiteSpace: "pre-wrap",
                        pointerEvents: "auto",
                    }}
                >
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <p className="label">{`Data : ${payload[0].payload["x"]}`}</p>
                    </div>
                    <div
                        style={{
                            maxHeight: 250,
                            overflowX: "auto",
                            overflowY: "auto",
                            pointerEvents: "auto",
                            touchAction: "auto",
                            margin: "auto 5px 5px 7px",
                        }}
                    >
                        {tooltipRet}
                    </div>
                </div>
            );
            return tooltipAll;
        }
    };

    const styles = {width: "auto", display: "block"};
    const getValueByDataRange = async (range) => {
        if (range) {
            setIsLoading(true);
            var dformatStart =
                [
                    range[0].getMonth() + 1,
                    range[0].getDate(),
                    range[0].getFullYear(),
                ].join("/") +
                " " +
                [0, 0, 0].join(":");
            var dformatStop =
                [
                    range[1].getMonth() + 1,
                    range[1].getDate(),
                    range[1].getFullYear(),
                ].join("/") +
                " " +
                [23, 59, 59].join(":");

            /* let responce = await fetch(
              "http://127.0.0.1:8000/zindoapp/datasselection/" + id + "/",
              {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  dataStart: dformatStart,
                  dataEnd: dformatStop,
                }),
              }
            ); */
            let responce = await ZindoService.datasselection(
                id,
                JSON.stringify({
                    dataStart: dformatStart,
                    dataEnd: dformatStop,
                })
            );
            let data = responce;
            setinitialIndex(data.maxDataSlideMotor);
            setstopIndex(data.minDataSlideMotor);
            setRowDataById(data);
            console.log('SONO QUIIIIIIIIIIIIIIIIIIII2222222222222222222222')
            console.log(data);
            setIsLoading(false);
        }
    };

    const printAlarm = () => {
        if (rowDataById.listAlarm && rowDataById.listAlarm.length > 0) {
            let columns = [
                {
                    name: 'Data',
                    selector: row => row.date,
                },
                // {
                //     name: 'Alias',
                //     selector: row => row.alias,
                // },
                // {
                //     name: 'Tipo',
                //     selector: row => row.type,
                // },
                {
                    name: 'Descrizione',
                    selector: row => row.description,
                },
            ];
            return (
                <DataTable
                    columns={columns}
                    data={rowDataById.listAlarm}
                    pagination
                />
            );
        }
        return (<p className={'py-5'}><h4>Nessun Allarme</h4></p>);
    }

    return (
        <ContainerAll>
            {isLoading ? (
                <Container
                    style={{margin: "12px auto 12px auto"}}
                    fluid
                    class="d-flex justify-content-around"
                >
                    <Spazer/>
                    <Row>
                        <Col xl={12} md={12} xs={12}>
                            <div fluid className="d-flex justify-content-around">
                                <ThreeCircles
                                    height="100"
                                    width="100"
                                    color="#328BEC"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={isLoading}
                                    ariaLabel="three-circles-rotating"
                                    outerCircleColor=""
                                    innerCircleColor=""
                                    middleCircleColor=""
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <MDBox pt={6} pb={6}>
                    <Spazer/>
                    <Grid container spacing={6}>
                        <Container className="allContainer" fluid={1}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            <Row>
                                                <Col xl={7} md={6} xs={6}>
                                                    Grafico Motori
                                                </Col>

                                                <Col xl={2} md={4} xs={4}>
                                                    <Form.Select aria-label="Seleziona Motore"
                                                                 onChange={(e) => ChangeMotorId(e.currentTarget.value)}>
                                                        {PrintSelectOptions()}
                                                    </Form.Select>
                                                </Col>

                                                <Col xl={2} md={4} xs={4}>
                                                    <DateRangePicker
                                                        showOneCalendar
                                                        size="xs"
                                                        placeholder="Periodo di visualizzazione"
                                                        placement="auto"
                                                        style={styles}
                                                        onChange={getValueByDataRange}
                                                    />
                                                </Col>

                                                <Col xl={1} md={2} xs={2}>
                                                    <button
                                                        className="buttonMachineRefresh"
                                                        onClick={clickSpinMotor}
                                                        style={{
                                                            transform: rotateMotor,
                                                            transition: "all 0.5s linear",
                                                            position: "absolute",
                                                            width: "32px",
                                                            height: "32px",
                                                            borderRadius: "50%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            border: "none",
                                                            letterSpacing: "inherit",
                                                            fontSize: "inherit",
                                                            outline: "none",
                                                        }}
                                                    >
                                                        <BiRefresh/>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <Row className="divGrafMotorAll">
                                            <Col xl={12} className="divGrafMotor">
                                                <ResponsiveContainer>
                                                    {printEnginesGraph()}
                                                </ResponsiveContainer>
                                            </Col>
                                        </Row>
                                    </MDBox>
                                </Card>
                            </Grid>
                            {printOtherGraph()}
                            <Spazer/>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            <Row>
                                                <Col xl={12} md={12} xs={12}>
                                                    Storico Allarmi
                                                </Col>
                                            </Row>
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <Row fluid={1}>
                                            <Col xl={12} className="text-center px-5">
                                                {printAlarm()}
                                            </Col>
                                        </Row>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Container>
                    </Grid>
                </MDBox>
            )}
        </ContainerAll>
    );
};

export default DashBoardGraf;

const ContainerAll = styled.div`
  .allContainer {
    margin-left: 38px;
    padding-right: 0;

    .divGrafMotorAll {
      height: 30vh;

      .scrollablelist {
        ::-webkit-scrollbar {
          width: 10px;
        }

        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
          background: #888;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }

    .divGrafOthersAll {
      height: 20vh;
    }

    .recharts-legend-item {
      font-size: 1.25rem;
      margin-right: 25px !important;
    }

    .recharts-responsive-container {
      height: auto;
    }
  }
`;

const Spazer = styled.div`
  height: 5vh;
`;
