import styled from "styled-components";

import { Scrollbar } from 'smooth-scrollbar-react';

import { useState, useEffect } from "react";
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import '@progress/kendo-theme-default/dist/all.css';

import React from "react";
//import Button from 'react-bootstrap/Button';

import MDBox from "components/MDBox";
import "../style/style.css";

//import "../style/style.css";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {enumStructureKey, regexStructureKey, enumTypeParam} from 'zindoLayouts/utility/enumerators';

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCol
} from 'mdb-react-ui-kit';

import Grid from "@mui/material/Grid";

import { StatusCodes } from "http-status-codes";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
    FormInput,
    FormDropDownList,
    FormTextArea,
    FormSwitch
} from "zindoLayouts/Recipes/components/form-components";
import {

    existParamValidator,
    existLabelValidator,

} from "zindoLayouts/Recipes/components/validators";
import zindoService from "services/zindo-service";
import { Typography } from "@mui/material";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default function ParamForm(props) {
    const {
        toggleShow,
        modalHook,
        modality,
        editValue,
        paramsHook
    } = props

    const formRef = React.useRef(null);

    const tipologieParametri = Object.values(enumTypeParam);
    const structureKey = Object.values(enumStructureKey);

    const initial ={
        nome : "",
        label_frontend : "",
        descrizione : "",
        is_structure : false,
        tipologia : tipologieParametri[0],
        unita_di_misura : "-"
    }
    const initialStructure = {
        Session: enumStructureKey.SESSION,
        PowerOn: enumStructureKey.POWERON,
        AllTime: enumStructureKey.ALLTIME
    }

    const [initialState, setInitialState] = useState(initial);
    const [isStructure, setIsStructure] = useState(false);
    
    const [structureParam, setStructureParam] =useState(initialStructure);

    
    const changeStructureValue = ()=>{
        setIsStructure(!isStructure);
    }

    const changeStructureParam = e =>{
        
        setStructureParam({
            Session:e.target.value.replaceAll(" ", "") + 'Session',
            PowerOn:e.target.value.replaceAll(" ", "") + 'PowerOn',
            AllTime:e.target.value.replaceAll(" ", "") + 'AllTime'
        })
    }

    const closeModal = ()=>{
        closeForm(formRef.current.resetForm)
    }


    useEffect(()=>{
    /*  EDITVALUE
        descrizione : "Schermata attiva sul display utente"
        is_structure : false
        label_frontend : ""
        nome : "ActiveScreen"
        pk : 1
        tipologia : "Intero "
        unita_di_misura : "-" 
    */

        setInitialState(modality==='edit' ? editValue: initial);
        setIsStructure(modality==='edit' ? editValue.is_structure: false); 
        setStructureParam(modality==='edit' 
            ?
            {
                Session: editValue.Sessionnome,
                PowerOn: editValue.PowerOnnome,
                AllTime: editValue.AllTimenome,
            }
            : initialStructure
        )
        

        console.log('editValue FORM')
        console.log(editValue);
        console.log(enumStructureKey.SESSION)
    },[editValue])


    const closeForm = (reset) => {
        reset();
        setStructureParam(initialStructure);
        setIsStructure(false);
        toggleShow();
    }



    /* const notificationAction = (type) => {

        console.log(type)
        if (type === "create") {
            NotificationManager.success(
                "Ricetta creata con successo",
                "",
                4000
            );
        } else {
            NotificationManager.success(
                "Ricetta modificata con successo",
                "",
                4000
            );
        }
    } */

    const submit = async (dataItem) => {
        /* 
        descrizione:""
        is_structure:true
        label_frontend:""
        nome:""
        tipologia:""
        unita_di_misura:""
        
        */
       console.log("submit --------------------------")
 
        if (modality === 'create') {

            const response = await zindoService.parametriPOST(
                JSON.stringify({
                    parametro: dataItem,
                    structureKey: structureKey
                })
            )
            
            if(response.status === 200){
                
                let copy = [...paramsHook.params]
                console.log("RESPONSE DATA");
                console.log(response.data);
                if (dataItem.is_structure){
                    console.log("struttura data item")
                    response.data.map(elem => copy.push(elem))
                }else{
                    copy.push(response.data);

                }
               
                paramsHook.setParams(copy)
            }else{
                console.log("GIA ESISTENTE")
            }
        }
        else{
            const response = await zindoService.parametriPUT(
                JSON.stringify({
                    parametro: dataItem,
                    structureKey: structureKey
                })
            )
            console.log("response data")
            console.log(response.data)
            
            if(response.status === 200){
                let copy = [...paramsHook.params];
                if (dataItem.is_structure){
                    response.data.map(elem =>
                        copy = copy.map((param => param.pk === elem.pk ? elem: param))
                        )
                }else{
                    copy = copy.map((param => param.pk ===response.data.pk ? response.data: param));
                }

              
                console.log("copy")
                console.log(copy);
                paramsHook.setParams(copy);
            }else{
                alert("qualcosa è andato storto")//do something
            }
        }

        closeForm(formRef.current.resetForm)
    };

    useEffect(()=>{

        formRef.current.resetForm();

    },[initialState])


    return (<><MDBModal staticBackdrop tabIndex='-1' show={modalHook.staticModal} setShow={modalHook.setStaticModal} className="pt-5">
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBox
                        mx={1}
                        mt={-3}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDBModalHeader style={{ border: 0 }}>
                            <MDBModalTitle style={{ color: "whitesmoke" }}>{modality === 'edit' ? 'Modifica Parametro' : 'Crea Parametro'}</MDBModalTitle>
                            <MDBBtn className="btn-close float right" color="none" aria-label="Close" onClick={closeModal} />
                        </MDBModalHeader>
                    </MDBox>

                    <MDBModalBody className="px-4 py-0">

                        <MDBCol xl={12} lg={12} className='mb-4 pr-3'>
                            <Form
                                ref={formRef}
                                onSubmit={submit}
                                initialValues={initialState}
                                key={"form_recipe"}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <fieldset className={"k-form-fieldset"}>

                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} md={10}>
                                                {modality ==='edit'?
                                                    <Field
                                                        id={"nameID"}
                                                        name={"nome"}
                                                        label={"Parametro"}
                                                        component={FormInput}
                                                        
                                                        onChange={changeStructureParam}
                                                        disabled={true}
                                                        />
                                                 :     <Field
                                                        id={"nameID"}
                                                        name={"nome"}
                                                        label={"Parametro"}
                                                        component={FormInput}
                                                        onChange={changeStructureParam}
                                                        validator={(choice) => existParamValidator(choice, paramsHook.params.map(param => param.nome), structureKey)}
                                                    />
                                                }
                                            </Grid>
                                               
                                            <Grid item xs={12} md={2} >
                                                <Field
                                                
                                                id={"isStructureID"}
                                                name={"is_structure"}
                                                label={"Structure"}
                                                component={FormSwitch}
                                                disabled={modality==='edit'?true:false}
                                                onChange={changeStructureValue}
                                                //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                                                />
                                            </Grid>
                                        </Grid>

                                        {isStructure
                                            ?
                                                <Tabs
                                                    defaultActiveKey="Session"
                                                    id="justify-tab-example"
                                                    className="my-3"
                                                    justify
                                                    >
                                                    {structureKey.map(key =>
                                                        <Tab eventKey={key} title={key}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} >
                                                                    
                                                                <Typography
                                                                component="span"
                                                                variant="inherit"
                                                                color="text.primary"
                                                                >{structureParam[key]}</Typography>
                                                                
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Field
                                                                        id={key+"labelID"}
                                                                        name={key+"label_frontend"}
                                                                        label={"Label"}
                                                                        component={FormInput}
                                                                        validator={(choice) => existLabelValidator(choice, modality==='edit' ? editValue[key+'label_frontend']:"", paramsHook.params.map(param => param.label_frontend))}
                                                                        
                                                                    />
            
                                                                </Grid>
                                                                
                                                                <Grid item xs={12} md={3}>
                                                                    <Field
                                                                        id={key+"unitaID"}
                                                                        name={key+"unita_di_misura"}
                                                                        label={"Unità di misura"}
                                                                        component={FormInput}
                                                                        //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={3}>
                                                                <Field
                                                id={key+"tipologiaID"}
                                                name={key+"tipologia"}
                                                label={"Tipologia"}
                                                component={FormDropDownList}
                                                data={tipologieParametri}
                                                
                                            />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Field
                                                                        id={key+"descrizioneID"}
                                                                        name={key+"descrizione"}
                                                                        label={"Descrizione"}
                                                                        component={FormTextArea}
                                                                        //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                                                                    />
            
                                                                </Grid>
                                                            </Grid>
                                                        </Tab>
                                                    )}     
                                                </Tabs>
                                                
                                            : <Grid container spacing={2}>
                                            
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    id={"labelID"}
                                                    name={"label_frontend"}
                                                    label={"Label"}
                                                    component={FormInput}
                                                    validator={(choice) => existLabelValidator(choice, modality==='edit' ? editValue.label_frontend:"", paramsHook.params.map(param => param.label_frontend))}
                                                    
                                                />

                                            </Grid>
                                            
                                            <Grid item xs={12} md={3}>
                                                <Field
                                                    id={"unitaID"}
                                                    name={"unita_di_misura"}
                                                    label={"Unità di misura"}
                                                    component={FormInput}
                                                    //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                            <Field
                                                id={"tipologiaID"}
                                                name={"tipologia"}
                                                label={"Tipologia"}
                                                component={FormDropDownList}
                                                data={tipologieParametri}   
                                            />
                                            
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field
                                                    id={"descrizioneID"}
                                                    name={"descrizione"}
                                                    label={"Descrizione"}
                                                    component={FormTextArea}
                                                    //validator={(choice) => existRecipeValidator(choice, recipesHook.recipes)}
                                                />

                                            </Grid>
                                        </Grid>
                                        }
                                        </fieldset>
                                        <div className="k-form-buttons">
                                            <Button
                                                themeColor={"primary"}
                                                type={"submit"}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {modality === 'edit' ? 'Aggiorna' : 'Aggiungi'}
                                            </Button>
                                            <Button onClick={() => closeForm(formRenderProps.onFormReset)}>Chiudi</Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        </MDBCol>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
        <NotificationContainer/>
    </>
        
    )
}

