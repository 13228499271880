import { useState, useEffect } from "react";
import styled from "styled-components";
import L, { latLngBounds } from "leaflet";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
/* import Machines from "../Lists/listMachines"; */
import ZindoService from "services/zindo-service";
import Machines from "./listMachines";

import icon from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
} from "mdb-react-ui-kit";

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconRetinaUrl: iconRetinaUrl,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
let errorIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",

  iconRetinaUrl: iconRetinaUrl,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

export function MapMachine() {
  const [center, setCenter] = useState([41.113319, 16.8741]);
  const [ListMarkerPop, setListMarkerPop] = useState([]);


  useEffect(() => {

    getMachineByIdTest();
  }, []);

  let getMachineByIdTest = async () => {

    let data = await ZindoService.getMachineLocation();


    if (data) {
      setCenter(data.center);
      setListMarkerPop(data.allInfo);
    }
  };

  function ZoomComponent() {
    const map = useMap();
    let markerBounds = latLngBounds([]);
    if (ListMarkerPop.length && ListMarkerPop.length > 0) {
      ListMarkerPop.forEach((marker) => {
        markerBounds.extend([marker.latitudine, marker.longitudine]);
      });
      map.fitBounds(markerBounds);
    }

    const bounds = [
      [-180, 200],
      [180, -200],
    ];
    map.setMaxBounds(bounds);
    map.on("drag", function () {
      map.panInsideBounds(bounds, { animate: false });
    });

    return null;
  }

  return (
    <ContainerRealTime>
      <MapContainer
        center={center}
        zoom={10}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        minZoom={3}
        style={{
          width: "auto",
          height: "80vh",
          borderRadius: "5px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=it" />
        <ZoomComponent />
        {ListMarkerPop.map((markerpop, idx) => {
          var cardInfo = (
            <MDBCard>
              <Popup>

                <MDBCardHeader className=""></MDBCardHeader>

                <MDBCardBody className="no-pad ">
                  <Machines
                    city_machine={markerpop.città}
                    machines={markerpop.machines}
                    error={markerpop.error}
                  />
                </MDBCardBody>

                <MDBCardFooter className="zindo-primary px-4 py-3">
                  <MDBCardTitle>{markerpop.azienda}</MDBCardTitle>
                  <MDBCardText>
                    <strong>Località: </strong>
                    {markerpop.città}
                    <br />
                    <br />
                    <strong>Indirizzo: </strong>
                    {markerpop.via}
                    <br />
                    <br />
                    <strong>N° macchine: </strong>
                    {markerpop.numMacchine}
                  </MDBCardText>
                </MDBCardFooter>
              </Popup>
            </MDBCard>
          );

          if (markerpop.error) {
            return (
              <Marker
                position={[markerpop.latitudine, markerpop.longitudine]}
                icon={errorIcon}
                key={idx}
              >
                {cardInfo}
              </Marker>
            );
          } else {
            return (
              <Marker
                position={[markerpop.latitudine, markerpop.longitudine]}
                icon={DefaultIcon}
                key={idx}
              >
                {cardInfo}
              </Marker>
            );
          }
        })}
      </MapContainer>
    </ContainerRealTime>
  );
}

// #Region Styled Component

const ContainerRealTime = styled.div`
  .leaflet-container {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .leaflet-popup-content-wrapper {
    margin: 0;
    padding: 0;

    width: 400px !important;
  }
  .leaflet-popup-content {
    margin: 0;
    width: fit-content !important;
  }
  .leaflet-popup-close-button {
    color: black !important;
    z-index: 10;
    margin: 0;
  }

  .ripple-surface {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    height: 220px;
    border-radius: 10px 10px 0 0;
    margin: 10px;
  }

  .scrollable {
    display: flex;
    max-height: 200px;
  }
  .no-pad {
    padding: 0;
  }
  /* .leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: none !important;
} */

  .glass-effect {
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: #333;
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
  }

  .card-footer {
    color: ghostwhite;
  }

  .zindo-primary {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
  }
  .card-header {
    border-width: 0 !important;
  }

  .leaflet-popup-tip {
    position: relative;
    z-index: -1;
    background: linear-gradient(
      to bottom right,
      rgb(37, 56, 89),
      rgb(68, 102, 162)
    );
  }
  .glass-effect *:not(.badge):not(.rounded-circle) {
    background: transparent !important;
  }

  .error-icon {
    background: red;
  }
`;

// #End region
