import Axios from "axios";
import isAuth from "zindoLayouts/utility/authorization";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {

  _axios = Axios.create();//your-api-endpoint',);

  constructor() {
    this.header = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    this._axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
          config.headers.Authorization = `JWT ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this._axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        // Handle token expiration and retry the request with a refreshed token
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const isRefreshSuccessful = isAuth();
          if (isRefreshSuccessful) {
            return this._axios(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );

  }


  get = async (url, header) => await this.request(this.getOptionsConfig("get", url, header));

  post = async (url, data, header) => await this.request(this.getOptionsConfig("post", url, data, header));

  put = async (url, data, header) => await this.request(this.getOptionsConfig("put", url, data, header));

  patch = async (url, data, header) => await this.request(this.getOptionsConfig("patch", url, data, header));

  delete = async (url, data, header) => await this.request(this.getOptionsConfig("delete", url, data, header));

  getOptionsConfig = (method, url, data = {}, header = this.header) => {

    console.log("---------------------------------------------------")
    console.log(method + " " + url);
    console.log(data)
    console.log(header)
    console.log("---------------------------------------------------")

    return {
      method,
      url,
      data,
      headers: header,
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res.data))
        .catch((ex) => resolve(ex.response.data));
    });
  }
}

export default new HttpService();
