import { useContext, useState, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from '@mui/material/InputLabel';
import Input from "@material-ui/core/Input";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";


// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
    FormInput,
    FormDropDownList,
    FormTextArea,
    FormSwitch,
} from "zindoLayouts/Recipes/components/form-components";
import {
    existParamValidator,
    existLabelValidator,
    errorPwdValidator,
    passwordFormValidator,
    emailValidator,
    loginValidator,
    handlerPassword,
} from "zindoLayouts/Recipes/components/validators";


import { FormInputMod, PasswordInput } from "./customForm";



function Login() {

    const authContext = useContext(AuthContext);

    const formRef = useRef(null);

    const [submitError, setSubmitError] = useState({
        error: false,
        message: ''
    })

    const [initialState, setInitialState] = useState({
        password: "",
        email: "",

    });
    const handlerInputs = () => setSubmitError({ error: false, message: '' });

    /* useEffect(()=>{
          console.log(localStorage.getItem('user'))
      }) */

    const closeModal = () => {
        closeForm(formRef.current.resetForm);
    };

    const closeForm = (reset) => {
        reset();
    };



    const submit = async (dataItem) => {

        console.log(dataItem)

        //accesso
        const response = await AuthService.login(dataItem);

        console.log(response);

        if (response.status === '400') {

            setSubmitError({
                error: true,
                message: response.message
            })
        } else {
            console.log("---------------------------------------------------")
            setSubmitError({
                error: false,
                message: ""
            })
            authContext.login(response.access, response.refresh)
        }



    };

    return (
        <BasicLayoutLanding image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign in
                    </MDTypography>

                </MDBox>
                <MDBox pt={1} pb={3} px={3}>
                    <Form
                        ref={formRef}
                        onSubmit={submit}
                        initialValues={initialState}
                        validator={() => loginValidator(submitError)}
                        key={"form_recipe"}
                        render={(formRenderProps) => (
                            <FormElement>

                                <fieldset className={"k-form-fieldset"}>
                                    {formRenderProps.visited &&
                                        formRenderProps.errors &&
                                        formRenderProps.errors.VALIDATION_SUMMARY && (
                                            <div className={"k-messagebox k-messagebox-error mt-3 mb-0"}>
                                                {formRenderProps.errors.VALIDATION_SUMMARY}
                                            </div>
                                        )}

                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} >
                                            <Field
                                                id={"emailID"}
                                                name={"email"}
                                                label={"Email"}
                                                component={FormInputMod}
                                                onChange={handlerInputs}
                                                validator={emailValidator}
                                            />

                                        </Grid>
                                        <Grid item xs={12} >
                                            <Field
                                                id={"pwdID"}
                                                name={"password"}
                                                label={"Password"}
                                                onChange={handlerInputs}
                                                component={PasswordInput}
                                            />
                                        </Grid>

                                    </Grid>
                                </fieldset>
                                <div className="k-form-buttons">

                                    <Button
                                        style={{ width: '100%', border: 'none', borderRadius: '8px', background: 'linear-gradient(195deg, #49a3f1, #1A73E8)' }}
                                        themeColor={"primary"}
                                        type={"submit"}
                                        size={'large'}
                                        disabled={!formRenderProps.allowSubmit}

                                    >
                                        {'Accedi'}
                                    </Button>


                                </div>
                            </FormElement>
                        )}
                    />
                </MDBox>
            </Card>
        </BasicLayoutLanding>
    );
}

export default Login;
