// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {useLocation, useNavigate} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {RJSFSchema, UiSchema} from "@rjsf/utils";
import {customizeValidator} from "@rjsf/validator-ajv8";
import localizer from 'ajv-i18n';
import Form from '@rjsf/core';
import jwtDecode from "jwt-decode";
import ZindoService from "../../services/zindo-service";
import {Button} from "@progress/kendo-react-buttons";
import {NotificationContainer, NotificationManager} from "react-notifications";


function EditCliente() {
    const {state} = useLocation();
    console.log("QUA QUA")
    console.log(state)

    const [allData, setAllData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [nazione, setNazione] = useState([]);
    const [regione, setRegione] = useState([]);
    const [provincia, setProvincia] = useState([]);
    const [comune, setComune] = useState([]);

    useEffect(() => {
        getCliente();
    }, []);

    useEffect(() => {
        updateValue(allData?.cliente);
    }, [allData]);

    useEffect(() => {
        setRegione(allData?.regioni[formData?.nazione]);
        setProvincia([]);
        setComune([]);
    }, [allData?.regioni, formData?.nazione]);

    useEffect(() => {
        setProvincia(allData?.province[formData?.regione]);
        setComune([]);
    }, [allData?.province, formData?.regione]);

    useEffect(() => {
        setComune(allData?.comuni[formData?.provincia]);
    }, [allData?.comuni, formData?.provincia]);

    const navigate = useNavigate();

    const createNotification = async (type) => {
        if (type === "success") {
            console.log(1);
            NotificationManager.success(
                "Cliente Modificato",
                "Modifica Effettuato",
                5000
            )
            navigate("/gestione_macchine");
        } else if (type === "error") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Problema durante la modifica",
                "Errore durante inserimento",
                5000
            );
        }
    };

    const schema: RJSFSchema = {
        title: 'Modifica Cliente',
        type: 'object',
        required: ['ragione_sociale', 'partita_iva', 'nazione', 'regione', 'provincia', 'comune', 'indirizzo'],
        properties: {
            ragione_sociale: {type: 'string', title: 'Ragione Sociale'},
            partita_iva: {type: 'string', title: 'Partita IVA', maxLength: 11},
            pec: {type: 'string', title: 'PEC'},
            nazione: {type: 'string', title: 'Nazione', enum: nazione},
            regione: {type: 'string', title: 'Regione', enum: regione},
            provincia: {type: 'string', title: 'Provincia', enum: provincia},
            comune: {type: 'string', title: 'Comune', enum: comune},
            indirizzo: {type: 'string', title: 'Indirizzo'},
        },
    };

    const uiSchema: UiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "disabled": false,
                "className": "btn btn-info"
            },
            "submitText": "Applica"
        }
    }

    const onSubmit = async () => {
        let singleDataUserAg = formData;
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        singleDataUserAg["user_id"] = user_id;
        singleDataUserAg["client_id"] = state;
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({userData: singleDataUserAg}),
        };
        const response = await ZindoService.editClientAndSede(requestOptions);
        console.log(response);
        createNotification(response['response']);
    }

    const updateValue = (data) => {
        setNazione(allData?.nazioni);
        setRegione(allData?.regioni[data?.nazione]);
        setProvincia(allData?.province[data?.regione]);
        setComune(allData?.comuni[data?.provincia]);
        setFormData(data);
    }

    const validator = customizeValidator({}, localizer.it);

    let getCliente = async () => {
        let response = await ZindoService.getClienteAndSede(state);
        setAllData(response);
        setFormData(response?.cliente);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={1}>
                <MDBox>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                onChange={(e) => setFormData(e.formData)}
                                validator={validator}
                                onSubmit={onSubmit}
                            >
                                <div>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <NotificationContainer />
        </DashboardLayout>
    );
}

export default EditCliente;