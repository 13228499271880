import React, { useState, useEffect } from "react";

/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */

import MDBox from "components/MDBox";
//import "../style/formStyle.css";
import Grid from "@mui/material/Grid";
import zindoService from "services/zindo-service";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {  BsSearch } from "react-icons/bs";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

import ParamTable from "./ParamsTable";


export default function DashboardParametri() {

    const [rows, setRows]= useState([]);

    const [search, setSearch] = useState('');
    const [filteredRow, setFilteredRow] = useState([]);

    //const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    

    useEffect(()=>{
        const getParams = async()=>{
            /* 
                descrizione : "Schermata attiva sul display utente"
                is_structure : false
                label_frontend : ""
                nome : "ActiveScreen"
                pk : 1
                tipologia : "Intero"
                unita_di_misura : "-"
            */
            /* Esempio di output
            0 : descrizione : "tipologia baresina per le macchine"
                nome : "baresina"
                parametri:
                    0:{nome: 'CutterSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    1:{nome: 'PressSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    2:{nome: 'AirStatus', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    3:{nome: 'CutterStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                    4:{nome: 'PressStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                pk: 1
            */
            const data = await zindoService.parametriGET();
            console.log("data")
            console.log(data)
            setRows(data.data);
            setFilteredRow(data.data);

        };

        getParams();



    },[]);

    useEffect(()=>{

        console.log("Modifca filterRows")
        
        const filteredRows_ = rows.filter(elem => elem.nome.toLowerCase().includes(search));
        console.log(filteredRows_)
        setFilteredRow(filteredRows_);

    },[rows])


    const filterRows = e =>{
        console.log('search')
        let search_ = e.target.value.toLowerCase()
        const filteredRows_ = rows.filter(elem => elem.nome.toLowerCase().includes(search_));
        setFilteredRow(filteredRows_);
        setSearch(e.target.value);
    }
    return (
        <>
            <MDBox pb={2} pt={1} px={2}>
                <Grid container spacing={0} >
                    <Grid item xs={12} md={6} lg={4} my={3}>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Parametri"
                            fullWidth
                            value = {search}
                            onChange = {filterRows}
                            InputProps={{
                            startAdornment: (<InputAdornment position="start"><BsSearch/></InputAdornment>),
                            }}
                            
                        />
                    </Grid>
                    
                        <Grid item xs={12} md={12} xl={12} >
                            <div id="id_standard_param"> 
                                <ParamTable
                                id={'tableParamID'}
                                rows = {filteredRow}
                                paramsHook={{params: rows, setParams: setRows}}
                                />
                            </div>
                        </Grid>
                </Grid>
            </MDBox>
    

            {/* <NotificationContainer/> */}
            
        </>
    );
}

// #End region

