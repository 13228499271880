import React, {useEffect} from "react";
import ZindoService from "services/zindo-service";
/* import { Card } from "primereact/card"; */
import styled from "styled-components";

import {Knob} from "primereact/knob";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Thermometer from "react-thermometer-component";
import {Margin} from "@mui/icons-material";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {BsFillCircleFill} from "react-icons/bs";
import {ResponsiveContainer} from "recharts";
import DataTable from "react-data-table-component";

const RealTime = ({id}) => {
    /* const [expanded, setExpanded] = React.useState(false); */
    /* const [nome, setNome] = React.useState(null); */
    const [cutterSpeed, setCutterSpeed] = React.useState(0);
    const [SpeedSpeed, setSpeedSpeed] = React.useState(0);
    const [temp, setTemp] = React.useState(0);
    const [numCutter, setNumCutter] = React.useState("");
    const [productionTime, setProductionTime] = React.useState("");
    const [activeRec, setActiveRec] = React.useState("");
    const [machineStatus, setMachineStatus] = React.useState("")
    const [minCutter, setMinCutter] = React.useState(1);
    const [maxCutter, setMaxCutter] = React.useState(100);
    const [minPress, setMinPress] = React.useState(1);
    const [maxPress, setMaxPress] = React.useState(100);
    const [colorPress, setColorPress] = React.useState("red");
    const [colorCutter, setColorCutter] = React.useState("red");
    const [realTimeAlarm, setRealTimeAlarm] = React.useState(null)

    /* const handleExpandClick = () => {
      setExpanded(!expanded);
    }; */
    useEffect(() => {
        console.log("Realtime con id: " + id);
        /* let url = `ws://127.0.0.1:8000/ws/mqtt/${id}/`;

        const machineSocket = new WebSocket(url); */
        const machineSocket = ZindoService.getDataMqttSocket(id);
        machineSocket.onmessage = function (e) {
            let data = JSON.parse(e.data);
            if (data.hasOwnProperty("value")) {
                /* console.log("Data ActiveRecipe:", data.value.value.Data.ActiveRecipe); */
                /* console.log(data.value.value.Data); */
                /* setNome(data.value.value.Data.ActiveRecipe); */

                setCutterSpeed(data.value.value.Data.CutterSpeed);
                setSpeedSpeed(data.value.value.Data.PressSpeed);
                setTemp(data.value.value.Data.Temperature);
                setActiveRec(data.value.value.Data.ActiveRecipe);
                setMachineStatus(data.value.value.Data.MachineActivityStatus);
                setNumCutter(data.value.value.Data.CutterCuts.Session);
                toHoursAndMinutes(data.value.value.Data.ProductionTime.Session);
                data.value.value.Data.CutterStatus == 1 ? setColorCutter("green") : setColorCutter("red");
                data.value.value.Data.PressStatus == 1 ? setColorPress("green") : setColorPress("red");
                setRealTimeAlarm(data.value.value.Data.Alarms_Active ? data.value.value.Data.Alarms_Active : null);
                console.log('SONO QUIIIIIIIIIII££££££££££££££££££££££££££');
                console.log(data);
            } else {
                console.log("Data:", data);
            }
        };
        const machine_property = ZindoService.getMachineMixMax(id).then((response) => {
            setMinCutter(response.cutter.min);
            setMaxCutter(response.cutter.max);
            setMinPress(response.press.min);
            setMaxPress(response.press.max);
            console.log(response);
        });
    }, [cutterSpeed, SpeedSpeed, temp, activeRec, minPress, maxPress, minCutter, maxCutter]);

    const toHoursAndMinutes = (totalMinutes) => {
        console.log('quiminuti')
        let minutes = totalMinutes % 60;
        let hours = Math.floor(totalMinutes / 60);

        setProductionTime(`${hours} ore - ${minutes} minuti`);
    }

    const realTimeAlarmList = () => {
        if (realTimeAlarm) {
            console.log(realTimeAlarm)
            let columns = [
                {
                    name: 'Data',
                    selector: row => new Date(row.Timestamp*1000).toLocaleString("it-IT", {hour12:false}),
                },
                {
                    name: 'Descrizione',
                    selector: row => row.Description,
                },
            ];
            return (
                <>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    <Row>
                                        <Col xl={12} md={12} xs={12}>
                                            Allarmi Attivi
                                        </Col>
                                    </Row>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <Row fluid={1}>
                                    <Col xl={12} className="text-center px-5">
                                        <DataTable
                                            columns={columns}
                                            data={realTimeAlarm}
                                            pagination
                                        />
                                    </Col>
                                </Row>
                            </MDBox>
                        </Card>
                    </Grid>
                </>
            );
        }
        return (
            <></>
        );
    }

    return (
        <MDBox pt={6} pb={6}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                <Row>
                                    <Col xl={9} md={7} xs={7}>
                                        Grafico Realtime
                                    </Col>
                                    <Col xl={3} md={5} xs={5}>
                                        <div>Fase di Lavoro: {machineStatus}</div>
                                        <div>Ricetta Attiva: {activeRec}</div>
                                        <div>Numero Tagli: {numCutter}</div>
                                        <div>Tempo di Lavoro: {productionTime}</div>
                                    </Col>
                                </Row>
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <ContainerRealTime>
                                <Container
                                    style={{margin: "12px auto 12px auto"}}
                                    fluid
                                    class="d-flex justify-content-around"
                                >
                                    <Row>
                                        <Col xl={4} md={4} xs={12}>
                                            <div fluid class="d-flex justify-content-around"
                                                 style={{textAlign: "center"}}>
                                                <div>
                                                    <span className="tagNomeKnobPress">Velocità Pressa (Hz)</span>
                                                    <Knob
                                                        value={SpeedSpeed}
                                                        onChange={(e) => setSpeedSpeed(e.value)}
                                                        min={minPress}
                                                        max={maxPress}
                                                        size={150}
                                                        readOnly
                                                    />
                                                    <BsFillCircleFill color={colorPress}/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} md={4} xs={12}>
                                            <div fluid class="d-flex justify-content-around">
                                                <Thermometer
                                                    theme="light"
                                                    value={Math.round((temp + Number.EPSILON) * 100) / 100}
                                                    max="100"
                                                    steps="1"
                                                    format="°C"
                                                    size="normal"
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={4} md={4} xs={12}>
                                            <div fluid class="d-flex justify-content-around"
                                                 style={{textAlign: "center"}}>
                                                <div>
                                                    <span className="tagNomeKnobTaglie">Velocità Taglierina (Hz)</span>
                                                    <Knob
                                                        value={cutterSpeed}
                                                        onChange={(e) => setCutterSpeed(e.value)}
                                                        min={minCutter}
                                                        max={maxCutter}
                                                        size={150}
                                                        readOnly
                                                    />
                                                    <BsFillCircleFill color={colorCutter}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </ContainerRealTime>
                        </MDBox>
                    </Card>
                </Grid>
                {realTimeAlarmList()}
            </Grid>
        </MDBox>
    );
};

export default RealTime;

// #Region Styled Component

const ContainerRealTime = styled.div`
  .thermometer ul.thermometer__statistics li {
    width: auto !important;
  }
`;

const Spazer = styled.div`
  height: 5vh;
`;

// #End region
