import React, { useState } from "react";
import ZindoService from "services/zindo-service";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ListOfMachines from "./listMachine";
import ListOfClients from "./listClients";

import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import MDButton from "components/MDButton";

function AllComponents() {
  const [listMachineCheched, setListMachineCheched] = useState([]);
  const [listClientiCheched, setListClientiCheched] = useState([]);
  const [checkUpdate, setCheckUpdate] = useState(0);
  const [checked, setChecked] = useState([]);

  const createNotification = (type) => {
    if (type === "info") {
      NotificationManager.info("Info message");
    } else if (type === "success") {
      setCheckUpdate((checkUpdate) => checkUpdate + 1);
      NotificationManager.success(
        "Cliente e Macchina associata",
        "Inserimento Effettuato",
        5000
      );
    } else if (type === "warning") {
      NotificationManager.warning(
        "Warning message",
        "Close after 3000ms",
        3000
      );
    } else if (type === "error") {
      NotificationManager.error(
        "Problema durante l'inserimento",
        "Errore durante inserimento",
        5000
      );
    }
  };

  const sendToBAckend = async () => {
    if (listMachineCheched.length !== 0 && listClientiCheched.length !== 0) {
      let listMachine = listMachineCheched;
      let listClienti = listClientiCheched;
      /* const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          listaDelleMacchine: listMachine,
          listaDeiClienti: listClienti,
        }),
      }; */
      const response = await ZindoService.associamacchineclienti(
        JSON.stringify({
          listaDelleMacchine: listMachine,
          listaDeiClienti: listClienti,
        })
      );
      /* const response = await fetch(
        "http://127.0.0.1:8000/zindoapp/associamacchineclienti/",
        requestOptions
      ); */
      const data = response;
      createNotification(data[0]["responce"]);
      setChecked([]);
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Gestione Macchine
              </MDTypography>
            </MDBox>
            <Row flex>
              <Col xs={12} md={12} lg={6}>
                <ListOfMachines
                  listMachineCheched={listMachineCheched}
                  setListMachineCheched={setListMachineCheched}
                  checkUpdate={checkUpdate}
                  setCheckUpdate={setCheckUpdate}
                  checked={checked}
                  setChecked={setChecked}
                />
              </Col>
              <Col xs={12} md={12} lg={1}>
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="button"
                    className="float-right mt-3"
                    onClick={sendToBAckend}
                  >
                    Associa
                  </MDButton>
                  {/* <AwesomeButton
                    type="primary"
                    onPress={() => {
                      sendToBAckend();
                    }}
                  >
                    Button
                  </AwesomeButton> */}
                </div>
              </Col>
              <Col xs={12} md={12} lg={5}>
                <ListOfClients
                  listClientiCheched={listClientiCheched}
                  setListClientiCheched={setListClientiCheched}
                />
              </Col>
            </Row>
          </Card>
        </Grid>
      </Grid>
      <NotificationContainer />
    </MDBox>
  );
}

export default AllComponents;
