import styled from "styled-components";

import { Scrollbar } from 'smooth-scrollbar-react';

import { useState, useEffect } from "react";


//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import '@progress/kendo-theme-default/dist/all.css';


import { MDBCheckbox } from 'mdb-react-ui-kit';


import React from "react";
//import Button from 'react-bootstrap/Button';

import { BsPlus, BsSearch } from "react-icons/bs";

import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';



import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import Select from 'react-select';
import DataTable from "examples/Tables/DataTable";


import "./style/style.css";
import { useMaterialUIController } from "context";
import { BsPlusCircleFill } from "react-icons/bs";
import { BsPencilFill } from "react-icons/bs";
import IconButton from '@mui/material/IconButton';

import ZindoService from "services/zindo-service";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBRadio,
    MDBRow
} from 'mdb-react-ui-kit';


import { BsTrash3 } from "react-icons/bs";

import baresimaImage from "assets/images/machineImage/baresina.png"

import { StatusCodes } from "http-status-codes";



import DefaultTableRecipes from "./components/DefaultTableRecipes";
import RecipesForm from "./components/RecipeForm";



export default function Recipes() {

    //const [isOpen, setOpen] = useState(false);

    const [staticModal, setStaticModal] = useState(false);
    const toggleShow = () => setStaticModal(!staticModal);

    const [staticModalEdit, setStaticModalEdit] = useState(false);
    const toggleShowEdit = () => setStaticModalEdit(!staticModalEdit);

    const [editData, setEditData] = useState(null)


    const showEditModal = (idMac, recipe) => {

        setStaticModalEdit(!staticModalEdit);
        setEditData({ 'mach': idMac, 'recipe': recipe });
    }



    const [machines, setMachines] = useState([])
    const [recipes, setRecipes] = useState([])
    const [typeMachines, setTypeMachines] = useState([]);
    const [choiceType, setChoiceType] = useState(null);
    const [params, setParams] = useState([]);

    const [rows, setRow] = useState([]);
    const [columns, setColumns] = useState([]);

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const getRecipesSocket = (id, nomeMacchina) => {

        const machineSocketRecipes = ZindoService.getRecipesSocket(id);


        machineSocketRecipes.onopen = async function (e) {
            console.log("open")
            console.log("setting spento")
            var update = [];
            var ricette = {
                "recipes": [],
                "isactive": 2,
                "nomeMacchina": nomeMacchina,
                'activeRecipe': '',
                "idMachine": id
            };
            update = recipes.concat(ricette);
            setRecipes(update);
            let payload = JSON.stringify({ data_options: { idMachines: id } });
            const data = await ZindoService.getRecipes(payload);
        }

        machineSocketRecipes.onmessage = function (e) {
            let data = JSON.parse(e.data);
            /* var update = [];
            var ricette = {
                "recipes": [],
                "isactive": 2,
                "nomeMacchina": nomeMacchina,
                'activeRecipe': '',
                "idMachine": id
            }; */
            console.log("DATA WEBSOCKET ----------------------------------------")
            console.log(data)
            console.log("-------------------------------------------------------")
            console.log("-------------------------------------------------------")
            if (data.value !== undefined && data.value.value.Data.Recipes) {
                //console.log("data ricette macchina:")
                //console.log(data.value.value.Data.Recipes);
                let in_esecuzione = 0; //stop produzione
                let check_param = params.filter(item => item.parametro_stato === true);

                console.log("check");
                console.log(check_param);

                /* if ((data.value.value.Data.ActiveScreen === 0) &&
                    (data.value.value.Data.CutterStatus === 0) &&
                    (data.value.value.Data.PressStatus === 0)) {
                    in_esecuzione = 0; //stop produzione
                } */
                if (data.value.value.Data.ActiveScreen !== 0) {
                    in_esecuzione = 1; //produzione
                }

                for (let param of check_param) {
                    console.log(param.nome);
                    console.log(data.value.value.Data[param.nome])
                    if (data.value.value.Data[param.nome] !== 0) {
                        in_esecuzione = 1; //produzione  
                        break;
                    }
                }
                console.log("-----------------------------");

                var ricette = {
                    "recipes": data.value.value.Data.Recipes,
                    "isactive": in_esecuzione, //per test
                    "nomeMacchina": nomeMacchina,
                    'activeRecipe': data.value.value.Data.ActiveRecipe,
                    "idMachine": id
                }

                machineSocketRecipes.close();
                var update = recipes.concat(ricette);
                setRecipes(update)
            };

            ;
        };

    };

    const handlerActiveRecipe = async (e, mac) => {
        console.log(mac);

        let payload = JSON.stringify({
            data_options: {
                idMachine: parseInt(mac),
                recipe: e.target.value
            }
        })

        const data = await ZindoService.setActiveRecipe(payload);

    }


    useEffect(() => {
        let rows = [];

        let active = recipes.some(item => item.isactive === 0);
        console.log("active");
        console.log(active)

        for (let item of recipes) {
            for (let rec of item.recipes) {
                let recipe = Object.assign({}, { macchina: item.nomeMacchina }, rec);
                if (recipe.Name !== "*RISCALDAMENTO*") {
                    recipe.action = (<>
                        <EditButton color="secondary" disabled={item.isactive} >
                            <BsPencilFill size={20} onClick={() => showEditModal(item.idMachine, rec)} id={"actionHeader"} />
                        </EditButton>
                        <ParamButton color="secondary" disabled={item.isactive}>
                            <BsTrash3 size={20} onClick={() => removeRecipe(item.idMachine, rec.Name)} />
                        </ParamButton>
                    </>)
                }
                rows.push(recipe);
            }
            let status = [...machines];

            status = status.map(obj => {
                if (obj.id === parseInt(item.idMachine)) {
                    switch (item.isactive) {
                        case 0: //non attivo / stop produzione
                            obj.status = 'Stop produzione';
                            obj.color = 'green'
                            break;
                        case 1: // attivo / produzione
                            obj.status = 'Produzione';
                            obj.color = '#ffa937';
                            break;
                        case 2: // spento
                            obj.status = 'Spento';
                            obj.color = 'red';
                            break;
                        default:
                            break;
                    }
                    obj.hidden = false;
                }

                return obj;

            })

            setMachines(status);
            console.log('ROWS -----------------------------');
            console.log(rows)
            rows = rows.map(rec => rec.macchina === item.nomeMacchina
                ? rec.Name === item.activeRecipe ?
                    {
                        ...rec, active: <MDBRadio name={'actRec_' + rec.macchina}
                            defaultChecked onChange={e => handlerActiveRecipe(e, item.idMachine)}
                            value={rec.Name}
                            disabled={item.isactive}
                        />
                    } : {
                        ...rec, active: <MDBRadio name={'actRec_' + rec.macchina}
                            onChange={e => handlerActiveRecipe(e, item.idMachine)}
                            value={rec.Name}
                            disabled={item.isactive}
                        />

                    }
                : rec
            )

        }
        //document.getElementById("actionHeader").setAttribute('disabled', active);
        setColumns(columns.map(item => item.id === "id_plus"
            ? {
                ...item,
                Header: <Button variant="text" onClick={toggleShow} id={"actionHeader"} disabled={!active} ><BsPlus size={30} />AGGIUNGI</Button>

            }
            : item))

        setRow(rows);

    }, [recipes]);

    const getRecipes = async (id, nome) => {
        //console.log(id);
        let payload = JSON.stringify({ data_options: { idMachines: id } });

        getRecipesSocket(id, nome);

        console.log("weboscket is open")

        //const data = await ZindoService.getRecipes(payload);


    };

    const removeRecipe = async (id, nomeRic) => {

        const data = await ZindoService.removeRecipe(
            JSON.stringify({
                data_options: {
                    idMachine: id,
                    nomeRicetta: nomeRic
                },
            })
        );

        if (parseInt(data.status) == StatusCodes.OK) {

            let rec = recipes.filter((rec) => rec.idMachine === id)[0];

            let filter_recipes = rec.recipes.filter((recipe) => recipe.Name != nomeRic);

            let totale = recipes.map(item =>
                item.idMachine === rec.idMachine
                    ? { ...item, recipes: filter_recipes }
                    : item
            )
            setRecipes(totale);
        }

    };

    const getTypeMac = async () => {

        const types = await ZindoService.getTypeMachines();

        var t = [];
        types.map((type, index) => {
            t.push({ id: index, value: type.pk, label: type.nometipologia });

        })
        console.log("TYPES")
        console.log(types);
        setTypeMachines(t);

    }

    const getMachines = async (choice) => {

        setChoiceType(choice.value);

        // -----
        document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
        setRecipes([]);
        // -----

        var machines = await ZindoService.getMachines(choice.value);
        const headerTable = await ZindoService.getParamsType(choice.value);

        console.log("Machines")
        machines.map(mac => {
            mac.status = '';
            mac.color = '';
            mac.hidden = true
        })
        console.log(machines);
        console.log("PARAMS")
        console.log(headerTable);


        setParams(headerTable);
        setMachines(machines);

        let col = [{ Header: "macchina", accessor: "macchina", align: "center", id: 'macid' },
        { Header: "ricetta", accessor: "Name", align: "center", id: 'recid' },

        ];

        if (headerTable.length) {
            let i = 0;
            for (let elem of headerTable) {
                if (elem.in_ricetta) {
                    col.push({ Header: elem.label_frontend, accessor: elem.nome, align: "center", id: i });
                    i++;
                }


            }
            col.push({ Header: "attiva", accessor: "active", align: "center", id: 'id_active' });

            col.push(
                {
                    Header: <Button variant="text" onClick={toggleShow} id={"actionHeader"} disabled ><BsPlus size={30} />AGGIUNGI</Button>

                    , accessor: "action", align: "center", id: "id_plus"
                }
            )
        }

        setColumns(col);
        console.log("machines (Recipes/index: row 261):")

        document.getElementById('id_recipes').classList.add("recipes-visible")
        document.getElementById('id_recipes').classList.remove("recipes-hidden");
    }

    useEffect(() => {

        getTypeMac();

    }, [])

    const choicedmachine = (e) => {
        //console.log(e.target.checked);

        if (e.target.checked) {

            getRecipes(e.target.value, e.target.name);
        } else {
            let status = [...machines];
            status = status.map(obj => {
                if (obj.id === parseInt(e.target.value)) {
                    obj.status = '';
                    obj.color = '';
                    obj.hidden = true
                }
                return obj
            })
            setMachines(status);
            /* document.getElementById(e.target.value + "active").innerHTML = "";
            document.getElementById(e.target.value + "active").hidden = true; */
            var rec = recipes.filter((rec) => rec.idMachine != e.target.value);

            //let mac = selectedMac.filter(mac => mac != e.target.value);
            setRecipes(rec);
            //setSelectedMac(mac);
        }
    }


    const searchMac = (value) => {

    }


    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        RICETTE
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <MDBox pt={1} pb={2} >
                                        <Grid container spacing={0} >
                                            <Grid item xs={12} sm={12} md={5} xl={3} mb={3} px={2}>
                                                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}
                                                    bgColor={darkMode ? "transparent" : "grey-100"}
                                                    style={{ borderRadius: '0.5rem' }}>
                                                    <MDBox
                                                        id={'box_macchine'}
                                                        component="li"
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        borderRadius="lg"

                                                        mb={1}
                                                        mt={2}
                                                    >
                                                        {/* SELECT */}
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12} md={12} xl={12}>
                                                                <MDBox width="100%" display="flex" flexDirection="column">
                                                                    <MDBox px={2}>
                                                                        <MDBox mb={2} >
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                defaultValue={""}
                                                                                isSearchable={true}
                                                                                name="color"
                                                                                options={typeMachines}
                                                                                onChange={(choice) => getMachines(choice)}
                                                                            />
                                                                        </MDBox>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Grid>
                                                            <Grid item xs={12} md={12} xl={12}>
                                                                <MDBox width="100%" display="flex" flexDirection="column">
                                                                    <MDBox px={2}>
                                                                        <MDBox>
                                                                            <Scrollbar
                                                                                className="scrollable"
                                                                                alwaysShowTracks={true}

                                                                                style={{ maxHeight: "530px", padding: "0px" }}

                                                                                plugins={{
                                                                                    overscroll: {
                                                                                        effect: "bounce"
                                                                                    }
                                                                                }}
                                                                            >

                                                                                <div className="scroll-content" hidden={machines.length ? false : true}>
                                                                                    <MDBRow className={"mx-0"}>
                                                                                        {machines.map((machine, index) => (

                                                                                            <MDBCard className="my-1 borderless-card px-0">
                                                                                                <MDBCardBody className='d-flex align-items-center' >

                                                                                                    <MDBCheckbox value={machine.id} name={machine.Nome} onClick={choicedmachine} />
                                                                                                    <img
                                                                                                        src={baresimaImage}
                                                                                                        alt=''
                                                                                                        style={{ width: '35px', height: '35px' }}
                                                                                                        className='rounded-circle'
                                                                                                    />
                                                                                                    <div className='ms-3'>
                                                                                                        <font className='fw-bold m-0' size="3">{machine.Nome}</font><br />
                                                                                                        <font className='text-muted m-0' size="3">{machine.NomeUnivocoMacchina}</font><br />
                                                                                                        <font className='text-muted m-0' size="3">{machine.lteMacMacchina}</font><br />
                                                                                                        <font id={machine.id + "active"} size="3"
                                                                                                            hidden={machine.hidden}
                                                                                                            color={machine.color}
                                                                                                        >
                                                                                                            {machine.status}
                                                                                                        </font>
                                                                                                    </div>

                                                                                                </MDBCardBody>
                                                                                            </MDBCard>
                                                                                        ))}
                                                                                    </MDBRow>
                                                                                </div>

                                                                            </Scrollbar>
                                                                        </MDBox>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Grid>
                                                        </Grid>
                                                    </MDBox>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7} xl={9} px={2}>

                                                <div id="id_recipes" className="recipes-hidden">
                                                    <MDBox className={"mx-0 mb-2 mt-0"}>

                                                        <DataTable
                                                            id="recipesTable"
                                                            table={{ columns, rows }}
                                                            isSorted={false}
                                                            entriesPerPage={false}
                                                            showTotalEntries={false}
                                                            noEndBorder
                                                        //canSearch={true}
                                                        />

                                                    </MDBox>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} px={2}>

                                                <DefaultTableRecipes
                                                    col={columns}
                                                    typeMachine={choiceType}
                                                    selectedMachines={recipes}
                                                    recipesHook={{ recipes: recipes, setRecipes: setRecipes }}
                                                />

                                            </Grid>
                                        </Grid>
                                    </MDBox>

                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout >

            <RecipesForm params={params}
                toggleShow={toggleShow}
                modalHook={{ staticModal: staticModal, setStaticModal: setStaticModal }}
                recipesHook={{ recipes: recipes, setRecipes: setRecipes }}
                modality={'create'}
            />
            <RecipesForm params={params}
                toggleShow={toggleShowEdit}
                modalHook={{ staticModal: staticModalEdit, setStaticModal: setStaticModalEdit }}
                recipesHook={{ recipes: recipes, setRecipes: setRecipes }}
                nameRecipeEdit={editData}
                modality={'edit'}
            />
        </>
    )
}


const ParamButton = styled(IconButton)({
    '&:hover': {
        color: 'red',
        boxShadow: 'none',
    }
});
const EditButton = styled(IconButton)({
    '&:hover': {
        color: '#1A73E8',
        boxShadow: 'none',
    }
});
const ReverseButton = styled(IconButton)({
    '&:hover': {
        color: '#1A73E8',
        boxShadow: 'none',
    }
});


