import styled from "styled-components";
import React, { useState } from "react";

import DashBoardGraf from "./DBComponent/dashBoardgraf";
import RealTime from "./DBComponent/dashBoardRealTime";

import Example from "./DBComponent/dashBoardMotor";

import { useParams } from "react-router-dom";

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { NavLink } from "react-router-dom";
import { isAuthenticated } from "../../utils";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import "primereact/resources/themes/lara-light-indigo/theme.css";

import "primereact/resources/primereact.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";
import { MDBIcon, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";

export default function DashBoardMachineAll() {
    const params = useParams();
    /* console.log(params); */

    let history = useNavigate();
    const dns = "http://127.0.0.1:3000";
    const url = window.location.href.split(dns)[1]
        ? window.location.href.split(dns)[1]
        : "/";
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value: string) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    useEffect(() => {
        if (!isAuthenticated()) {
            history(`/login?redirectTo=${url}`);
        }
    }, []);

    return (
        <ContainerDashBoard>
            <MDBTabs className='mb-3'>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                        Real Time
                    </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                    <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                        Storico
                    </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
                <MDBTabsPane open={basicActive === 'tab1'}
                    style={{ display: basicActive === 'tab1' ? 'contents' : "none" }}>
                    <Row>
                        <Col>
                            <RealTime id={params.idMachine} />
                        </Col>
                    </Row>
                </MDBTabsPane>
                <MDBTabsPane open={basicActive === 'tab2'}
                    style={{ display: basicActive === 'tab2' ? 'contents' : "none" }}>
                    <Row>
                        <Col>
                            <DashBoardGraf id={params.idMachine} />
                        </Col>
                    </Row>
                </MDBTabsPane>
            </MDBTabsContent>
        </ContainerDashBoard>
    );
}

// #Region Styled Component
const ContainerDashBoard = styled.div``;

// #End region
