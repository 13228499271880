import React, { useCallback, useState, useRef, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
/* import { v } from "../style/Variables"; */
/* import { Button, ButtonGroup, Dropdown } from "react-bootstrap"; */


import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBCol,
    MDBRow

} from 'mdb-react-ui-kit';

import MDBox from "components/MDBox";

import "../style/formStyle.css";

import Grid from "@mui/material/Grid";

import { useMaterialUIController } from "context";


import zindoService from "services/zindo-service";




import Card from '@mui/material/Card';

import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import {  BsSearch } from "react-icons/bs";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

import TypeTable from "./TableTipologia";


export default function DashboardTipologia() {

    //const { columns, rows, datas } = TipologiaTableData();
    const [rows, setRows]= useState([]);
    const [controller] = useMaterialUIController();
    const [search, setSearch] = useState('');
    const [filteredRow, setFilteredRow] = useState([]);

    //const [tabsOrientation, setTabsOrientation] = useState("horizontal");

    

    useEffect(()=>{
        const getTypeMachine = async()=>{
            /* Esempio di output
            0 : descrizione : "tipologia baresina per le macchine"
                nome : "baresina"
                parametri:
                    0:{nome: 'CutterSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    1:{nome: 'PressSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    2:{nome: 'AirStatus', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}
                    3:{nome: 'CutterStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                    4:{nome: 'PressStatus', in_dasboard_realtime: false, in_ricetta: false, parametro_stato: true}
                pk: 1
            */
            const data = await zindoService.getAllTypeMachines();
            setRows(data);
            setFilteredRow(data);

        };

        getTypeMachine();



    },[]);


    const navigate = useNavigate();

    const inserisciTipologia = () => {
        navigate("/tipologia-macchina/crea", {state:{'tipologie':rows.map(type=> type.nome)}});
    };

    const updateTipologia = (tipologia) => {
        navigate("/tipologia-macchina/modifica", {state:{'tipologia':tipologia}});
    };
    const getStdRecipes = (tipologia) => {
        navigate("/tipologia-macchina/ricette-standard", {state:{'typeName':tipologia.nome, 'tipologiaID':tipologia.pk}});
    };

    const deleteTipologia = async (pk) =>{

        const response = await zindoService.deleteTipologia(pk);
        setRows(rows.filter(item => item.pk !== pk));
        setFilteredRow(rows.filter(item => item.pk !== pk));
    }


    const filterRows = e =>{
        let search_ = e.target.value.toLowerCase()
        const filteredRows_ = rows.filter(elem => elem.nome.toLowerCase().includes(search_));
        setFilteredRow(filteredRows_);
        setSearch(e.target.value);
    }
    return (
        <>
            <MDBox pb={2} pt={1} px={2}>
                <Grid container spacing={0} >
                    <Grid item xs={12} md={6} lg={4} my={3}>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Tipologia macchina"
                            fullWidth
                            value = {search}
                            onChange = {filterRows}
                            InputProps={{
                            startAdornment: (<InputAdornment position="start"><BsSearch/></InputAdornment>),
                            }}
                            
                        />
                    </Grid>
                    
                        <Grid item xs={12} md={12} xl={12} >
                            <div id="id_standard"> 
                                <TypeTable 
                                    data = {filteredRow} 
                                    create={inserisciTipologia} 
                                    update={updateTipologia} 
                                    del={deleteTipologia} 
                                    recipes={getStdRecipes}
                                />
                            </div>
                        </Grid>
                </Grid>
            </MDBox>
            {<NotificationContainer/>
            }
        </>
    );
}

// #End region


/* {filteredRow.length ? <Carousel
                                animation='slide'  
                                indicators={false} 
                                duration={700} 
                                index={index}
                                // fullHeightHover={false}  
                                // navButtonsAlwaysVisible={true} 
                                autoPlay={false}
                                className="px-0"
                               
                                
                                > 
                                
                                    {filteredRow.map((tipologia,index) =>(
                                        <CarouselCard variant="outlined" >
                                    <div className={'px-5 py-3'}>
                                    
                                        <CardContent>
                                            <Grid container spacing={2} >
                                                <Grid item xs={6}  >
                                                    <Typography variant="h5" component="div">
                                                        {tipologia.nome}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}  >
                                                <CardActions >
                                        <div className="k-form-buttons px-3">
                                            <MDButton
                                                
                                                type={"button"}
                                                variant="gradient"
                                                color="info"
                                                onClick={ () => updateTipologia(tipologia, index)}                                    
                                            >
                                                Modifica
                                            </MDButton>
                                            <MDButton
                                                
                                                type={"button"}
                                                
                                                color="error"

                                                onClick={ () => updateTipologia(tipologia, index)}
                                                                                   
                                            >
                                                Elimina
                                            </MDButton>
                                        </div>
                                        </CardActions>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={5} xl={6} style={{ height: "350px" }}>
                                                    <Scrollbar
                                                        className="scrollable"
                                                        alwaysShowTracks={true}

                                                        style={{ maxHeight: "310px", padding: "0px" }}
                                                        plugins={{
                                                            overscroll: {
                                                                effect: "bounce"
                                                            }
                                                        }}
                                                    >
                                                        
                                                            <Typography sx={{ mb: 1.5 }} color="text.secondary" align="justify">
                                                                {tipologia.descrizione}
                                                            </Typography>
                                                        
                                                    </Scrollbar>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={7} xl={6} >
                                                    <Scrollbar
                                                        className="scrollable"
                                                        alwaysShowTracks={true}

                                                        style={{ maxHeight: "310px", padding: "0px" }}
                                                        plugins={{
                                                            overscroll: {
                                                                effect: "bounce"
                                                            }
                                                        }}
                                                    >
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 150, borderShadow:'none' }} aria-label="simple table">
                                                                <TableHead sx={{ display: "table-header-group" }}>
                                                                <TableRow>
                                                                    <TableCell></TableCell>
                                                                    <TableCell align="center">Ricetta</TableCell>
                                                                    <TableCell align="center">Realtime</TableCell>
                                                                    <TableCell align="center">Controllo</TableCell>
                                                                    
                                                                </TableRow>
                                                                </TableHead>
                                                                <TableBody >
                                                                //{ 0:{nome: 'CutterSpeed', in_dasboard_realtime: false, in_ricetta: true, parametro_stato: false}}
                                                                {tipologia.parametri.map((param) => (
                                                                    
                                                                    <TableRow
                                                                    key={param.nome}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                    <TableCell component="th" scope="row">
                                                                        {param.nome}
                                                                    </TableCell>
                                                                    <TableCell align="center">{param.in_ricetta ? 1 : 0}</TableCell>
                                                                    <TableCell align="center">{param.in_dasboard_realtime ? 1 : 0}</TableCell>
                                                                    <TableCell align="center">{param.parametro_stato ? 1 : 0}</TableCell>

                                                                    </TableRow>
                                                                ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                                                                            
                                                        
                                                    </Scrollbar>
                                                </Grid>
                                                
                                                
                                            </Grid>
                                        </CardContent>
                                        
                                    </div>
                                    
                                    </CarouselCard>

                                    ))
                                    }

                                </Carousel>: "nessuna tipologia di macchina trovata"}
                                
 */