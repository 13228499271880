import axios from "axios";
import jwtDecode from "jwt-decode";

export function isAuthenticated() {

    let token = localStorage.getItem('access_token');
    let refresh_token = localStorage.getItem('refresh_token');

    if (!token) {
        return false
    } else {
        let tokenParts = jwtDecode(token);
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp < now) {

            let refresh_tokenParts = jwtDecode(refresh_token)

            if (refresh_tokenParts.exp > now) {

                axios.post('http://127.0.0.1:8000/api/token/refresh/', { refresh: refresh_token })
                    .then((response) => {

                        console.log("setting new token")
                        console.log("Response: " + response.data.access)
                        console.log("Response: " + response.data.refresh)
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
                        return true

                    })
                    .catch((err) => {
                        return false
                    });

            }
            else {
                return false
            }
        }
        return true
    }
}