import React, { Component } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "../style/formStyle.css";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


export default class TipologiaMacchineTemplate extends Component {
    render() {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3} style={{ minWidth: 280 }}>
                    <Grid container spacing={6} >
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        {this.props.title}
                                    </MDTypography>
                                </MDBox>
                                <MDBox>

                                    {/* <DashBoardInsertVar /> */}
                                    {this.props.component}

                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>
        );
    }
}

// #End region
