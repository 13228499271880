import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormInput, FormDateInput, FormRadioGroup } from "./form-components";
import {
  userNameValidator,
  emailValidator,
  requiredValidator,
  codiceFiscaleValidator,
  /* passwordValidator, */
} from "./validators";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { genders } from "./data";
export const AccountDetails = (
  <div>
    <Field
      key={"userName"}
      id={"userName"}
      name={"userName"}
      label={"Nome Utente"}
      component={FormInput}
      validator={userNameValidator}
    />
    <Field
      key={"userSurname"}
      id={"userSurname"}
      name={"userSurname"}
      label={"Cognome Utente"}
      component={FormInput}
      validator={userNameValidator}
    />
    <Field
      key={"platformName"}
      id={"platformName"}
      name={"platformName"}
      label={"Nome Su piattaforma"}
      component={FormInput}
      validator={userNameValidator}
    />
    <Row>
      <Col xs={12} md={10} lg={10}>
        <Field
          key={"birthDate"}
          id={"birthDate"}
          name={"birthDate"}
          label={"Data di nascita"}
          component={FormDateInput}
          // validator={requiredValidator}
        />
      </Col>
      <Col xs={12} md={2} lg={2}>
        <Field
          key={"gender"}
          id={"gender"}
          name={"gender"}
          label={"Gender"}
          layout={"horizontal"}
          component={FormRadioGroup}
          data={genders}
          // validator={requiredValidator}
        />
      </Col>
    </Row>

    <Field
      key={"codiceFiscale"}
      id={"codiceFiscale"}
      name={"codiceFiscale"}
      label={"Codice Fiscale"}
      component={FormInput}
      // validator={codiceFiscaleValidator}
    />
    <Field
      key={"email"}
      id={"email"}
      name={"email"}
      label={"Email"}
      hint={"Inserisci la tua e-mail"}
      type={"email"}
      component={FormInput}
      validator={emailValidator}
    />
  </div>
);
