export const regexStructureKey = /(Session|PowerOn|AllTime)/g

export const enumStructureKey = {
    SESSION: 'Session',
    POWERON: 'PowerOn',
    ALLTIME: 'AllTime'
}
// Ha un ordine preciso di seguito l'ordine: stringa->intero->float->bool
export const enumTypeParam = {
    STRING: 'String',
    INTEGER: 'Intero',
    FLOAT: 'Float',
    BOOL: 'Bool'
}

export const enumSection = {
    HOME: 'home',
    GESTIONE_MACCHINE: 'gestione_macchine',
    MACCHINE: 'macchine',
    RICETTE: 'ricette',
    TIPOLOGIA: 'tipologia_macchine',
    PARAMETRI: 'parametri',
    PROFILO: 'profilo'
}