import * as React from "react";
import ZindoService from "services/zindo-service";

import { Field } from "@progress/kendo-react-form";
import { FormInput, FormAutoComplete, FormCheckbox } from "./form-components";
import {
  nameValidator,
  requiredValidator,
  partitaIvaValidator,
  /* emailValidatorNotREquired, */
  userNameValidator,
} from "./validators";
import { countries, countryEn } from "./data";
import Divider from "@mui/material/Divider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
/* import { align } from "@progress/kendo-drawing"; */

/* import { align } from "@progress/kendo-drawing"; */

function PersonalDetails() {
  const [regionHook, setRegionHook] = React.useState([]);
  const [cityHook, setCityHook] = React.useState([]);
  const [provinciaHook, setProvinciaHook] = React.useState([]);

  const setRegion = async (event) => {
    if (countries.includes(event.value)) {
      let index = countries.indexOf(event.value);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country: countryEn[index] }),
      };
      const response = await ZindoService.getRegion(requestOptions);
      /* const response = await fetch(
        "http://127.0.0.1:8000/zindoapp/getregions/",
        requestOptions
      ); */
      const data = response;
      if (data) {
        setRegionHook(data);
      }
    }
  };

  const setProvince = async (event) => {
    /* console.log(event); */
    let regionFromPost = event.value;
    const requestOptionsCity = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ region: regionFromPost }),
    };
    const response = await ZindoService.getProvince(requestOptionsCity);
    /* const response = await fetch(
      "http://127.0.0.1:8000/zindoapp/getcitys/",
      requestOptionsCity
    ); */
    const data = response;
    if (data) {
      setProvinciaHook(data.provincieNome);
    }
  };

  const setCity = async (event) => {
    console.log(event);
    let regionFromPost = event.value;
    const requestOptionsCity = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ region: regionFromPost }),
    };
    const response = await ZindoService.getCity(requestOptionsCity);
    /* const response = await fetch(
      "http://127.0.0.1:8000/zindoapp/getcitys/",
      requestOptionsCity
    ); */
    const data = response;
    if (data) {
      setCityHook(data.cittaNome);
    }
    console.log("PROV ____________________________");
    console.log(data.cittaNome);
    console.log(data.provincieNome);
    console.log("PROV ____________________________");
  };

  /* const setProvince = async (event) => {
    let index = cityHook.indexOf(event.value);
    let singProv = provinciaHook[index];
    setProvinciaHook([singProv]);
  }; */

  return (
    <div>
      <Field
        key={"companyName"}
        id={"companyName"}
        name={"companyName"}
        label={"Nome azienda"}
        component={FormInput}
        validator={nameValidator}
      />
      <Field
        key={"companyPartitaIva"}
        id={"companyPartitaIva"}
        name={"companyPartitaIva"}
        label={"Partita IVA"}
        component={FormInput}
        validator={partitaIvaValidator}
      />
      <Row>
        <Col xs={12} md={10} lg={10}>
          <Field
            key={"pecemail"}
            id={"pecemail"}
            name={"pecemail"}
            label={"Email PEC"}
            hint={"Inserisci la PEC della Tua azienda"}
            type={"email"}
            component={FormInput}
            optional={true}
          />
        </Col>
        <Col xs={12} md={10} lg={2} style={{ align: "center" }}>
          <Field
            key={"rivenditoreCheck"}
            id={"rivenditoreCheck"}
            name={"rivenditoreCheck"}
            label={"E' un rivenditore?"}
            component={FormCheckbox}
          />
        </Col>
      </Row>
      <Divider />
      <div style={{ align: "left" }}>Sede Azienda</div>
      <Field
        key={"countryselected"}
        id={"countryselected"}
        name={"countryselected"}
        label={"Paese"}
        hint={"Selezione il paese della sede (solo Paesi Europei)"}
        component={FormAutoComplete}
        data={countries}
        onChange={setRegion}
        validator={requiredValidator}
      />
      <Field
        key={"regionselected"}
        id={"regionselected"}
        name={"regionselected"}
        label={"Regione"}
        hint={"Selezione la regione della sede"}
        component={FormAutoComplete}
        data={regionHook}
        onClose={setProvince}
        validator={requiredValidator}
      />
      <Row>
        <Col xs={12} md={10} lg={2}>
          <Field
            key={"provinciaselected"}
            id={"provinciaselected"}
            name={"provinciaselected"}
            label={"Provincia"}
            hint={"Selezione la provincia della sede"}
            component={FormAutoComplete}
            onClose={setCity}
            data={provinciaHook}
            validator={requiredValidator}
          />
        </Col>
        <Col xs={12} md={10} lg={10}>
          <Field
            key={"cityselected"}
            id={"cityselected"}
            name={"cityselected"}
            label={"Città"}
            hint={"Selezione la città della sede"}
            component={FormAutoComplete}
            data={cityHook}
            /* onClose={setProvince} */
            validator={requiredValidator}
          />
        </Col>
      </Row>
      <Field
        key={"companyAddress"}
        id={"companyAddress"}
        name={"companyAddress"}
        label={"Indirizzo sede legale"}
        component={FormInput}
        validator={userNameValidator}
      />
    </div>
  );
}
export default PersonalDetails;
