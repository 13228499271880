import React, {useState, useEffect} from "react";
import ZindoService from "services/zindo-service";
// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {ThemeProvider} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import {Scrollbar} from "react-scrollbars-custom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {useNavigate} from "react-router-dom";

import jwtDecode from "jwt-decode";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

// Image import
import baresina from "../../../assets/images/machineImage/baresina.png";
import Table from "@mui/material/Table";
import {TableCell} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

function ListOfMachines({
                            listMachineCheched,
                            setListMachineCheched,
                            checkUpdate,
                            setCheckUpdate,
                            checked,
                            setChecked,
                        }) {
    /* const [checked, setChecked] = useState([]); */
    var [dataMachineLibere, setDataMachineLibere] = useState([]);
    const [dataMachineLibereTemp, setDataMachineLibereTemp] = useState([]);
    var [dataMachineAssociate, setDataMachineAssociate] = useState([]);
    const [dataMachineAssociateTemp, setDataMachineAssociateTemp] = useState([]);
    var [dataMachineDisabled, setDataMachineDisabled] = useState([]);
    const [dataMachineDisabledTemp, setDataMachineDisabledTemp] = useState([]);

    useEffect(() => {
        getMachineByIdTest();
        if (checkUpdate) {
            console.log("checkUpdate");
            console.log(checkUpdate);
            getMachineByIdTest();
        }
    }, [checkUpdate]);

    let getMachineByIdTest = async () => {
        /* axios.get("http://127.0.0.1:8000/zindoapp/datas/" + id).then((response) => {
          setinitialIndex(response.data.maxDataSlideMotor);
          setstopIndex(response.data.minDataSlideMotor);
          setRowDataById(response.data);
        }); */
        /* let responce = await fetch("http://127.0.0.1:8000/zindoapp/datasGetAll/"); */
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        /* singleDataUserAg["user_id"] = user_id; */
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({userId: user_id}),
        };
        const response = await ZindoService.datasGetAll(requestOptions);
        /* const response = await fetch(
          "http://127.0.0.1:8000/zindoapp/datasGetAll/",
          requestOptions
        ); */
        let data = response;

        setDataMachineLibere(data.listAllDataNonAsso);
        setDataMachineLibereTemp(data.listAllDataNonAsso);
        setDataMachineAssociate(data.listAllDataAsso);
        setDataMachineAssociateTemp(data.listAllDataAsso);
        setDataMachineDisabled(data.listDisabledData);
        setDataMachineDisabledTemp(data.listDisabledData);
        console.log(data);
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        setListMachineCheched(newChecked);
    };

    const CustomWidthTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    const filterDataNonAssociate = (event) => {
        var filterDataMachineLibere = [];
        setDataMachineLibere(dataMachineLibereTemp);
        dataMachineLibere = dataMachineLibereTemp;
        if (event.target.value.length > 0) {
            for (
                let indexNonAss = 0;
                indexNonAss < dataMachineLibere.length;
                indexNonAss++
            ) {
                console.log(event.target.value.length);
                if (
                    dataMachineLibere[indexNonAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value
                    ) ||
                    dataMachineLibere[indexNonAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value.charAt(0).toUpperCase() +
                        event.target.value.slice(1)
                    )
                ) {
                    filterDataMachineLibere.push(dataMachineLibere[indexNonAss]);
                }
            }
        } else {
            filterDataMachineLibere = dataMachineLibereTemp;
        }
        setDataMachineLibere(filterDataMachineLibere);
    };

    const filterDataAssociate = (event) => {
        var filterDataMachineOccupare = [];
        setDataMachineAssociate(dataMachineAssociateTemp);
        dataMachineAssociate = dataMachineAssociateTemp;
        if (event.target.value.length > 0) {
            for (
                let indexAss = 0;
                indexAss < dataMachineAssociate.length;
                indexAss++
            ) {
                console.log(event.target.value.length);
                if (
                    (dataMachineAssociate[indexAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value
                    ) ||
                    dataMachineAssociate[indexAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value.charAt(0).toUpperCase() +
                        event.target.value.slice(1)
                    ))
                    ||
                    (dataMachineAssociate[indexAss].nome_cliente_associato.includes(
                        event.target.value
                    ) ||
                    dataMachineAssociate[indexAss].nome_cliente_associato.includes(
                        event.target.value.charAt(0).toUpperCase() +
                        event.target.value.slice(1)
                    ))
                ) {
                    filterDataMachineOccupare.push(dataMachineAssociate[indexAss]);
                }
            }
        } else {
            filterDataMachineOccupare = dataMachineAssociateTemp;
        }
        setDataMachineAssociate(filterDataMachineOccupare);
    };

    const filterDataDisabled = (event) => {
        var filterDataMachineDisabled = [];
        setDataMachineDisabled(dataMachineDisabledTemp);
        dataMachineDisabled = dataMachineDisabledTemp;
        if (event.target.value.length > 0) {
            for (
                let indexAss = 0;
                indexAss < dataMachineDisabled.length;
                indexAss++
            ) {
                console.log(event.target.value.length);
                if (
                    dataMachineDisabled[indexAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value
                    ) ||
                    dataMachineDisabled[indexAss].NomeUnivocoMacchina_macchina.includes(
                        event.target.value.charAt(0).toUpperCase() +
                        event.target.value.slice(1)
                    )
                ) {
                    filterDataMachineDisabled.push(dataMachineDisabled[indexAss]);
                }
            }
        } else {
            filterDataMachineDisabled = dataMachineDisabledTemp;
        }
        setDataMachineDisabled(filterDataMachineDisabled);
    };

    const navigate = useNavigate();
    const inserisciNuovaMacchina = () => {
        navigate("/gestione_macchine/inseriscimacchina");
    };

    const createNotificationRemoveAss = (type) => {

        console.log(type)
        if (type === "info") {
            NotificationManager.info("Info message");
        } else if (type === "success") {
            NotificationManager.success(
                "Associazione Macchina Rimossa",
                "Rimozione Effettuato",
                5000
            );
        } else if (type === "deleted") {
            NotificationManager.success(
                "Macchina Eliminata",
                "Rimozione Effettuata",
                5000
            );
        } else if (type === "restored") {
            NotificationManager.success(
                "Macchina Ripristinata",
                "",
                5000
            );
        } else if (type === "warning") {
            NotificationManager.warning(
                "Warning message",
                "Close after 3000ms",
                3000
            );
        } else if (type === "error") {
            /* NotificationManager.error("Error message", "Click me!", 5000, () => {
              alert("callback");
            }); */
            NotificationManager.error(
                "Errore Durante la Rimozione",
                "Errore Durante Rimozione",
                5000
            );
        }
    };

    const eliminaMacchina = async (idMacchina) => {
        const requestOptions = JSON.stringify({idMac: idMacchina})
        const response = await ZindoService.eliminamacchina(requestOptions);
        let data = response;
        createNotificationRemoveAss(data[0]["responce"]);
        getMachineByIdTest();
    }

    const restoreMacchina = async (idMacchina) => {
        const requestOptions = JSON.stringify({idMac: idMacchina})
        const response = await ZindoService.restoremacchina(requestOptions);
        let data = response;
        createNotificationRemoveAss(data[0]["responce"]);
        getMachineByIdTest();
    }

    const dissociaMacchina = async (idMacchina) => {
        console.log(idMacchina);
        const requestOptions = JSON.stringify({idMac: idMacchina})
        const response = await ZindoService.dissociamacchina(requestOptions);
        /* const response = await fetch(
          "http://127.0.0.1:8000/zindoapp/removeAssociate/",
          requestOptions
        ); */
        let data = response;
        createNotificationRemoveAss(data[0]["responce"]);
        getMachineByIdTest();
    };

    const editMachine = (machine_id) => {
        navigate("/gestione_macchine/editmachine", {state: machine_id});
    };

    return (
        <div>
            <MDBox py={3} pl={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <MDBox flex>
                        <Card>
                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                pt={1}
                                px={2}
                            >
                                <MDBox
                                    variant="gradient"
                                    bgColor="success"
                                    color={"dark" === "light" ? "dark" : "white"}
                                    coloredShadow="dark"
                                    borderRadius="xl"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="4rem"
                                    height="4rem"
                                    mt={-3}
                                >
                                    <Icon fontSize="medium" color="inherit">
                                        precision_manufacturing_icon
                                    </Icon>
                                </MDBox>
                                <MDBox textAlign="right" lineHeight={1.25}>
                                    <Row flex>
                                        <Col
                                            xs={12}
                                            md={2}
                                            lg={4}
                                            style={{margin: 0, padding: 0}}
                                            flex
                                        >
                                            <ThemeProvider>
                                                <TextField
                                                    label="Filtra per Nome"
                                                    size="small"
                                                    onChange={filterDataNonAssociate}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                        <Col xs={12} md={10} lg={8} flex>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="light"
                                                color="text"
                                            >
                                                MACCHINE NON ASSOCIATE
                                            </MDTypography>
                                        </Col>
                                    </Row>
                                </MDBox>
                                <div onClick={inserisciNuovaMacchina}>
                                    <Tooltip title="Inserisci nuova Macchina" placement="top">
                                        <MDBox
                                            variant="gradient"
                                            bgColor="light"
                                            color={"dark" === "light" ? "dark" : "white"}
                                            coloredShadow="dark"
                                            borderRadius="xl"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="2rem"
                                            height="2rem"
                                            // mt={-3}
                                        >
                                            <Icon fontSize="" color="inherit">
                                                add
                                            </Icon>
                                        </MDBox>
                                    </Tooltip>
                                </div>
                            </MDBox>
                            <Divider/>
                            <Scrollbar
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    height: "270px",
                                    maxHeight: "60%",
                                }}
                            >
                                <MDBox pb={2} px={2}>
                                    <List dense>
                                        {dataMachineLibere.map((singola_macchina) => {
                                            const labelId = `checkbox-list-secondary-label-${singola_macchina.id_macchina}`;
                                            var image = "";
                                            if (singola_macchina.tipologia_macchina === "baresina") {
                                                image = baresina;
                                            }
                                            return (
                                                <ListItem
                                                    key={singola_macchina.id_macchina}
                                                    secondaryAction={
                                                        <div>
                                                            <Tooltip title="Modifica Macchina">
                                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                    editMachine(singola_macchina.id_macchina)
                                                                }}>
                                                                    <EditNoteOutlinedIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Elimina Macchina" onClick={() => {
                                                                eliminaMacchina(singola_macchina.id_macchina)
                                                            }}>
                                                                <IconButton edge="end" aria-label="delete">
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={handleToggle(
                                                                    singola_macchina.id_macchina
                                                                )}
                                                                checked={
                                                                    checked.indexOf(
                                                                        singola_macchina.id_macchina
                                                                    ) !== -1
                                                                }
                                                                inputProps={{"aria-labelledby": labelId}}
                                                            />
                                                        </div>
                                                    }
                                                    disablePadding
                                                >
                                                    <CustomWidthTooltip
                                                        placement="top"
                                                        title={
                                                            <>
                                                                Tipologia Macchina ={" "}
                                                                {singola_macchina.tipologia_macchina}
                                                                <br/>
                                                                Codice LteMac ={" "}
                                                                {singola_macchina.lteMacMacchina_macchina}
                                                            </>
                                                        }
                                                    >
                                                        <ListItemButton>
                                                            <ListItemAvatar>
                                                                <Avatar
                                                                    alt={`Nome Macchina: ${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                                    src={image}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={`Nome macchina: ${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                            />
                                                        </ListItemButton>
                                                    </CustomWidthTooltip>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </MDBox>
                            </Scrollbar>
                        </Card>
                    </MDBox>
                </Grid>
            </MDBox>
            <MDBox py={3} pl={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <MDBox flex>
                        <Card>
                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                pt={1}
                                px={2}
                            >
                                <MDBox
                                    variant="gradient"
                                    bgColor="primary"
                                    color={"dark" === "light" ? "dark" : "white"}
                                    coloredShadow="dark"
                                    borderRadius="xl"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="4rem"
                                    height="4rem"
                                    mt={-3}
                                >
                                    <Icon fontSize="medium" color="inherit">
                                        precision_manufacturing_icon
                                    </Icon>
                                </MDBox>
                                <MDBox textAlign="right" lineHeight={1.25}>
                                    <Row flex>
                                        <Col
                                            xs={12}
                                            md={2}
                                            lg={4}
                                            style={{margin: 0, padding: 0}}
                                            flex
                                        >
                                            <ThemeProvider>
                                                <TextField
                                                    label="Filtra"
                                                    size="small"
                                                    onChange={filterDataAssociate}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                        <Col xs={12} md={10} lg={8} flex>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="light"
                                                color="text"
                                            >
                                                MACCHINE ASSOCIATE
                                            </MDTypography>
                                        </Col>
                                    </Row>
                                </MDBox>
                            </MDBox>
                            <Divider/>
                            <Scrollbar
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    height: "170px",
                                    maxHeight: "40%",
                                }}
                            >
                                <MDBox pb={2} px={2}>
                                    <Table>
                                        <TableBody>
                                            {dataMachineAssociate.map((singola_macchina) => {
                                                const labelId = `checkbox-list-secondary-label-${singola_macchina.id_macchina}`;
                                                var image = "";
                                                if (singola_macchina.tipologia_macchina === "baresina") {
                                                    image = baresina;
                                                }
                                                return (
                                                    <TableRow
                                                        key={labelId}
                                                    >
                                                        <TableCell style={{"padding": 0}}>
                                                            <CustomWidthTooltip
                                                                placement="top"
                                                                title={
                                                                    <>
                                                                        Tipologia Macchina ={" "}
                                                                        {singola_macchina.tipologia_macchina}
                                                                        <br/>
                                                                        Codice LteMac ={" "}
                                                                        {singola_macchina.lteMacMacchina_macchina}
                                                                    </>
                                                                }
                                                            >
                                                                <ListItemButton>
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            alt={`${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                                            src={image}
                                                                        />
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primaryTypographyProps={{fontSize: '16px', fontWeight: "300"}}
                                                                        id={labelId}
                                                                        primary={`${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                                    />
                                                                </ListItemButton>
                                                            </CustomWidthTooltip>
                                                        </TableCell>
                                                        <TableCell>
                                                            <ListItemText
                                                                primaryTypographyProps={{fontSize: '16px', fontWeight: "300"}}
                                                                id={labelId}
                                                                primary={`${singola_macchina.nome_cliente_associato}`}
                                                            />
                                                        </TableCell>
                                                        <TableCell align={"right"}>
                                                            <Tooltip title="Modifica Macchina">
                                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                    editMachine(singola_macchina.id_macchina)
                                                                }}>
                                                                    <EditNoteOutlinedIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Dissocia Macchina">
                                                                <IconButton edge="end" aria-label="delete">
                                                                    <DeleteIcon
                                                                        onClick={() =>
                                                                            dissociaMacchina(
                                                                                singola_macchina.id_macchina
                                                                            )
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </MDBox>
                            </Scrollbar>
                        </Card>
                    </MDBox>
                </Grid>
            </MDBox>
            <MDBox py={3} pl={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <MDBox flex>
                        <Card>
                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                pt={1}
                                px={2}
                            >
                                <MDBox
                                    variant="gradient"
                                    bgColor="warning"
                                    color={"dark" === "light" ? "dark" : "white"}
                                    coloredShadow="dark"
                                    borderRadius="xl"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="4rem"
                                    height="4rem"
                                    mt={-3}
                                >
                                    <Icon fontSize="medium" color="inherit">
                                        precision_manufacturing_icon
                                    </Icon>
                                </MDBox>
                                <MDBox textAlign="right" lineHeight={1.25}>
                                    <Row flex>
                                        <Col
                                            xs={12}
                                            md={2}
                                            lg={4}
                                            style={{margin: 0, padding: 0}}
                                            flex
                                        >
                                            <ThemeProvider>
                                                <TextField
                                                    label="Filtra per nome"
                                                    size="small"
                                                    onChange={filterDataDisabled}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                        <Col xs={12} md={10} lg={8} flex>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="light"
                                                color="text"
                                            >
                                                MACCHINE DISATTIVATE
                                            </MDTypography>
                                        </Col>
                                    </Row>
                                </MDBox>
                            </MDBox>
                            <Divider/>
                            <Scrollbar
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    height: "170px",
                                    maxHeight: "40%",
                                }}
                            >
                                <MDBox pb={2} px={2}>
                                    <List dense>
                                        {dataMachineDisabled.map((singola_macchina) => {
                                            const labelId = `checkbox-list-secondary-label-${singola_macchina.id_macchina}`;
                                            var image = "";
                                            if (singola_macchina.tipologia_macchina === "baresina") {
                                                image = baresina;
                                            }
                                            return (
                                                <div>
                                                    <ListItem
                                                        key={singola_macchina.id_macchina + "disabled"}
                                                        secondaryAction={
                                                            <div>
                                                                <Tooltip title="Ripristina Macchina">
                                                                    <IconButton edge="end" aria-label="delete">
                                                                        <RestoreFromTrashIcon
                                                                            onClick={() =>
                                                                                restoreMacchina(singola_macchina.id_macchina)
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                        disablePadding
                                                    >
                                                        <CustomWidthTooltip
                                                            placement="top"
                                                            /* title={`Tipologia Macchina = ${singola_macchina.tipologia_macchina} <br /> Codice LteMac= ${singola_macchina.lteMacMacchina_macchina}`} */
                                                            title={
                                                                <>
                                                                    Tipologia Macchina ={" "}
                                                                    {singola_macchina.tipologia_macchina}
                                                                    <br/>
                                                                    Codice LteMac ={" "}
                                                                    {singola_macchina.lteMacMacchina_macchina}
                                                                </>
                                                            }
                                                        >
                                                            <ListItemButton>
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        alt={`Nome Macchina: ${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                                        src={image}
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    id={labelId}
                                                                    primary={`Nome macchina: ${singola_macchina.NomeUnivocoMacchina_macchina}`}
                                                                />
                                                            </ListItemButton>
                                                        </CustomWidthTooltip>
                                                    </ListItem>
                                                </div>
                                            );
                                        })}
                                    </List>
                                </MDBox>
                            </Scrollbar>
                        </Card>
                    </MDBox>
                </Grid>
            </MDBox>
            <NotificationContainer/>
        </div>
    );
}

export default ListOfMachines;
