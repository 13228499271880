import React, { Component } from "react";
import DashboardUser from "./components/DashboardUser";
import TipologiaMacchineTemplate from "zindoLayouts/TipologiaMacchina/components/tipologiaTemplate";

export default class DashboardProfileComponents extends Component {
    render() {
        return (
            <TipologiaMacchineTemplate
                title="Profilo"
                component={<DashboardUser />}
            />

        );
    }
}

// #End region
