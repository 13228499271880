

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ZindoService from "services/zindo-service";
import TipologiaMacchineTemplate from "../components/tipologiaTemplate";
import { useNavigate,useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';




import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { Scrollbar } from 'smooth-scrollbar-react';

import StdRecipesForm from "./StdForm";

import TablePagination from '@mui/material/TablePagination';

import Button from '@mui/material/Button';

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText  } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Popover from '@mui/material/Popover';
import zindoService from 'services/zindo-service';

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DeleteButton (props){
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const disabled_label = "Ci sono macchine associate";
    const label = "elimina";

    return (
        <>
        <Tooltip title={props.disable ? disabled_label : label} TransitionComponent={Zoom} placement="top">
            <span>
                <IconButton color="error" aria-label="add an alarm" onClick={handleClick}  disabled={props.disable} /*  onClick={ () => updateTipologia(rowsPerPage)} */ >
                    <BsTrash3  size={20} className='p-0'/>
            </IconButton>
            </span>                        
        </Tooltip>
        
            <Popover
 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                      
            >
                <Typography sx={{ p: 2 }} variant='h6'>Sei Sicuro di voler eliminare questa tipologia?</Typography> 
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 16px 5px 16px' }}>
                    <MDButton  variant="text" color="info" onClick={handleClose} size='small'>indietro</MDButton>
                    <MDButton  color={'error'} size='small' onClick={() => props.deleteAction(props.value)}>Elimina</MDButton>
                </div>     
            </Popover>
    
        
    </>
    )
}


function Row(props) {
  const { row,
    update,
    remove,
    } = props;

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nomericetta}
        </TableCell>
        {row.parametri.map(param =>(
            <TableCell align='center'>{param.valore}</TableCell>
        ))}
        
        <TableCell align='right'>
            
            <Tooltip title="Modifica" TransitionComponent={Zoom} placement="top">
                    <IconButton color="info" aria-label="add an alarm"  onClick={ () => update(row)}>
                        <BsPen  size={20} className='p-0'/>
                    </IconButton>
            </Tooltip>

            <DeleteButton deleteAction = {remove} value = {row.pk}/>
            
            {/* {row.numero_macchine_associate
                ?<DeleteButton disable = {true} deleteAction = {deleteAction} value = {row.pk}/>
                :<DeleteButton disable = {false} deleteAction = {deleteAction} value = {row.pk}/>
            } */}
        
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} sx={{ backgroundColor: '#f8f9fa' }}>
          <Collapse in={open} timeout="auto" unmountOnExit >
            <Box sx={{ margin: 1}}>
              <Typography variant="h6" gutterBottom component="div" >
               Descrizione
              </Typography>
              <Typography variant="body2" gutterBottom component="div"  align="justify" >
              <Scrollbar
                                                        className="scrollable"
                                                        alwaysShowTracks={true}

                                                        style={{ maxHeight: "300px", paddingRight: "25px"}}
                                                        plugins={{
                                                            overscroll: {
                                                                effect: "bounce"
                                                            }
                                                        }}

                                                    ><div className='pl-5'>
                                                         {row.info}
                                                    </div>
                                                       
                                                    </Scrollbar>
              </Typography>

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


function StdRecTable(props) {

    const {
        columns,
        data,
        create,
        update,
        remove,
        toggleShow,
        toggleShowEdit,
    } = props

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
          <TableCell>Info</TableCell>
          <TableCell>Ricetta</TableCell>
            {columns.map(column => (
                <TableCell  align='center'>{column.label_frontend}</TableCell>
            ))}
            <TableCell component='td' align='right'>
                <Button variant="text" color='primary' style={{paddingRight:17}} onClick={toggleShow} id={"addTypeID"}><BsPlus size={30} align={'right'}/>AGGIUNGI</Button>
            </TableCell>           
          </TableRow>
        </TableHead>
        <TableBody>
     
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={row.pk} row={row} update ={toggleShowEdit} remove={remove}/>
          ))}
        </TableBody>
      </Table>
     
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="righe per pagina"  
              SelectProps={{
                select:{
                  marginbottom:'10px'
                }
               
              }}
            
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />

    </TableContainer>
  );
}



function StdRecipes(props){
    
    const {tipologiaID } = props;
    const [recipes, setRecipes] = useState([]);
    const [columns, setColumns] = useState([]);

    const notificationAction = (type) => {

      console.log(type)
      switch(type){
        case 'create':
          NotificationManager.success(
            "Ricetta creata con successo",
            "",
            4000
          );
          break;

        case 'edit':
          NotificationManager.success(
            "Ricetta modificata con successo",
            "",
            4000
          );
          break;
        
        case 'delete':
          NotificationManager.success(
            "Ricetta Eliminata con successo",
            "",
            4000
          );
          break;
        default:
          break;

      }

  }

    const createRecipe = async (recipe) =>{
      
      const response = await ZindoService.standardRecipePOST(JSON.stringify({
        typeID: tipologiaID,
        recipe: recipe
      }))

        console.log("create recipe");
        const data = response.data;
        console.log(data);
        
        let totale = [...recipes];
        totale.push(data);
        setRecipes(totale);

        notificationAction('create');
    }

    const updateRecipe = async (recipe) =>{
      
      
      const response = await ZindoService.standardRecipePUT(JSON.stringify({
        typeID: tipologiaID,
        recipe: recipe
      }))

        const data = response.data;

        console.log("update recipe");
        console.log(data)
       
        /* let tot = recipes.map(rec => rec.pk === data.pk ? data : rec);
        console.log(tot) */
        setRecipes(recipes.map(rec => rec.pk === data.pk ? data : rec));
        notificationAction('edit');
    }

    const deleteRecipe = async (recipeID) =>{
      const data = await ZindoService.standardRecipeDELETE(JSON.stringify({
        recipeID: recipeID
      }))

        console.log("delete recipe");
        console.log(data)
        setRecipes(recipes.filter(recipe => recipe.pk !== recipeID));
        notificationAction('delete');
    }


    const [staticModal, setStaticModal] = useState(false);
    const toggleShow = () => setStaticModal(!staticModal);

    const [staticModalEdit, setStaticModalEdit] = useState(false);
    const toggleShowEdit = () => setStaticModalEdit(!staticModalEdit);

    const [editData, setEditData] = useState(null)


    const showEditModal = (recipe) => {

        setStaticModalEdit(!staticModalEdit);
        setEditData({ 'mach': tipologiaID, 'recipe': recipe });
    }


    useEffect(()=>{
        const getRecipeStandard = async () =>{

            const data = await ZindoService.standardRecipeGET(tipologiaID);

            const param = await ZindoService.getParamsType(tipologiaID);

            console.log("params");
            console.log(param.filter(elem => elem.in_ricetta === true));
  
            /* data[0].parametri.map(param =>(
                columns_.push(param.idparametro)
            ))
 */
            setColumns(param.filter(elem => elem.in_ricetta === true));
            console.log(data);
            setRecipes(data.data);
        }

        getRecipeStandard();
       
    },[])


    return (
        <>
            <MDBox pb={2} pt={1} px={2}>
                <Grid container spacing={0} >
                    <Grid item xs={12} md={12} xl={12} my={3} >
                        <div id="id_standard"
                        
                        >
                            <StdRecTable 
                                columns ={columns} 
                                data={recipes}
                                create={createRecipe} 
                                update={updateRecipe} 
                                remove={deleteRecipe} 
                                toggleShow={toggleShow}
                                toggleShowEdit={showEditModal}
                            />
                        </div>
                    </Grid> 
                </Grid>
            </MDBox>

            <StdRecipesForm params={columns}
                toggleShow={toggleShow}
                modalHook={{ staticModal: staticModal, setStaticModal: setStaticModal }}
                recipesHook={{ recipes: recipes, setRecipes: setRecipes }}
                action = {createRecipe}
                modality={'create'}
            />
            <StdRecipesForm params={columns}
                toggleShow={toggleShowEdit}
                modalHook={{ staticModal: staticModalEdit, setStaticModal: setStaticModalEdit }}
                recipesHook={{ recipes: recipes, setRecipes: setRecipes }}
                nameRecipeEdit={editData}
                action = {updateRecipe}
                modality={'edit'}
            />

            <NotificationContainer/>

            
            
        </>
    );


}

export default function DashboardStdRecipes() {

        const location = useLocation();

        const title = "Ricette Standard "+location.state.typeName;

        return (
            <TipologiaMacchineTemplate
                title={title}
                component={<StdRecipes tipologiaID={location.state.tipologiaID}/>}
            />
        );
    
}


