import Dashboard from "./zindoLayouts/dashboard";
import Machines from "./zindoLayouts/Machines";
import GestioneMacchine from "./zindoLayouts/gestionemacchine";
import TypeMachine from "./zindoLayouts/TipologiaMacchina";
import TipologiaMacchineComponent from "./zindoLayouts/TipologiaMacchina";
import DashboardParametriComponent from "zindoLayouts/parametri";
import Recipes from "zindoLayouts/Recipes";

import Billing from "./layouts/billing";
import Notifications from "./layouts/notifications";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";

import UserProfile from "./layouts/user-profile";
import UserManagement from "./layouts/user-management";

import Login from "./auth/login";
import Register from "./auth/register";
import ForgotPassword from "./auth/forgot-password";
import ResetPassword from "./auth/reset-password";

import { CreateTipologia } from "zindoLayouts/TipologiaMacchina/components/dashboardinsertVar";
import { UpdateTipologia } from "zindoLayouts/TipologiaMacchina/components/dashboardUpdateType";
import DashBoardMachine from "layouts/dashboardmachine";
import InserisciNuovoCliente from "zindoLayouts/nuovoCliente";
import EditCliente from "zindoLayouts/editCliente";
import InserisciNuovaMacchina from "zindoLayouts/nuovaMacchina";
import EditMachine from "zindoLayouts/editMachine";
import DashboardStdRecipes from "zindoLayouts/TipologiaMacchina/RicetteStandard/Ricette Standard";
import DashboardProfileComponents from "zindoLayouts/profiloUtente";
import { enumSection as section } from "zindoLayouts/utility/enumerators";
/* import GestioneAccounts from "zindoLayouts/gestioneAccounts"; */ //lASCIARE COMMENTATO

//import RequireAuth from "auth/RequireAuth";

// @mui icons
import Icon from "@mui/material/Icon";
import {
  AiOutlineHome,

} from "react-icons/ai";
import { BsTruckFrontFill } from "react-icons/bs";

const routes = [
  {
    type: "collapse",
    category: section.HOME,
    name: "Home",
    key: "dashboard",
    icon: <AiOutlineHome />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  //GESTIONE MACCHINE
  {
    type: "collapse",
    category: section.GESTIONE_MACCHINE,
    name: "Gestione Macchine",
    key: "gestione_macchine",
    icon: <Icon fontSize="small">settings_suggest_icon</Icon>,
    route: "/gestione_macchine",
    component: <GestioneMacchine />,
  },
  // MACCHINE
  {
    type: "collapse",
    category: section.MACCHINE,
    name: "Macchine",
    key: "macchine",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/macchine",
    component: <Machines />,
  },

  // RICETTE
  {
    type: "collapse",
    category: section.RICETTE,
    name: "Ricette",
    key: "ricette",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/ricette",
    component: <Recipes />,
  },
  //TIPOLOGIA MACCHINA
  {
    type: "collapse",
    category: section.TIPOLOGIA,
    name: "Tipologia macchina",
    key: "tipologia-macchina",
    icon: <BsTruckFrontFill />,
    route: "/tipologia-macchina",
    component: <TipologiaMacchineComponent />,
  },
  {
    type: "collapse",
    category: section.PARAMETRI,
    name: "Parametri",
    key: "parametri",
    icon: <BsTruckFrontFill />,
    route: "/parametri",
    component: <DashboardParametriComponent />,
  },
  /*   {
      type: "collapse",
      category: section.PARAMETRI,
      name: "Gestione Accounts",
      key: "accounts",
      icon: <BsTruckFrontFill />,
      route: "/accounts",
      component: <GestioneAccounts />,
    }, */
  {
    name: "crea tipologia",
    category: section.TIPOLOGIA,
    key: "creaTipologia",
    route: "/tipologia-macchina/crea",
    component: <CreateTipologia />
  },
  {
    name: "Modifica tipologia",
    category: section.TIPOLOGIA,
    key: "modificaTipologia",
    route: "/tipologia-macchina/modifica",
    component: <UpdateTipologia />
  },
  {
    name: "Ricette standard",
    category: section.TIPOLOGIA,
    key: "ricetteStandard",
    route: "/tipologia-macchina/ricette-standard",
    component: <DashboardStdRecipes />
  },
  {
    name: "macchine",
    category: section.MACCHINE,
    key: "macchine",
    route: "/macchine/:idMachine",
    component: <DashBoardMachine />
  },
  {
    name: "crea cliente",
    category: section.GESTIONE_MACCHINE,
    key: "creaCliente",
    route: "/gestione_macchine/inseriscicliente",
    component: <InserisciNuovoCliente />
  },
  {
    name: "modifica cliente",
    category: section.GESTIONE_MACCHINE,
    key: "modificaCliente",
    route: "/gestione_macchine/editcliente",
    component: <EditCliente />
  },
  {
    name: "crea macchina",
    category: section.GESTIONE_MACCHINE,
    key: "creaMacchina",
    route: "/gestione_macchine/inseriscimacchina",
    component: <InserisciNuovaMacchina />
  },
  {
    name: "modifica macchina",
    category: section.GESTIONE_MACCHINE,
    key: "modificaMacchina",
    route: "/gestione_macchine/editmachine",
    component: <EditMachine />
  },
  {
    name: "profile",
    category: section.PROFILO,
    key: "profile",
    route: "/profilo",
    component: <DashboardProfileComponents />
  },





  /* {
    type: "collapse",
    name: "Clienti",
    key: "client",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/clienti",
    component: <SignIn />,
  }, */
  /* {
    type: "examples",
    name: "User Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
  }, */
  /* {
    type: "examples",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  }, */
  /* {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  }, */
  /*   {
      type: "logout",
      name: "Logout",
      key: "logout",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/authentication/sign-up",
      component: <SignUp />,
    }, */

  /* {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">reigster</Icon>,
    route: "/auth/register",
    component: <Register />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
  }, */
];

export default routes;
