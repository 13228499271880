import React, {useState, useEffect} from "react";
import ZindoService from "services/zindo-service";
// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {ThemeProvider} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import {Scrollbar} from "react-scrollbars-custom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {useNavigate} from "react-router-dom";

import jwtDecode from "jwt-decode";

function ListOfClients({listClientiCheched, setListClientiCheched}) {
    const [checked, setChecked] = useState([]);
    var [dataAllClients, setDataAllClients] = useState([]);
    const [dataAllClientsTemp, setDataAllClientsTemp] = useState([]);

    useEffect(() => {
        getClientsWithId();
    }, []);

    let getClientsWithId = async () => {
        /* axios.get("http://127.0.0.1:8000/zindoapp/datas/" + id).then((response) => {
          setinitialIndex(response.data.maxDataSlideMotor);
          setstopIndex(response.data.minDataSlideMotor);
          setRowDataById(response.data);
        }); */
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        let responce = await ZindoService.datasGetAllClients(user_id.toString());
        /* let responce = await fetch(
          "http://127.0.0.1:8000/zindoapp/datasGetAllClients/" +
            user_id.toString() +
            "/"
        ); */

        /* if (Array.isArray(responce)) { */
        let data = responce;
        setDataAllClients(data);
        setDataAllClientsTemp(data);
        /* } */
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        var newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked = [value];
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setListClientiCheched(newChecked);
    };

    const CustomWidthTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    const filterAllClients = (event) => {
        var filterAllClientsList = [];
        setDataAllClients(dataAllClientsTemp);
        dataAllClients = dataAllClientsTemp;
        if (event.target.value.length > 0) {
            for (
                let indexNonAss = 0;
                indexNonAss < dataAllClients.length;
                indexNonAss++
            ) {
                if (
                    dataAllClients[indexNonAss].nome_cliente.includes(
                        event.target.value
                    ) ||
                    dataAllClients[indexNonAss].nome_cliente.includes(
                        event.target.value.charAt(0).toUpperCase() +
                        event.target.value.slice(1)
                    )
                ) {
                    filterAllClientsList.push(dataAllClients[indexNonAss]);
                }
            }
        } else {
            filterAllClientsList = dataAllClientsTemp;
        }
        setDataAllClients(filterAllClientsList);
    };

    const navigate = useNavigate();
    const inserisciNuovoCliente = () => {
        navigate("/gestione_macchine/inseriscicliente");
    };

    const editCliente = (cliente_id) => {
        navigate("/gestione_macchine/editcliente", {state: cliente_id});
    };

    const deleteCliente = async (cliente_id) => {
        var refresh_token = localStorage.getItem("access_token");
        let refresh_tokenParts = jwtDecode(refresh_token);
        var user_id = refresh_tokenParts.user_id;
        let response = await ZindoService.deleteClientAndSede(cliente_id);
        console.log(response);
        getClientsWithId();
    }

    /* localStorage.getItem('access_token');
    let refresh_tokenParts = jwtDecode(refresh_token) */

    return (
        <div>
            <MDBox py={3} pr={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <MDBox flex>
                        <Card>
                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                pt={1}
                                px={2}
                            >
                                <MDBox
                                    variant="gradient"
                                    bgColor="info"
                                    color={"dark" === "light" ? "dark" : "white"}
                                    coloredShadow="dark"
                                    borderRadius="xl"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="4rem"
                                    height="4rem"
                                    mt={-3}
                                >
                                    <Icon fontSize="medium" color="inherit">
                                        person
                                    </Icon>
                                </MDBox>
                                <MDBox textAlign="right" lineHeight={1.25}>
                                    <Row flex>
                                        <Col
                                            xs={12}
                                            md={2}
                                            lg={4}
                                            style={{margin: 0, padding: 0}}
                                            flex
                                        >
                                            <ThemeProvider>
                                                <TextField
                                                    label="Filtra per Nome"
                                                    size="small"
                                                    onChange={filterAllClients}
                                                />
                                            </ThemeProvider>
                                        </Col>
                                        <Col xs={12} md={10} lg={8} flex>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="light"
                                                color="text"
                                            >
                                                CLIENTI
                                            </MDTypography>
                                        </Col>
                                    </Row>
                                </MDBox>
                                <div onClick={inserisciNuovoCliente}>
                                    <Tooltip title="Inserisci nuovo Cliente" placement="top">
                                        <MDBox
                                            variant="gradient"
                                            bgColor="light"
                                            color={"dark" === "light" ? "dark" : "white"}
                                            coloredShadow="dark"
                                            borderRadius="xl"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="2rem"
                                            height="2rem"
                                            mt={-3}
                                        >
                                            <Icon fontSize="medium" color="inherit">
                                                add
                                            </Icon>
                                        </MDBox>
                                    </Tooltip>
                                </div>
                            </MDBox>
                            <Divider/>
                            <Scrollbar
                                style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    height: "570px",
                                    maxHeight: "60%",
                                }}
                            >
                                <MDBox pb={2} px={2}>
                                    <List dense>
                                        {dataAllClients.map((singolo_cliente) => {
                                            const labelId = `checkbox-list-secondary-label-${singolo_cliente.id_cliente}`;
                                            return (
                                                <ListItem
                                                    key={singolo_cliente.id_cliente}
                                                    secondaryAction={
                                                        <div>
                                                            <Tooltip title="Modifica Cliente">
                                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                                    editCliente(singolo_cliente.id_cliente)
                                                                }}>
                                                                    <EditNoteOutlinedIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Elimina Cliente">
                                                                <IconButton edge="end" aria-label="delete" disabled={!singolo_cliente.is_deletable} onClick={() => {deleteCliente(singolo_cliente.id_cliente)}}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={handleToggle(
                                                                    singolo_cliente.id_cliente
                                                                )}
                                                                checked={
                                                                    checked.indexOf(
                                                                        singolo_cliente.id_cliente
                                                                    ) !== -1
                                                                }
                                                                inputProps={{"aria-labelledby": labelId}}
                                                            />
                                                        </div>
                                                    }
                                                    disablePadding
                                                >
                                                    <CustomWidthTooltip
                                                        placement="top"
                                                        title={
                                                            <>
                                                                Tipologia Macchina ={" "}
                                                                {singolo_cliente.nome_cliente}
                                                            </>
                                                        }
                                                    >
                                                        <ListItemButton>
                                                            <ListItemAvatar>
                                                                <Avatar
                                                                    alt={`Nome Macchina: ${singolo_cliente.nome_cliente}`}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={`Nome cliente: ${singolo_cliente.nome_cliente}`}
                                                            />
                                                        </ListItemButton>
                                                    </CustomWidthTooltip>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </MDBox>
                            </Scrollbar>
                        </Card>
                    </MDBox>
                </Grid>
            </MDBox>
        </div>
    );
}

export default ListOfClients;
