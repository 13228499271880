import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar-react";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { Component } from "react";

import { styled } from "@mui/material/styles";

import { BarChart, Bar, XAxis, ResponsiveContainer } from "recharts";
import { Tooltip as TP } from "recharts";

// Images
import MDButton from "components/MDButton";

import { BsPlusCircleFill } from "react-icons/bs";
import { BsCheck2Circle } from "react-icons/bs";
import { BsXCircle, BsPen } from "react-icons/bs";
import { BsX, BsPlusCircleDotted, BsPlus } from "react-icons/bs";
import { BsTrash3, BsFileText } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Popover from "@mui/material/Popover";
import zindoService from "services/zindo-service";
import { useState } from "react";

import { MDBCheckbox, MDBSelect } from "mdb-react-ui-kit";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "../styles/machineTable.css";

import {
  enumStructureKey,
  regexStructureKey,
} from "zindoLayouts/utility/enumerators";

const structureKey = Object.values(enumStructureKey);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
      </div>
    );
  }
  return null;
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.webSocketSniffer(props.value);

    const buttonGlowStyleYellow = {
      font: "10 Raleway, sans-serif",
      padding: "5px",
      border: "solid 2px",
      cursor: "pointer",
      position: "relative",
      transition: "0.25s ease-out",
      boxShadow: "0 0 15px #dee610",
    };
    this.state = buttonGlowStyleYellow;
  }
  webSocketSniffer(id) {
    let url = `${process.env.REACT_APP_SOCKET_ENDPOINT}ws/mqtt/${id}/`;
    console.log(id);
    const machineSocket = new WebSocket(url);
    machineSocket.onopen = (e) => {
      console.log("connected");
      this.setState({ boxShadow: "0 0 15px #2ae319", borderColor: "#2ae319" });
    };
    machineSocket.onclose = (e) => {
      console.log("disconnected");
      this.setState({ boxShadow: "0 0 15px #f20f22", borderColor: "#f20f22" });

      setTimeout(() => {
        this.machineSocket = new WebSocket(url);
        console.log(url);
      }, 1000);
    };
    machineSocket.onerror = (err) => {
      console.log("Socket encountered error: ", err.message, "Closing socket");
      this.setState({ boxShadow: "0 0 15px #f20f22", borderColor: "#f20f22" });
      machineSocket.close();
    };
    machineSocket.onmessage = function (e) {
      let data = JSON.parse(e.data);
      /* if (data.hasOwnProperty("value")) {
        console.log("Data ActiveRecipe:", data.value.value.Data.ActiveRecipe);
      } else {
        console.log("Data:", data);
      } */
    };
  }

  render() {
    return (
      <NavLink
        to={`/macchine/${this.props.value}`}
        state={{ id: this.props.value, permission: this.props.dashboard }}
      >
        <MDButton className="buttonGlow" style={this.state} m={5}>
          Dashboard
        </MDButton>
      </NavLink>
    );
  }
}

function DeleteButton(props) {
  const { deleteAction, parametro } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [params, setParams] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (parametro.is_structure) {
      let parametri = [];

      let param = parametro.nome.replace(regexStructureKey, "");

      structureKey.map((key) => {
        parametri.push(param + key);
      });

      setParams(parametri);
    }
  }, [parametro]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const disabled_label = "Ci sono macchine associate";
  const label = "elimina";

  return (
    <>
      <Tooltip title={label} TransitionComponent={Zoom} placement="top">
        <span>
          <IconButton color="error" onClick={handleClick}>
            <BsTrash3 size={20} className="p-0" />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }} variant="h6">
          Sei Sicuro di voler eliminare questa tipologia?
        </Typography>
        <Typography sx={{ p: 2 }} variant="body2">
          {params.length ? (
            <>
              Questa azione comporta l'eliminazione di:
              {params.map((p, index) => (
                <li key={index}>{p}</li>
              ))}
            </>
          ) : (
            ""
          )}
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 16px 5px 16px",
          }}
        >
          <MDButton
            variant="text"
            color="info"
            onClick={handleClose}
            size="small"
          >
            indietro
          </MDButton>
          <MDButton
            color={"error"}
            size="small"
            onClick={() => deleteAction(parametro)}
          >
            Elimina
          </MDButton>
        </div>
      </Popover>
    </>
  );
}

function Row(props) {
  const {
    row,
    defaultValue,
    parametri,
    updateAction,
    deleteAction,
    submit,
    cancel,
    chart,
  } = props;

  const trueElem = <BsCheck2Circle color="green" size={25} />;
  const falseElem = <BsXCircle color="red" size={20} />;

  const [check, setCheck] = useState(true);

  /*   const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [lastInsert, setLastInsert] = useState({});


  useEffect(()=>{
    var lastInsert_= {}
    if(row.modality==='edit') {
        lastInsert_ ={
            paramID: row.paramID,
            nome: row.nome,
            in_ricetta: row.in_ricetta,
            realtime: row.realtime,
            controllo: row.controllo,
            modality: 'view'
        }
    }else{
        lastInsert_ ={
            paramID: defaultValue.value,
            nome: defaultValue.label,
            in_ricetta: false,
            realtime: false,
            controllo: false,
            modality: 'view'
        }
    }
    
    setLastInsert(lastInsert_);

  },[row])

  
  const changeHandlerParam = (choice) => setLastInsert({ ...lastInsert, 'paramID': choice.value, 'nome': choice.label });

  const changeHandler = (e) =>{
    setLastInsert({ ...lastInsert, [e.target.name]: e.target.checked });
    let controllo = e.target.name ==='controllo'? e.target.checked : lastInsert.controllo;
    let ricetta = e.target.name ==='in_ricetta'? e.target.checked : lastInsert.in_ricetta;
    let realtime = e.target.name ==='realtime'? e.target.checked : lastInsert.realtime;

    setCheck(controllo || ricetta || realtime);
    
  } 

  const submitRow =(modality, data) =>{
    const c = lastInsert.controllo || lastInsert.in_ricetta || lastInsert.realtime;
    if (c){
        submit(modality, data);
    }else{
        setCheck(c);
    }
  } */
  /* descrizione : "Schermata attiva sul display utente"
    is_structure : false
    label_frontend : ""
    nome : "ActiveScreen"
    pk : 1
    tipologia : "Intero"
    unita_di_misura : "-" 
    */

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" align="center">
          {row.Nome}
        </TableCell>
        <TableCell align="center">{row.Descrizione}</TableCell>
        <TableCell align="center">{row.NomeUnivocoMacchina}</TableCell>
        <TableCell align="center">{row.lteMacMacchina}</TableCell>
        <TableCell align="right" style={{ width: "0px" }}>
          <BarChart
            data={row.active}
            width={300}
            height={50}
            className="zindoChart"
          >
            <TP content={<CustomTooltip />} />
            <XAxis dataKey="xVal" hide />
            <Bar dataKey="yVal" fill="#8884d8" />
          </BarChart>
        </TableCell>

        <TableCell align="center">
          {(row.dashboard || row.Assistenza) && (
            <BtnCellRenderer value={row.id} name={row.NomeUnivocoMacchina} />
          )}
        </TableCell>
      </TableRow>

      <TableRow></TableRow>
    </React.Fragment>
  );
}

export default function ParamTable(props) {
  const { rows, paramsHook, chartComponent } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [staticModal, setStaticModal] = useState(false);
  const toggleShow = () => setStaticModal(!staticModal);
  const [staticModalEdit, setStaticModalEdit] = useState(false);
  const toggleShowEdit = () => setStaticModalEdit(!staticModalEdit);
  const [editValue, setEditValue] = useState({});

  const getStructureData = (data) => {
    let parametri = {};

    let param = data.nome.replace(regexStructureKey, "");

    structureKey.map((key) => {
      let related_param = rows.find((p) => p.nome === param + key);
      for (const [related_key, value] of Object.entries(related_param)) {
        parametri[key + related_key] = value;
      }
    });

    parametri["nome"] = param;
    parametri["is_structure"] = data.is_structure;

    console.log("PARAM");
    console.log(parametri);
    return parametri;
  };

  const updateValue = (data) => {
    console.log("EDIT DATA");

    if (data.is_structure) {
      data = getStructureData(data);
    }

    setEditValue(data);
    toggleShowEdit();
  };

  const remove = async (parametro) => {
    if (parametro.is_structure) {
      let parametriID = [];

      let param = parametro.nome.replace(regexStructureKey, "");

      structureKey.map((key) => {
        let related_param = rows.find((p) => p.nome === param + key);
        parametriID.push(related_param.pk);
      });

      console.log(parametriID);
      let copy = [...paramsHook.params];

      for (let paramID of parametriID) {
        const response = await zindoService.parametriDELETE(
          JSON.stringify({
            paramID: paramID,
          })
        );
        if (response.status === 200) {
          console.log("ok copy:");
          copy = copy.filter((param) => param.pk !== paramID);
          console.log(copy);
        } else {
          //do something
        }
      }

      paramsHook.setParams(copy);
    } else {
      const response = await zindoService.parametriDELETE(
        JSON.stringify({
          paramID: parametro.pk,
        })
      );

      if (response.status === 200) {
        let copy = [...paramsHook.params];
        paramsHook.setParams(copy.filter((param) => param.pk !== parametro.pk));
      } else {
        //do something
      }
    }
  };

  const [params, setParams] = useState([]); //parametri nel DB
  const [disable, setDisable] = useState(false); //disabilita il tasto aggiungi
  const [filterParams, setFilterParams] = useState([]);
  const [default_, setDefault_] = useState({});

  useEffect(() => {
    console.log("rows");
    console.log(rows);
  }, []);

  return (
    <>
      <TableContainer component={Paper} style={{ minHeight: "500px" }}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              {/* <TableCell style={{maxWidth: '40px'}}>Descrizione</TableCell> */}
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrizione</TableCell>
              <TableCell align="center">Matricola</TableCell>
              <TableCell align="center">LTE</TableCell>
              <TableCell align="center">Utilizzo</TableCell>
              <TableCell component="td" align="right">
                {/* <Button variant="text" color='primary' style={{paddingRight:17}} onClick={toggleShow} id={"addParamID"} disabled={disable}><BsPlus size={30} align={'right'}/>AGGIUNGI</Button> */}
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell colSpan={5} style={{hidden:true}}></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row.pk}
                  row={row}
                  updateAction={updateValue}
                  deleteAction={remove}
                  chart={chartComponent}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="righe per pagina"
          SelectProps={{
            select: {
              marginbottom: "10px",
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </>
  );
}

const ParamButton = styled(IconButton)({
  "&:hover": {
    color: "red",
    boxShadow: "none",
  },
});
const ParamEditButton = styled(IconButton)({
  "&:hover": {
    color: "blue",
    boxShadow: "none",
  },
});
