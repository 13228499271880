import React, { useEffect } from "react";
import ZindoService from "services/zindo-service";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

/* import Typography from "@mui/material/Typography"; */
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { FormComboBox, FormInput, FormTextArea } from "./form-components";
import { requiredValidator, requiredValidatorCheckUnique } from "./validators";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { BsQrCodeScan } from "react-icons/bs";
/* import { MdQrCodeScanner } from "react-icons/md"; */

import { QrReader } from "react-qr-reader";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import QrTestScan from "./qrScan";
/* import jwtDecode from "jwt-decode"; */

function InsertMachineNew() {
  const [openCodUniv, setOpenCodUniv] = React.useState(false);
  const handleOpenCodUniv = () => setOpenCodUniv(true);
  const handleClosCodUnive = () => setOpenCodUniv(false);

  const [chooseCamera, setChooseCamera] = React.useState("environment");

  const [codiceLte, setCodiceLte] = React.useState("");
  const [nomeUnivoco, setNomeUnivoco] = React.useState("");
  const [keyCount, setKeyCount] = React.useState(0);
  const [tipologiaMacchina, setTipologiaMacchina] = React.useState([]);
  var [formInitialValue, setFormInitialValue] = React.useState({
    nomeUnivocoMacchine: nomeUnivoco,
    codiceLteMacchina: codiceLte,
    keyCount: keyCount,
  });

  const createNotification = (type) => {
    if (type === "info") {
      NotificationManager.info("Info message");
    } else if (type === "success") {
      NotificationManager.success(
        "Macchina Inserita con Successo",
        "Inserimento Effettuato",
        5000
      );
    } else if (type === "warning") {
      NotificationManager.warning(
        "Warning message",
        "Close after 3000ms",
        3000
      );
    } else if (type === "error") {
      /* NotificationManager.error("Error message", "Click me!", 5000, () => {
        alert("callback");
      }); */
      NotificationManager.error(
        "Errore Nome Univoco o Codice Lte già presente",
        "Errore Durante inserimento",
        5000
      );
    }
  };

  const handleSubmit = async (dataItem) => {
    /* alert(JSON.stringify(dataItem, null, 2)); */
    let newMachineData = dataItem;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ machineData: newMachineData }),
    };
    const response = await ZindoService.insertnewmachineandcheck(requestOptions);
    /* const response = await fetch(
      "http://127.0.0.1:8000/zindoapp/insertnewmachineandcheck/",
      requestOptions
    ); */
    const data = await response;
    /* console.log(data[0]["responce"]); */
    createNotification(data[0]["responce"]);
  };

  useEffect(() => {
    getListOfTipologiaMacchina();
  }, []);

  let getListOfTipologiaMacchina = async () => {
    /* var refresh_token = localStorage.getItem("access_token");
    let refresh_tokenParts = jwtDecode(refresh_token);
    var user_id = refresh_tokenParts.user_id; */
    /* let responce = await fetch("http://127.0.0.1:8000/zindoapp/typemachines/"); */
    let responce = await ZindoService.getTypeMachines();
    let data = await responce;

    var appendNome = [];
    for (let i = 0; i < data.length; i++) {
      appendNome.push(String(data[i].nometipologia));
    }
    setTipologiaMacchina(appendNome);
  };

  const styleCodUniv = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const TestQRScan = () => {
    return (
      <>
        <select onChange={(e) => setChooseCamera(e.target.value)}>
          <option value={"environment"}>Back Camera</option>
          <option value={"user"}>Front Camera</option>
        </select>
        <QrReader
          facingMode={chooseCamera}
          onResult={(result, error) => {
            if (!!result) {
              try {
                JSON.parse(result?.text);
                var isString = false;
              } catch (e) {
                var isString = true;
              }
              if (isString) {
                if (
                  result?.text.includes("IMEI") &&
                  result?.text.includes("MAC")
                ) {
                  let index = result?.text.indexOf("MAC:");
                  var strOut = result?.text.substr(index).replace("MAC:", "");
                  setCodiceLte(strOut);
                  setFormInitialValue({
                    nomeUnivocoMacchine: nomeUnivoco,
                    codiceLteMacchina: strOut,
                  });
                } else {
                  setNomeUnivoco(result?.text);
                  const changeInitial = {
                    nomeUnivocoMacchine: nomeUnivoco,
                    codiceLteMacchina: codiceLte,
                  };
                  setFormInitialValue(changeInitial);
                }
              } else {
                const data = JSON.parse(result?.text);
                if (typeof data === "object") {
                  if (
                    data["POST"]["Dsp_Board_Info"].hasOwnProperty("LTE_MAC")
                  ) {
                    setCodiceLte(data["POST"]["Dsp_Board_Info"]["LTE_MAC"]);
                    setFormInitialValue({
                      nomeUnivocoMacchine: nomeUnivoco,
                      codiceLteMacchina:
                        data["POST"]["Dsp_Board_Info"]["LTE_MAC"],
                    });
                    console.log(formInitialValue);
                  }
                }
              }
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: "100%" }}
        />
        {chooseCamera}
      </>
    );
  };

  const MyCustomInput = (fieldRenderProps) => {
    const { label, value, onChange } = fieldRenderProps;
    return <input label={label} value={value} onChange={onChange} />;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <MDBox py={3} pl={3}>
        <Grid item>
          <MDBox flex>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                pt={1}
                px={2}
              >
                <MDBox
                  variant="gradient"
                  bgColor="success"
                  color={"dark" === "light" ? "dark" : "white"}
                  coloredShadow="dark"
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  mt={-3}
                >
                  <Icon fontSize="medium" color="inherit">
                    add_card
                  </Icon>
                </MDBox>
              </MDBox>
              <Divider />
              <div
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={21}>
                    <button
                      onClick={handleOpenCodUniv}
                      style={{
                        margin: "10px",
                        fontSize: "18px",
                        height: "7vh",
                        width: "100%",
                        borderRadius: "10px",
                        border: "none",
                        boxShadow: "1px 1px 0px 2px rgba (0,0,0,0.3)",
                        background: "rgb(230,220,220)",
                      }}
                    >
                      <BsQrCodeScan />
                      Scansiona QR
                    </button>
                    <Modal
                      open={openCodUniv}
                      onClose={handleClosCodUnive}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleCodUniv}>
                        {/* <TestQRScan /> */}
                        <QrTestScan
                          codiceLte={codiceLte}
                          setCodiceLte={setCodiceLte}
                          nomeUnivoco={nomeUnivoco}
                          setNomeUnivoco={setNomeUnivoco}
                          formInitialValue={formInitialValue}
                          setFormInitialValue={setFormInitialValue}
                          keyCount={keyCount}
                          setKeyCount={setKeyCount}
                          setOpenCodUniv={setOpenCodUniv}
                        />
                      </Box>
                    </Modal>
                  </Col>
                </Row>
                <Divider />
                <Form
                  onSubmit={handleSubmit}
                  initialValues={formInitialValue}
                  key={JSON.stringify(formInitialValue)}
                  render={(formRenderProps) => (
                    <FormElement
                      style={{
                        width: "100%",
                      }}
                    >
                      <Field
                        id={"tipologiaMacchinaSelected"}
                        name={"tipologiaMacchinaSelected"}
                        label={"Tipologia Macchina"}
                        component={FormComboBox}
                        data={tipologiaMacchina}
                        validator={requiredValidator}
                      />
                      <Divider />
                      <Field
                        id={"nomeMacchine"}
                        name={"nomeMacchine"}
                        label={"Nome Macchina"}
                        /* hint={"Hint: Hint message aligned start"} */
                        component={FormInput}
                        validator={requiredValidator}
                      />

                      <Field
                        id={"nomeUnivocoMacchine"}
                        name={"nomeUnivocoMacchine"}
                        label={"Nome Univoco Macchina"}
                        hint={
                          "Inserisci (o scansionare) un nome univoco per la nuova Macchina"
                        }
                        MyCustomInput
                        component={FormInput}
                        /* onSubmit={checkNomeUnivoco} */
                        validator={requiredValidatorCheckUnique}
                        data={nomeUnivoco}
                      />

                      <Field
                        id={"codiceLteMacchina"}
                        name={"codiceLteMacchina"}
                        label={"Inserire codice Lte Macchina"}
                        hint={
                          "Inserisci (o scansionare) il codice Lte per la nuova Macchina"
                        }
                        component={FormInput}
                        validator={requiredValidator}
                        data={codiceLte}
                      />
                      <Field
                        id={"descrizioneMacchina"}
                        name={"descrizioneMacchina"}
                        label={"Descrizione Macchina"}
                        optional={true}
                        component={FormTextArea}
                      />
                      <div className="k-form-buttons">
                        <Button
                          themeColor={"primary"}
                          type={"submit"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          Submit
                        </Button>
                        <Button onClick={formRenderProps.onFormReset}>
                          Clear
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </Card>
          </MDBox>
        </Grid>
      </MDBox>
      <NotificationContainer />
    </div>
  );
}
export default InsertMachineNew;
